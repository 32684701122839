<script>
  import { _, locale } from "svelte-i18n";
  import { replace } from "svelte-spa-router";

  export let completeCode;
  export let valrequired;
  export let loginError;
  export let managementColors;

  let arrayInputs = ["zero", "one", "two", "three", "four"];
  let index = 0;
  let valueCode = "";
  let useIndex = [];
  let shadowInput;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let middlewareInput = (i, e) => {
    let stringMatriz;

    if (e.data && e.data.trim().length != 0) {
      i < 4 ? (index = i + 1) : (index = i);
      document.getElementById(arrayInputs[index]).focus();

      if (!valueCode.includes("*")) {
        useIndex.includes(i)
          ? (valueCode = valueCode.replace(valueCode[i], e.data))
          : (valueCode = valueCode.concat(e.data));
      } else {
        valueCode = valueCode.replace("*", e.data);
      }
      useIndex.push(i);

      completeCode(valueCode);
    } else {
      if (e.data && e.data.trim().length == 0) {
        valueCode = "";
        document.getElementById(arrayInputs[index]).value = "";
      }
      index = i;
      document.getElementById(arrayInputs[index]).focus();

      stringMatriz = [...valueCode];
      stringMatriz.splice(index, 1, "*");

      valueCode = stringMatriz.toString().replaceAll(",", "");
      completeCode(0);
    }
  };

  let cleanCode = () => {
    for (let i = 0; i < 5; i++) {
      document.getElementById(arrayInputs[i]).value = "";
    }
    index = 0;
    valueCode = "";
    useIndex = [];
    completeCode(valueCode);
  };

  let handelFocus = (id) => {
    shadowInput = `0px 2px 0px${managementColors.color}`;

    arrayInputs.map((input) => {
      if (input == id) {
        let element = document.getElementById(`${id}`);
        element.style.setProperty("box-shadow", shadowInput);
      } else {
        let element = document.getElementById(`${input}`);
        element.style.removeProperty("box-shadow");
      }
    });
  };

</script>

<div class="col s12 code">
  <div class="col s12 description">
    <span
      class="description__text {$locale}"
      for="guest"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_("Enter the validation code that was sent to your email")}
    </span>
  </div>
  <div class="col s12 fileds-code">
    <input
      id="zero"
      maxlength="1"
      on:focus={() => handelFocus("zero")}
      on:input={() => middlewareInput(0, event)}
      autocomplete="off"
      type="text"
      class="inputs-fields {managementColors.general.colorMode ==
        'white'
          ? 'inputs-fields--white'
          : managementColors.general.colorMode == 'dark'
          ? 'inputs-fields--dark'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class:input-required={(valrequired && valueCode.length < 5) || loginError}
    />
    <input
      id="one"
      maxlength="1"
      on:focus={() => handelFocus("one")}
      on:input={() => middlewareInput(1, event)}
      autocomplete="off"
      type="text"
      class="inputs-fields {managementColors.general.colorMode ==
        'white'
          ? 'inputs-fields--white'
          : managementColors.general.colorMode == 'dark'
          ? 'inputs-fields--dark'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class:input-required={(valrequired && valueCode.length < 5) || loginError}
    />
    <input
      id="two"
      maxlength="1"
      on:focus={() => handelFocus("two")}
      on:input={() => middlewareInput(2, event)}
      autocomplete="off"
      type="text"
      class="inputs-fields {managementColors.general.colorMode ==
        'white'
          ? 'inputs-fields--white'
          : managementColors.general.colorMode == 'dark'
          ? 'inputs-fields--dark'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class:input-required={(valrequired && valueCode.length < 5) || loginError}
    />
    <input
      id="three"
      maxlength="1"
      on:focus={() => handelFocus("three")}
      on:input={() => middlewareInput(3, event)}
      autocomplete="off"
      type="text"
      class="inputs-fields {managementColors.general.colorMode ==
        'white'
          ? 'inputs-fields--white'
          : managementColors.general.colorMode == 'dark'
          ? 'inputs-fields--dark'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class:input-required={(valrequired && valueCode.length < 5) || loginError}
    />
    <input
      id="four"
      maxlength="1"
      on:focus={() => handelFocus("four")}
      on:input={() => middlewareInput(4, event)}
      autocomplete="off"
      type="text"
      class="inputs-fields {managementColors.general.colorMode ==
        'white'
          ? 'inputs-fields--white'
          : managementColors.general.colorMode == 'dark'
          ? 'inputs-fields--dark'
          : ''}"
      use:styling={{
        background: managementColors.general.backgroundColor,
      }}
      class:input-required={(valrequired && valueCode.length < 5) || loginError}
    />
  </div>
  {#if valrequired && valueCode.length < 5}
    <div class="col s12 message">
      <span class="tetx__required {$locale}">{$_("Required fields")}</span>
    </div>
  {/if}
  {#if loginError}
    <span class="clean-code">
      {cleanCode()}
    </span>
    <div class="col s12 message">
      <span class="tetx__required {$locale}">{$_("Invalid code")}</span>
    </div>
  {/if}
</div>

<style>
  .code {
    padding-bottom: 78px !important;
  }

  .fileds-code {
    display: flex;
    padding: 16px 0px 0px 0px !important;
    justify-content: center;
  }

  .inputs-fields {
    width: 16% !important;
    height: 60px !important;
    border: 1px solid #707070 !important;
    float: left !important;
    border-radius: 8px !important;
    margin: 0px 8px 0px 0px !important;
    opacity: 1 !important;
  }

  .input-required {
    border: 1px solid #ff4855 !important;
  }

  .description {
    text-align: center;
    padding-bottom: 24px !important;
  }

  .description__text {
    font-size: 0.875rem;
    color: #212121;
    font-weight: 700;
  }

  input {
    text-align: center;
  }

  .tetx__required {
    font-size: 0.75rem;
    color: #ff4855;
  }

  .message {
    text-align: center;
  }

  .clean-code {
    display: none;
  }

  @media only screen and (min-width: 601px) {
    .description {
      padding-bottom: 0px !important;
    }
  }

  @media only screen and (min-width: 992px) {
  }
</style>
