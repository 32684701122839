<script>
  import { _, locale } from "svelte-i18n";

  export let toastActive;
  export let messageToast;

  let bottom = "60px";
  let top = "";
  let width = "90%";
  let classToast = "show-mobile";
  let right = "";

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let showToast = () => {
    if (window.innerWidth >= 992) {
      width = "20%";
      bottom = "";
      top = "50px";
      right = "60px";
      classToast = "show-desktop";
    } else if (window.innerWidth >= 601) {
      width = "40%";
      bottom = "";
      top = "50px";
      right = "60px";
      classToast = "show-desktop";
    }

    setTimeout(() => {
      toastActive = false;
    }, 5000);
  };

  showToast();
</script>

<div
  class={classToast}
  class:mytoast={toastActive}
  use:styling={{ bottom: bottom, width: width, top: top, right: right }}
>
  <i class="material-icons toast__icon">file_download</i>
  <span class="toat__text {$locale}">{$_(messageToast)}</span>
</div>

<style>
  .mytoast {
    visibility: hidden;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 53px;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 8px;
    padding: 0px 16px;
    margin: 0px 16px 38px 16px;
    opacity: 1;
    z-index: 1;
  }

  .toast__icon {
    width: 20px;
    height: 20px;
    letter-spacing: 0px;
    color: #f4b92a;
    margin: 0px 8px 5px 0px;
    opacity: 1;
  }

  .toat__text {
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .show-mobile {
    visibility: visible;
    -webkit-animation: fadein 1s, fadeout 1s 4.5s;
    animation: fadein 1s, fadeout 1s 4.5s;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 60px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 60px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 60px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 60px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  .show-desktop {
    visibility: visible;
    -webkit-animation: fadein-desktop 1s, fadeout-desktop 1s 4.5s;
    animation: fadein-desktop 1s, fadeout-desktop 1s 4.5s;
  }

  @-webkit-keyframes fadein-desktop {
    from {
      right: 0;
      opacity: 0;
    }
    to {
      right: 60px;
      opacity: 1;
    }
  }

  @keyframes fadein-desktop {
    from {
      right: 0;
      opacity: 0;
    }
    to {
      right: 60px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout-desktop {
    from {
      right: 60px;
      opacity: 1;
    }
    to {
      right: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout-desktop {
    from {
      right: 60px;
      opacity: 1;
    }
    to {
      right: 0;
      opacity: 0;
    }
  }
</style>
