<script>
  import { location, querystring } from "svelte-spa-router";
  import { push } from "svelte-spa-router";
  import MessageView from "../../components/view-messages/component.svelte";
  import PropertyFeatures from "../../property-features-service";
  import ValidationUser from "../../authentication-service";
  import Reservations from "../../reservations-service";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let title;
  let message;
  let reloading;
  let imgView;
  let btnActionMobile;
  let textCallBtn;
  let textGoBtn;
  let callBtn;
  let goBtn;
  let classImg;
  let classBtnGo;
  let classBtnGoText;
  let managementColors = {};
  let colorImgSvg;
  let backgroundColorView;

  JSON.parse(localStorage.getItem("managementColors"))
    ? (managementColors = JSON.parse(localStorage.getItem("managementColors")))
    : (managementColors = {
        color: "",
        title: "",
        general: "",
      });

      const validationColors = (guestappsettins) => {

    managementColors = {
      filter: guestappsettins.filter,
      color: Reservations.getColorComponentsAndIcons(guestappsettins.color).color,
      title: Reservations.getColorComponentsAndIcons(guestappsettins.color).title,
      general: Reservations.getColorsContrast(guestappsettins),
    };


    localStorage.setItem("managementColors", JSON.stringify(managementColors));
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const init = () => {
    Promise.all([
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
    ]).then(([propertyInformation, guestappsettins]) => {
      propertyData = propertyInformation;
      validationColors(guestappsettins);
      setFontFamily(guestappsettins.fontFamily);
    });

    $querystring &&
    ($querystring.split("code=")[1].includes("&")
      ? $querystring.split("code=")[1].split("&")[0]
      : $querystring.split("code=")[1])
      ? validationViewMessage(true)
      : validationViewMessage();
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  let validationViewMessage = (typeView) => {
    switch (typeView) {
      case true:
        let dataLogin = {
          code: $querystring.split("code=")[1].includes("&")
            ? $querystring.split("code=")[1].split("&")[0]
            : $querystring.split("code=")[1],
        };
        title = "Just a minute";
        message = "We are verifying your reservation information";
        reloading = true;
        classImg = "img-no-found";
        btnActionMobile = false;
        imgView = false;
        goBtn = false;
        handleLogin(dataLogin);
        break;
      case "error":
        title = "A problem has occurred";
        message = false;
        imgView = "no-found.png";
        classBtnGo = "button-dark";
        btnActionMobile = true;
        reloading = false;
        goBtn = true;
        textGoBtn = "Try again";
        classImg = "img-error";
        classBtnGoText = "text--button-home--dark";
        reloading = false;
        break;
      default:
        title = "A problem has occurred";
        message = "Please contact the operator";
        imgView = "operator.png";
        btnActionMobile = true;
        textCallBtn = "Contact the operator";
        callBtn = true;
        reloading = false;
        break;
    }
  };

  let handleLogin = (dataLogin) => {
    ValidationUser.loginMagicLink(state.propertyId, dataLogin)
      .then((response) => {
        if (response) {
          push(`/properties/${state.propertyId}`);
        } else {
          validationViewMessage("error");
        }
      })
      .catch((e) => {
        validationViewMessage(null);
      });
  };

  let handleAction = (e) => {
    validationViewMessage(true);
  };

  init();
</script>

<div
  class="view-magic-link"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  <MessageView
    bind:title
    bind:message
    bind:reloading
    bind:callBtn
    bind:goBtn
    bind:imgView
    bind:btnActionMobile
    bind:textCallBtn
    bind:textGoBtn
    bind:ownerPhone={propertyData.ownerPhone}
    bind:classImg
    bind:classBtnGo
    bind:classBtnGoText
    bind:managementColors
    bind:colorImgSvg
    bind:handleAction
  />
</div>

<style>
  .view-magic-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
