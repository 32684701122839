<script>
  import { _, locale } from "svelte-i18n";
  export let id;
  export let img;
  export let secondImg;
  export let name;
  export let question = "";
  export let rowCardRequest = false;
  export let managementColors;
  export let index;
  export let showName;
  export let backgroundColorView;
  export let backgroundColorCards;

  let backgroundColor;
  let shadow;
  let textColor;
  let color__icon__create__requets;
  let filterSvg = false;

  let icon__active__hover = managementColors.title + "_second";

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColors = () => {
    id == "requests.create"
      ? (backgroundColor = managementColors.color)
      : (backgroundColor = backgroundColorCards);

    if (id == "requests.create" && managementColors.general.colorMode != "white") {
      textColor = "#212121";
      color__icon__create__requets = "icon-create-request-black";
    } else if (id != "requests.create" && managementColors.general.colorMode != "white") {
      textColor = managementColors.general.colorLetter;
    } else if (id == "requests.create" && managementColors.general.colorMode == "white") {
      textColor = "#F4F4F4";
      color__icon__create__requets = "icon-create-request-white";
    } else {
      textColor = "#212121";
    }

    shadow = `0px 4px 8px${managementColors.color}`;
  };

  const hover = () => {
    if (window.innerWidth >= 992) {
      const element = document.getElementById(`${index}`);
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const nameFeature = document.getElementById(`${index}nameFeature`);
      nameFeature && id != "requests.create"
        ? nameFeature.style.setProperty("color", managementColors.color)
        : null;

      const questionFeature = document.getElementById(
        `${index}questionFeature`
      );
      questionFeature && id != "requests.create"
        ? questionFeature.style.setProperty("color", managementColors.color)
        : null;
    }
  };

  const handleMouseOut = () => {
    if (window.innerWidth >= 992) {
      const element = document.getElementById(`${index}`);
      element ? element.style.removeProperty("box-shadow") : null;

      const nameFeature = document.getElementById(`${index}nameFeature`);
      nameFeature ? nameFeature.style.setProperty("color", textColor) : null;

      const questionFeature = document.getElementById(
        `${index}questionFeature`
      );
      questionFeature
        ? questionFeature.style.setProperty("color", textColor)
        : null;
    }
  };

  const validationSvg = () => {
    if (img.split("/").find((sp) => sp == "img")) filterSvg = true;
  };

  validationColors();
  validationSvg();
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<div
  on:mouseover={hover}
  on:mouseout={handleMouseOut}
  id={index}
  class="card__feature"
  use:styling={{ background: backgroundColor }}
  class:card__mod--horizontal={id == "requests.create" && rowCardRequest}
>
  <div
    class={id == "requests.create" && rowCardRequest
      ? "cont__icon--right"
      : !filterSvg
      ? "cont__icon"
      : "cont__icon--icon"}
    class:cont__icon__all={!showName}
  >
    {#if /\.(svg)$/i.test(img) && filterSvg}
      <img
        class="img-svg color-filter-svg {id == 'requests.create'
          ? color__icon__create__requets
          : managementColors.title}"
        src={img}
        alt=""
      />
    {:else}
      <img
        class:img_extensions-create-request={id == "requests.create"}
        class="{showName ? 'img_extensions' : 'img_extensions_all'} "
        src={img}
        alt=""
      />
    {/if}
  </div>
  {#if showName}
    <div
      class={id == "requests.create" && rowCardRequest
        ? "cont__data--right"
        : "cont__data"}
    >
      <div class="div_text">
        {#if id == "requests.create"}
          {#if question}
            <p
              class="name__feature {$locale}"
              id="{index}questionFeature"
              use:styling={{ color: textColor }}
            >
              {$_(question)}
            </p>
          {/if}
          {#if name && rowCardRequest}
            <p
              class="name__feature {$locale}"
              class:requests--text={id == "requests.create"}
              id="{index}nameFeature"
              use:styling={{ color: textColor }}
            >
              {name}
            </p>
          {/if}
        {:else if name}
          <p
            class="name__feature {$locale}"
            id="{index}questionFeature"
            use:styling={{ color: textColor }}
          >
            {$_(name)}
          </p>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  .color-filter-svg {
    filter: var(--filter-svg);
  }
  .card__feature {
    height: 163px;
    width: 47%;
    float: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 8px;
    margin: 0px 0px 8px 8px;
    opacity: 1;
    cursor: pointer;
  }

  .cont__data {
    height: 30%;
    justify-content: center;
    display: flex;
  }

  .name__feature {
    font-size: 0.875rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card__mod--horizontal {
    width: 96% !important;
    height: 102px;
    float: left;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .cont__icon--right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
    float: left;
  }

  .cont__data--right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
  }

  .div_text {
    padding: 0px 5px 0px 5px;
  }

  .requests--text {
    font-weight: 1000 !important;
  }

  p {
    margin: 0px;
    text-align: center;
  }

  .icon-create-request-white {
    height: 65px;
    filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(23deg)
      brightness(110%) contrast(96%) !important;
  }

  .icon-create-request-black {
    height: 65px;
    filter: invert(2%) sepia(1%) saturate(1137%) hue-rotate(314deg)
      brightness(101%) contrast(77%);
  }

  .icon-create-request-white__second {
    height: 65px;
  }

  .icon-create-request-black__second {
    height: 65px;
  }

  .img_extensions {
    width: 100%;
    height: 92px;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
  }

  .img_extensions_all {
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    object-fit: cover;
  }

  .img-svg {
    width: 70px;
    height: 72px;
  }

  .cont__icon {
    height: 66%;
    display: flex;
    justify-content: center;
  }

  .cont__icon--icon {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cont__icon__all {
    padding-top: 0px !important;
    height: 100% !important;
    width: 100% !important;
    border-radius: 8px;
  }

  .img_extensions-create-request {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    border-radius: 8px !important;
  }

  @media only screen and (max-width: 600px) {
    .cont__icon__all {
      padding-top: 0px !important;
    }
  }

  @media only screen and (min-width: 601px) {
    .card__feature {
      height: 170px;
    }

    .cont__icon__all {
      padding-top: 0px !important;
    }

    .name__feature {
      font-size: 1rem;
    }

    .icon-create-request-white {
      width: 101px;
      height: 97px;
    }

    .icon-create-request-black {
      width: 101px;
      height: 97px;
    }

    .img_extensions {
      width: 100%;
      height: 97px;
      object-fit: cover;
      border-radius: 8px 8px 0px 0px;
    }

    .img-svg {
      width: 101px;
      height: 97px;
    }


    .img_extensions_second {
      width: 100%;
      height: 98px;
      object-fit: cover;
      border-radius: 8px 8px 0px 0px;
    }

    .img_extensions-create-request-second {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      border-radius: 8px 8px 0px 0px;
    }

    .img_extensions_second_all {
      border-radius: 8px !important;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card__mod--horizontal {
      height: 132px;
    }
  }
</style>
