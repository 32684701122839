<script>
  import { _, locale } from "svelte-i18n";
  import { replace } from "svelte-spa-router";
  import PropertyFeatures from "../property-features-service";

  export let wayValidation;
  export let dataForm;
  export let valrequired;
  export let loginError;
  export let managementColors;

  let name = "";
  let lastName = "";
  let roomNumber = "";
  let date = "";
  let userInformation = {};

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let changeName = (e) => {
    if (e.data && e.data.trim().length != 0) {
      userInformation.name = name;
      dataForm(userInformation);
    } else {
      if (
        e.data &&
        e.data.trim().length == 0 &&
        document.getElementById("name").value.trim().length == 0
      ) {
        document.getElementById("name").value = null;
      } else {
        userInformation.name = name;
        dataForm(userInformation);
      }
    }
  };

  let changeLastname = (e) => {
    if (e.data && e.data.trim().length != 0) {
      userInformation.lastname = lastName;
      dataForm(userInformation);
    } else {
      if (
        e.data &&
        e.data.trim().length == 0 &&
        document.getElementById("lastname").value.trim().length == 0
      ) {
        document.getElementById("lastname").value = null;
      } else {
        userInformation.lastname = lastName;
        dataForm(userInformation);
      }
    }
  };

  let changeRoomNumber = (e) => {
    if (e.data && e.data.trim().length != 0) {
      userInformation.roomnumber = roomNumber;
      dataForm(userInformation);
    } else {
      if (
        e.data &&
        e.data.trim().length == 0 &&
        document.getElementById("room").value.trim().length == 0
      ) {
        document.getElementById("room").value = null;
      } else {
        userInformation.roomnumber = roomNumber;
        dataForm(userInformation);
      }
    }
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  }

  setFontFamily(null);

  let changeDate = () => {
    userInformation.date = date;
    dataForm(userInformation);
  };
</script>

<div class="col l12 m12 s12 form">
  <div class="col s12 title">
    <span
      class="body__title {$locale}"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_("Enter your information")}
    </span>
  </div>

  <div class="col s12 input">
    <div class="input-field col s12">
      <input
        use:styling={{
          "border-bottom": ` 1px solid ${managementColors.color}`,
        }}
        use:styling={{ color: managementColors.general.inputLetterColor }}
        maxlength="100"
        placeholder={$_("Type your name")}
        id="name"
        bind:value={name}
        on:input={() => changeName(event)}
        type="text"
        class="validate name {managementColors.general.colorMode ==
          'white'
            ? 'inputs-fields--white'
            : managementColors.general.colorMode == 'dark'
            ? 'inputs-fields--dark'
            : ''}"
      />
      <label
        class="active label__input {$locale}"
        use:styling={{ color: managementColors.general.colorLetter }}
        for="name"
      >
        {$_("Name")}
      </label>
      {#if valrequired && name.length < 2}
        <span class="tetx__required {$locale}">{$_("Required field")}</span>
      {/if}
    </div>
  </div>

  <div class="col s12 input">
    <div class="input-field col s12">
      <input
        use:styling={{
          "border-bottom": ` 1px solid ${managementColors.color}`,
        }}
        use:styling={{ color: managementColors.general.inputLetterColor }}
        maxlength="100"
        placeholder={$_("Type your last name")}
        id="lastname"
        bind:value={lastName}
        on:input={() => changeLastname(event)}
        type="text"
        class="validate lastName {managementColors.general.colorMode ==
          'white'
            ? 'inputs-fields--white'
            : managementColors.general.colorMode == 'dark'
            ? 'inputs-fields--dark'
            : ''}"
      />
      <label
        class="active label__input {$locale}"
        use:styling={{ color: managementColors.general.colorLetter }}
        for="lastname"
      >
        {$_("Last name")}
      </label>
      {#if valrequired && lastName.length < 2}
        <span class="tetx__required {$locale}">{$_("Required field")}</span>
      {/if}
    </div>
  </div>

  {#if wayValidation == 1}
    <div class="col s12 input">
      <div class="input-field col s12">
        <input
          use:styling={{
            "border-bottom": ` 1px solid ${managementColors.color}`,
          }}
          use:styling={{ color: managementColors.general.inputLetterColor }}
          maxlength="10"
          placeholder={$_("Type your room number")}
          id="room"
          name="room"
          bind:value={roomNumber}
          on:input={() => changeRoomNumber(event)}
          type="text"
          class="validate number {managementColors.general.colorMode ==
            'white'
              ? 'inputs-fields--white'
              : managementColors.general.colorMode == 'dark'
              ? 'inputs-fields--dark'
              : ''}"
        />
        <label
          class="active label__input {$locale}"
          use:styling={{ color: managementColors.general.colorLetter }}
          for="room"
        >
          {$_("Room number")}
        </label>
        {#if valrequired && roomNumber.length < 1}
          <span class="tetx__required {$locale}">{$_("Required field")}</span>
        {/if}
      </div>
    </div>
  {:else}
    <div class="col s12 input">
      <div class="input-field col s12">
        <input
          use:styling={{
            "border-bottom": ` 1px solid ${managementColors.color}`,
          }}
          use:styling={{ color: managementColors.general.inputLetterColor }}
          maxlength="10"
          placeholder={$_("Enter your check in date")}
          id="date"
          name="date"
          bind:value={date}
          on:input={changeDate}
          type="date"
          class="validate {managementColors.general.colorMode ==
            'white'
              ? 'inputs-fields--white'
              : managementColors.general.colorMode == 'dark'
              ? 'inputs-fields--dark'
              : ''}"
        />
        <label
          class="active label__input {$locale}"
          use:styling={{ color: managementColors.general.colorLetter }}
          for="date"
        >
          {$_("Check in date")}
        </label>
        {#if valrequired && !date}
          <span class="tetx__required {$locale}">{$_("Required field")}</span>
        {/if}
      </div>
    </div>
  {/if}

  {#if loginError}
    <div class="col s12 message__error">
      <span class="tetx__required {$locale}"
        >{$_("The data entered is not valid")}</span
      >
    </div>
  {/if}
</div>

<style>
  .form {
    padding-bottom: 70px !important;
  }

  .body__title {
    font-size: 0.875rem;
    font-weight: 700;
    color: #212121;
    margin-bottom: 17px;
  }

  .title {
    padding-bottom: 32px;
    text-align: center;
  }

  .input {
    padding-bottom: 0px;
  }

  .input > .input-field {
    padding: 0px 0px 0px 0px;
  }

  .tetx__required {
    font-size: 0.75rem;
    color: #ff4855;
  }

  .label__input {
    left: 0 !important;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .required {
    color: #ff4855;
  }

  .message__error {
    padding: 0px !important;
    text-align: center;
  }

  @media only screen and (min-width: 601px) {
  }
</style>
