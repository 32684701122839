<script>
  import { _, locale } from "svelte-i18n";
  import ButtonReloading from "../button-loading/component.svelte";
  export let managementColors;
  export let handleSendRequest;
  export let resetBtnVariables;

  export let textBtn = "Send your request";

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleSend = () => {
    handleSendRequest();
    resetBtnVariables();
  };
</script>

<div
  class="col l12 m12 s12 row sent-request-cont"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  <div class="col xl4 l4 m12 s12 row">
    <ButtonReloading
      bind:handleLogin={handleSend}
      bind:resetBtnVariables
      bind:managementColors
      bind:textBtn
    />
  </div>
</div>

<style>
  .sent-request-cont {
    bottom: 0;
    margin: 0px;
    position: fixed;
    height: 11%;
    display: flex;
    align-items: center;
  }

  .sent-request-cont > .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    opacity: 1;
  }

  .button--block {
    background: #c3c3c3 0% 0% no-repeat padding-box !important;
  }

  .text--button {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: initial;
  }

  @media only screen and (min-width: 601px) {
    .sent-request-cont {
      padding: 0px 32px 0px 32px;
    }
  }

  @media only screen and (min-width: 992px) {
    .sent-request-cont > .button--continuebase {
      width: 326px;
      float: right;
    }

    .sent-request-cont {
      box-shadow: 0px -4px 6px #00000029;
      justify-content: flex-end;
      opacity: 1;
    }
  }
</style>
