<script>
  import { _, locale } from "svelte-i18n";
  import PropertyFeatures from "../../property-features-service";
  import RoutesServices from "../../management-routes-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Footnote from "../../components/footnote/component.svelte";
  import { push } from "svelte-spa-router";

  export let params = {};

  let resquestText = "";
  let whereText = "";
  let whoText = "";
  let valrequired = false;
  let blockButton = true;
  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let language;
  let languagesEnabled = [];
  let faeturesList = [];
  let faeturesRequests = null;
  let managementColors = {};
  let showBtnBack = true;
  let inputactive = false;
  let inputactiveWhere = false;
  let inputactiveWho = false;
  let propEmpty = "";
  let settingsData;
  let datalogin = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColor();

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const init = (lang) => {
    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(([features, guestappsettins, propertyInformation]) => {
      propertyData = propertyInformation;
      settingsData = guestappsettins;
      faeturesList = features.filter(
        (fet) => fet.enabled || fet.codename == "property.details"
      );
      faeturesRequests = features.find(
        (fet) => fet.codename == "requests.create"
      );
      validationLanguage(
        guestappsettins.languages,
        propertyInformation.language.code
      );
      setFontFamily(guestappsettins.fontFamily);
      RoutesServices.setLastRoute("pop");
    });
  };

  init(
    JSON.parse(localStorage.getItem("language")) ||
      window.navigator.language.split("-")[0]
  );

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const validationLanguage = (enabledLanguges, languageDefault) => {
    localStorage.setItem("languagesConfig", JSON.stringify(enabledLanguges));
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let changeRequest = () => {
    if (
      resquestText.toString().trim().length == 0 ||
      (!datalogin || (datalogin && !datalogin.roomNumber)
        ? whereText.toString().trim().length == 0
        : false) ||
      (!datalogin || (datalogin && !datalogin.guestName)
        ? whoText.toString().trim().length == 0
        : false)
    ) {
      blockButton = true;
      resetBtnVariables(blockButton, false);
    } else {
      blockButton = false;
      resetBtnVariables(blockButton, false);
    }

    setFontFamily(settingsData.fontFamily);
  };

  let handleSendRequest = () => {
    valrequired = true;
    if (resquestText && !blockButton) {
      blockButton = true;

      if (
        faeturesRequests &&
        faeturesRequests.settings.loginRequired &&
        !localStorage.getItem("auth")
      ) {
        push(`/properties/${state.propertyId}/message-bad-request`);
        return;
      }

      if (
        (datalogin && !datalogin.guestName) ||
        (datalogin && !datalogin.roomNumber)
      ) {
        localStorage.setItem(
          "auth",
          JSON.stringify(
            Object.assign({}, datalogin, {
              guestName:
                datalogin && datalogin.guestName
                  ? datalogin.guestName
                  : whoText,
              roomNumber:
                datalogin && datalogin.roomNumber
                  ? datalogin.roomNumber
                  : whereText,
            })
          )
        );
      }

      let data = {
        isLogin: datalogin ? true : false,
        reservationId: datalogin ? datalogin.reservationId : null,
        reservationVip: datalogin ? datalogin.reservationVip : null,
        browserIdentify:
          datalogin && datalogin.reservationId
            ? null
            : localStorage.getItem("browser-identify"),
        serviceId: localStorage.getItem("serviceCreate")
          ? JSON.parse(localStorage.getItem("serviceCreate")).featureId
          : null,
        guestName:
          datalogin && datalogin.guestName ? datalogin.guestName : whoText,
        roomNumber:
          datalogin && datalogin.roomNumber ? datalogin.roomNumber : whereText,
        requestText: resquestText,
      };

      PropertyFeatures.createRequest(state.propertyId, data).then(
        (response) => {
          if (response.error) {
            push(`/properties/${state.propertyId}/message-bad-request`);
          } else {
            if (datalogin) {
              push(
                `/properties/${state.propertyId}/my-experience/${response.id}`
              );
            } else {
              push(`/properties/${state.propertyId}`);
            }
          }
        }
      );
    }
  };

  let resetBtnVariables = (blockButton, loginError) => {};

  let handleLanguage = (e) => {
    init(e);
  };
</script>

<div
  class="col s12 input-request__cont row"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  <div
    class="col s12 row navifation-cont {managementColors.general.colorMode ==
    'white'
      ? 'navifation-cont-border--white'
      : managementColors.general.colorMode == 'dark'
        ? 'navifation-cont-border--dark'
        : ''}"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <NavigationTop
      bind:languageConection={handleLanguage}
      bind:logoUrl={propertyData.logo}
      bind:propertyName={propertyData.name}
      bind:propertyId={state.propertyId}
      bind:lang={language}
      bind:listLanguagesEnabled={languagesEnabled}
      bind:features={faeturesList}
      bind:backgroundColorView={managementColors.general.backgroundColor}
      bind:colorLetter={managementColors.general.colorLetter}
      bind:managementColors
      bind:color={managementColors.color}
      bind:showBtnBack
      bind:iframeFromLanding={propEmpty}
    />
  </div>
  <div
    class="input-request__body row col l8 offset-l2 m12 s12"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <div class="col l12 m12 s12 nopadding">
      <div class="cols 12 title">
        <span
          class="body__title {$locale}"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {$_("Create request")}
        </span>
      </div>
      {#if !datalogin || (datalogin && !datalogin.guestName)}
        <div class="cols 12 input__resquestText">
          <div class="input-field col s12">
            <input
              use:styling={{
                "border-bottom": ` 1px solid ${managementColors.color}`,
              }}
              maxlength="50"
              placeholder={$_("Enter your first and last name")}
              id="whoText"
              on:input={() => (inputactiveWho = true)}
              on:click={() => (inputactiveWho = true)}
              bind:value={whoText}
              on:input={changeRequest}
              type="text"
              autocomplete="off"
              class="validate {managementColors.general.colorMode == 'white'
                ? 'inputs-fields--white'
                : managementColors.general.colorMode == 'dark'
                  ? 'inputs-fields--dark'
                  : ''}"
            />
            <label
              use:styling={{ color: `${managementColors.color}` }}
              class="active label__input {$locale}"
              class:activeinput={inputactiveWho == true}
              for="whereText"
            >
              {$_("First and Last Name")}
            </label>
            <!-- <span class="input__resquestText--helper">
              {whoText.length + "/50"}
            </span> -->
            {#if valrequired && !whoText}
              <span class="tetx__required {$locale}"
                >{$_("Required field")}</span
              >
            {/if}
          </div>
        </div>
      {/if}
      {#if !datalogin || (datalogin && !datalogin.roomNumber)}
        <div class="cols 12 input__resquestText">
          <div class="input-field col s12">
            <input
              use:styling={{
                "border-bottom": ` 1px solid ${managementColors.color}`,
              }}
              maxlength="50"
              placeholder={$_("Enter your room or area")}
              id="whereText"
              on:input={() => (inputactiveWhere = true)}
              on:click={() => (inputactiveWhere = true)}
              bind:value={whereText}
              on:input={changeRequest}
              type="text"
              autocomplete="off"
              class="validate {managementColors.general.colorMode == 'white'
                ? 'inputs-fields--white'
                : managementColors.general.colorMode == 'dark'
                  ? 'inputs-fields--dark'
                  : ''}"
            />
            <label
              use:styling={{ color: `${managementColors.color}` }}
              class="active label__input {$locale}"
              class:activeinput={inputactiveWhere == true}
              for="whereText"
            >
              {$_("Location")}
            </label>
            <!-- <span class="input__resquestText--helper">
              {whereText.length + "/50"}
            </span> -->
            {#if valrequired && !whereText}
              <span class="tetx__required {$locale}"
                >{$_("Required field")}</span
              >
            {/if}
          </div>
        </div>
      {/if}
      <div class="cols 12 input__resquestText">
        <div class="input-field col s12">
          <input
            use:styling={{
              "border-bottom": ` 1px solid ${managementColors.color}`,
            }}
            maxlength="250"
            placeholder={$_("Enter your request")}
            id="resquestText"
            on:input={() => (inputactive = true)}
            on:click={() => (inputactive = true)}
            bind:value={resquestText}
            on:input={changeRequest}
            type="text"
            autocomplete="off"
            autofocus="on"
            class="validate {managementColors.general.colorMode == 'white'
              ? 'inputs-fields--white'
              : managementColors.general.colorMode == 'dark'
                ? 'inputs-fields--dark'
                : ''}"
          />
          <label
            use:styling={{ color: `${managementColors.color}` }}
            class="active label__input {$locale}"
            class:activeinput={inputactive == true}
            for="resquestText"
          >
            {$_("What can we do for you?")}
          </label>
          <span class="input__resquestText--helper">
            {resquestText.length + "/250"}
          </span>
          {#if valrequired && !resquestText}
            <span class="tetx__required {$locale}">{$_("Required field")}</span>
          {/if}
        </div>
      </div>
    </div>
  </div>
  <div class="col xl4 l4 m12 s12 nopadding">
    <Footnote
      bind:managementColors
      bind:handleSendRequest
      bind:resetBtnVariables
      textBtn="Create request"
    />
  </div>
</div>

<style>
  .input-request__cont {
    height: 100%;
    margin: 0px;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .input-request__body {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    height: 80%;
    min-height: 377px;
    opacity: 1;
    position: relative;
    top: 60px;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
  }

  .body__title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 17px;
  }

  .title {
    padding-bottom: 17px;
  }

  .input__resquestText {
    padding-bottom: 20px;
  }

  .input__resquestText > .input-field {
    padding: 0px 0px 22px 0px;
  }

  .tetx__required {
    font-size: 0.75rem;
    color: #ff4855;
  }

  .label__input {
    left: 0 !important;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #686c6d;
    font-weight: 700;
  }

  .required {
    color: #ff4855;
  }

  .input__resquestText--helper {
    font-size: 0.875rem;
    color: #686c6d;
    float: right;
  }

  .nopadding {
    padding: 0px;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nopadding {
    padding: 0px;
  }

  .activeinput {
    color: #1ab394;
  }

  @media only screen and (min-width: 601px) {
    .input-request__body {
      height: auto;
      padding: 32px 32px 32px 32px;
    }

    .body__title {
      font-size: 1.5rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .input-request__body {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }
  }
</style>
