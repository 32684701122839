<script>
  import { _, locale } from "svelte-i18n";
  import Communications from "../../communications-service";

  export let propertyData;
  export let experienceLength;
  export let messageId;
  export let recomendedLength;
  export let comments;
  export let arraySocialMeidia;
  export let messageData;

  const getFullYear = new Date().getFullYear();
  let classStar = "strborder";
  let classStarRecom = "strborder";
  let indexe = null;
  let indexr = null;
  let classStarBase = "strborder";
  let statesExperience = [];
  let statesRecomendate = [];
  let nocopy = false;
  let emailSurvey = null;

  let init = () => {
    emailSurvey = messageData.channels.find((i) => i.name == "email")
      ? messageData.channels.find((i) => i.name == "email").attributes.from
          .email
      : null;
    for (let i = 1; i <= 5; ++i) {
      if (i <= experienceLength) {
        statesExperience.push(true);
      } else {
        statesExperience.push(false);
      }
    }
    indexe = statesExperience.filter((r) => r).length;
    switch (statesExperience.filter((r) => r).length) {
      case 1:
        classStar = "startRed";
        break;
      case 2:
        classStar = "startRed";
        break;
      case 3:
        classStar = "startOrange";
        break;
      case 4:
        classStar = "startYellow";
        break;
      case 5:
        classStar = "startYellow";
        break;
      default:
        break;
    }

    for (let i = 1; i <= 5; ++i) {
      if (i <= recomendedLength) {
        statesRecomendate.push(true);
      } else {
        statesRecomendate.push(false);
      }
    }

    indexr = statesRecomendate.filter((r) => r).length;
    switch (statesRecomendate.filter((r) => r).length) {
      case 1:
        classStarRecom = "startRed";
        break;
      case 2:
        classStarRecom = "startRed";
        break;
      case 3:
        classStarRecom = "startOrange";
        break;
      case 4:
        classStarRecom = "startYellow";
        break;
      case 5:
        classStarRecom = "startYellow";
        break;
      default:
        break;
    }

    validationBtnsSocialMedia();
  };

  let copyComment = () => {
    let textArea = document.createElement("textarea");
    textArea.value = comments;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    nocopy = true;
    setTimeout(() => (nocopy = false), 3000);
  };

  let validationBtnsSocialMedia = () => {
    arraySocialMeidia.map((social) => {
      switch (social.name) {
        case "Tripadvisor Account URL":
          social.index = 1;
          (social.namesocial = "Tripadvisor"),
            (social.class = "class-tipadvisor"),
            (social.icon = "icon-tripadvisor.svg"),
            (social.classtext = "class-text-tripadvisor");
          break;
        case "Yelp Account URL":
          social.index = 3;
          (social.namesocial = "Yelp!"),
            (social.class = "class-yelp"),
            (social.icon = "icon-yelp.svg"),
            (social.classtext = "class-text-yelp");
          break;
        case "Google+ Place ID":
          social.index = 2;
          (social.namesocial = "Google"),
            (social.class = "class-google"),
            (social.icon = "icon-google.svg"),
            (social.classtext = "class-text-google");
          break;
        default:
          break;
      }
    });
    arraySocialMeidia.sort(function (a, b) {
      return a.index - b.index;
    });
  };

  const clickSocialMedia = (social) => {
    if (social.namesocial == "Google") {
      social.link = `https://search.google.com/local/writereview?placeid=${social.link}`;
      Communications.clikSocial(social.link, messageId, propertyData.id)
        .then(() => {
          window.location.href = social.link;
        })
        .catch((err) => {
          console.error(err);
          window.location.href = social.link;
        });
    } else {
      social.link = social.link;
      Communications.clikSocial(social.link, messageId, propertyData.id)
        .then(() => {
          window.location.href = social.link;
        })
        .catch((err) => {
          console.error(err);
          window.location.href = social.link;
        });
    }
  };

  const clickShare = () => {
    var asunto = "";
    var cuerpo = "";

    var mailtoLink =
      "mailto:" +
      emailSurvey +
      "?subject=" +
      encodeURIComponent(asunto) +
      "&body=" +
      encodeURIComponent(cuerpo);

    window.location.href = mailtoLink;
  };

  init();
</script>

<div class="container-fluid row">
  <div class="col s12 m12 l8 offset-l2 xl8 offset-xl2 feedback">
    {#if propertyData.coverImage}
      <div class="col s12 feedback__cont__logo">
        <div class="cont__cover">
          <img
            class="feedback__cover"
            alt="logo"
            src={propertyData.coverImage}
          />
        </div>
      </div>
    {/if}

    <div
      class="col s12 {propertyData.coverImage
        ? 'feedback__cont__primary'
        : 'feedback__top'}"
    >
      <img class="feedback__logo" alt="logo" src={propertyData.logo} />
    </div>

    <!-- <div class="col s12 feedback__cont__primary">
      <img class="feedback__logo" alt="logo" src={propertyData.logo} />
    </div> -->

    <div
      class="col s12 {propertyData.coverImage
        ? 'feedback__title'
        : 'title__top'}"
    >
      <span class="feedback__title__text {$locale}">
        {#if recomendedLength > 3}
          {$_("Thank you for completing our survey.")}
        {:else}
          {$_("We´re sorry to hear your experience was less than perfect.")}
        {/if}
      </span>
    </div>

    {#if recomendedLength > 3}
      <div class="col s12 feedback__starts">
        <div class=" feedback__starts__contex">
          <div class="col s12 feedback__starts__cont__clave">
            <span class="feedback__starts__text {$locale}">
              {$_("Stay rating")}
            </span>
          </div>
          <div class="col s12 feedback__starts__cont__single">
            {#each statesExperience as star, i}
              <i
                class="material-icons star {i < indexe
                  ? classStar
                  : classStarBase}"
              >
                star
              </i>
            {/each}
          </div>
        </div>

        <div class="feedback__starts__contrem">
          <div class="col s12 feedback__starts__cont__clave">
            <span class="feedback__starts__text {$locale}">
              {$_("Likely to recommend")}
            </span>
          </div>
          <div class="col s12 feedback__starts__cont__single">
            {#each statesRecomendate as starc, i}
              <i
                class="material-icons star {i < indexr
                  ? classStarRecom
                  : classStarBase}"
              >
                star
              </i>
            {/each}
          </div>
        </div>
      </div>
    {/if}

    {#if comments}
      <div class="col s12 feedback__comments">
        <span class="feedback__comments__text"> "{comments}" </span>
      </div>

      <div class="col s12 feedback__copy" on:click={copyComment}>
        <div class="feedback__copy__cont">
          {#if !nocopy}
            <i class="material-icons feedback__copy__icon"> content_copy </i>
          {:else}
            <i class="material-icons feedback__copy__icon"> check_circle </i>
          {/if}

          <span class="feedback__copy__text {$locale}">
            {$_("Copy feedback")}
          </span>
        </div>
      </div>
    {/if}
    {#if recomendedLength <= 3}
      <div class="col s12 feedback__title mrg__top">
        <span class="feedback__comments__text {$locale}">
          {$_(
            "May we contact you to discuss your experience? If you agree, please click on the button below to share your phone number or email to contact you."
          )}
        </span>
      </div>

      {#if emailSurvey}
        <div class="col s12 feedback__actions">
          <button
            class="btn button--continuebase"
            on:click={() => clickShare()}
          >
            <span class="text--button {$locale}"
              >{$_("Share phone number or email")}</span
            >
          </button>
        </div>
      {/if}
    {/if}
  </div>
  <div class="col s12 feedback__footer">
    <div class="col s12 m5 feedback__footer__powered">
      <div class="feedback__footer__powered__move">
        <span class="feedback__footer__text"> Powered by</span>
        <img
          class="feedback__footer__logo"
          alt="footerlogo"
          src="img/logo-guesthub.png"
        />
      </div>
    </div>
    <div class="col s12 m7 feedback__footer__copyright">
      <span class="feedback__footer__text"> © {getFullYear} GuestHub </span>
      <span class="feedback__footer__text {$locale}">
        {$_("All rights reserved")}</span
      >
    </div>
  </div>
</div>

<style>
  .container-fluid {
    height: 100% !important;
  }

  .mrg__top {
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .feedback {
    min-height: 95% !important;
  }

  .feedback__cont__primary {
    text-align: center;
    position: relative;
    top: -83px;
    margin-bottom: -70px;
  }

  .feedback__top {
    padding: 1.625rem 0rem 0rem 0rem !important;
    text-align: center;
  }

  .feedback__cont__logo {
    display: flex;
    justify-content: center;
    padding: 1.625rem 0rem 0rem 0rem !important;
  }

  .feedback__logo {
    width: 149px;
    height: 149px;
    opacity: 1;
    border: 1px solid #c3c3c3;
    border-radius: 8px;
  }

  .feedback__cover {
    width: 100%;
    height: 100%;
    opacity: 1;
    border: 1px solid #c3c3c3;
    border-radius: 8px;
    object-fit: cover;
  }

  .cont__cover {
    height: 24rem;
    width: 100%;
  }

  .feedback__title {
    text-align: center;
  }
  .title__top {
    padding: 1rem 0rem 0rem 0rem !important;
    text-align: center;
  }

  .feedback__title__text {
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__starts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 2.25rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__cont__clave {
    text-align: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__cont__single {
    text-align: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__contex {
    float: left;
    margin-right: 15px;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__contrem {
    width: auto;
    float: left;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .strborder {
    font-size: 1.5rem;
    color: #c3c3c3;
  }

  .startRed {
    font-size: 1.5rem;
    color: #ff4855 !important;
  }

  .startOrange {
    font-size: 1.5rem;
    color: #ffa64d !important;
  }

  .startYellow {
    font-size: 1.5rem;
    color: #fedf61 !important;
  }

  .star {
    position: relative;
    animation-name: staranimation;
    animation-duration: 4s;
    animation-delay: -2s;
  }

  @keyframes staranimation {
    0% {
      bottom: 80px;
      opacity: 0;
    }
    25% {
      bottom: 60px;
      opacity: 0.7;
    }
    50% {
      bottom: 40px;
      opacity: 0.5;
    }
    75% {
      bottom: 20px;
      opacity: 0.2;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }

  .feedback__starts__text {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #2f4050;
    text-transform: uppercase;
    opacity: 1;
  }

  .feedback__comments {
    word-break: break-word;
    text-align: center;
    padding: 1.313rem 0rem 0rem 0rem !important;
  }

  .feedback__copy {
    display: flex;
    justify-content: center;
    padding: 0.531rem 0rem 0rem 0rem !important;
  }

  .feedback__copy__cont {
    display: flex;
    cursor: pointer;
  }

  .feedback__copy__icon {
    width: 16px;
    height: 18px;
    color: #1ab394;
    margin-right: 0.77rem;
    opacity: 1;
  }

  .feedback__copy__text {
    font-size: 1rem;
    color: #1ab394;
    opacity: 1;
  }

  .feedback__share {
    padding: 3.031rem 0rem 0rem 0rem !important;
  }

  .feedback__comments__text {
    font-size: 1.125rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__footer {
    bottom: 0;
    z-index: -1;
  }

  .feedback__footer__logo {
    width: 6rem;
    height: 2rem;
    margin-bottom: 5px;
    opacity: 1;
  }

  .feedback__footer__powered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__text {
    font-size: 0.75rem;
    letter-spacing: 0px;
    color: #212121;
    margin-left: 0.3rem !important;
    opacity: 1;
  }

  .feedback__footer__powered__move {
    display: flex;
    align-items: center;
  }

  .feedback__share__cont {
    text-align: center;
  }

  .feedback__share__btns {
    padding: 0.5rem 0rem 0rem 0rem !important;
  }

  .feedback__share__btns__inter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feedback__share__cont__text {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .class-tipadvisor {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    width: 147px;
    height: 38px;
    border: 1px solid #1bb394;
    border-radius: 4px;
    margin-bottom: 0.938rem;
    opacity: 1;
  }

  .class-yelp {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    width: 147px;
    height: 38px;
    border: 1px solid #bf2519;
    border-radius: 4px;
    margin-bottom: 0.938rem;
    opacity: 1;
  }

  .class-google {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    width: 147px;
    height: 38px;
    border: 1px solid #4285f4;
    border-radius: 4px;
    margin-bottom: 0.938rem;
    opacity: 1;
  }

  .class-text-tripadvisor {
    color: #1bb394;
  }

  .class-text-yelp {
    color: #bf2519;
  }

  .class-text-google {
    color: #4285f4;
  }

  @media only screen and (max-width: 601px) {
    .cont__cover {
      height: 16rem !important;
    }
  }

  @media only screen and (min-width: 601px) {
    .feedback__footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .feedback__footer__powered__move {
      width: auto;
      float: right;
    }

    .feedback__footer__powered__move {
      float: right;
    }

    .feedback__footer__powered {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }

    .feedback__footer__text {
      float: left;
    }

    .feedback__footer__copyright {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }

    .feedback__share__btns__inter {
      width: auto !important;
      padding: 0rem 0rem 0rem 0rem !important;
      margin-left: 0.625rem !important;
    }

    .feedback__share__btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (min-width: 701px) {
    /* .feedback__starts {
      display: block;
    } */

    .feedback__starts__contex {
      display: flex;
      align-items: center;
    }

    .feedback__starts__contrem {
      float: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .feedback__starts__cont__clave {
      text-align: left;
      margin: 0rem !important;
      width: auto !important;
    }

    .feedback__starts__cont__single {
      display: flex;
      align-items: center;
      margin: 0rem !important;
      width: auto !important;
    }
  }

  .feedback__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem 0rem 0rem !important;
  }

  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212121 0% 0% no-repeat padding-box !important;
    float: right;
    cursor: pointer;
    width: auto;
    height: 38px;
    border-radius: 8px;
    opacity: 1;
  }

  .text--button {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: initial;
    opacity: 1;
  }
</style>
