<script>
  import Map from "./map.svelte";

  export let propertyName;
  export let propertyLongitud;
  export let propertyLattud;
  export let direction;
  export let city;
  export let country;
  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div
  class="card col s12"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
>
  <div class="cont__map col s12 m7">
    <Map bind:propertyLongitud bind:propertyLattud />
  </div>
  <div
    class="cont__data col s12 m5"
    use:styling={{
      background: managementColors.general.backgroundColorCards,
    }}
  >
    <span
      class="title_data col s12"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {propertyName}
    </span>
    <span
      class="text_data col s12"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {direction}
    </span>
    <span
      class="text_data col s12"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {city}
    </span>
    <span
      class="text_data col s12"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {country}
    </span>
  </div>
</div>

<style>
  .card {
    height: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    margin-top: 16px !important;
    padding: 0px 0px 15px 0px !important;
  }

  .cont__map {
    height: 200px;
    background: rgba(0, 0, 0, 0.2);
    padding: 0px !important;
  }

  .cont__data {
    min-height: 100px;
    padding: 15px 0px 0px 5px !important;
  }

  .title_data {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .text_data {
    font-size: 0.875rem;
  }

  @media only screen and (min-width: 601px) {
    .card {
      padding: 0px !important;
    }
  }
</style>
