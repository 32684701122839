import environments from "./environments";
import { locale } from "svelte-i18n";

const isLogIn = (propertyId) =>
  localStorage.getItem("auth")
    ? loginMagicLink(propertyId, { code: JSON.parse(localStorage.getItem("auth")).code })
    : Promise.reject({ code: 401 });

const logOut = (propertyId) =>
  localStorage.removeItem("auth") ||
  localStorage.removeItem("reservation")
  || Promise.resolve();

const updateLanguage = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "language": data.language,
    },
  }).then((response) => (response ? true : Promise.reject(false)));

const loginMagicLink = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [propertyId],
      }),
    },
  }).then((response) => (response ? response.json() : Promise.reject(response)))
    .then(response => {
      localStorage.setItem("auth", JSON.stringify(Object.assign({}, {
        guestName: response.guestName,
        propertyId: propertyId,
        reservationId: response.reservationId,
        reservationVip: response.reservationVip,
        roomNumber: response.roomNumber,
        code: data.code
      })));
      if (response.language) {
        localStorage.setItem(
          "language",
          JSON.stringify(response.language)
        );
        locale.set(response.language);
      }
      localStorage.setItem(
        "reservation",
        JSON.stringify(new Date().getTime())
      );
      return response;
    }).catch(logOut);

const login = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/guest`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [propertyId],
      }),
    },
  }).then((response) => (response ? response.json() : null))
    .then(response => {
      localStorage.setItem("auth", JSON.stringify(Object.assign({}, {
        guestName: response.guestName,
        propertyId: propertyId,
        reservationVip: response.reservationVip,
        reservationId: response.reservationId,
        roomNumber: response.roomNumber,
        code: data.code
      })));
      if (response.language) {
        localStorage.setItem(
          "language",
          JSON.stringify(response.language)
        );
        locale.set(response.language);
      }
      localStorage.setItem(
        "reservation",
        JSON.stringify(new Date().getTime())
      );
      return response;
    });

const sendCodeEmail = (propertyId, data) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/guest/send`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "guesthub-context": JSON.stringify({
          properties: [propertyId],
        }),
      },
    }
  ).then((response) => (response.ok ? true : Promise.reject(response)));

export default {
  isLogIn,
  logOut,
  login,
  loginMagicLink,
  sendCodeEmail,
  updateLanguage
};
