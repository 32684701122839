<script>
  import { _, locale } from "svelte-i18n";
  import ButtonReloading from "../../button-loading/component.svelte";
  import PropertyFeatures from "../../../property-features-service";

  export let title;
  export let optionsForm;
  export let sendOptionCancel;
  export let managementColors;

  let colorSelectBase;
  let optionWhite;
  let index;
  let valueOption = "";
  let inputactive = false;
  let moreOptions = false;
  let textBtn = "Send";
  let blockButton = true;
  let saving = false;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationColor = () => {
    if (managementColors.general.backgroundColor != "#FFFFFF") {
      optionWhite = false;
      colorSelectBase = "";
    } else {
      optionWhite = true;
      colorSelectBase = "#212121";
    }
  };

  let clickOption = (option, i) => {
    setFontFamily(null);
    saving = false;
    if (!saving) {
      index = i;
      switch (option.nameOption) {
        case "Other":
          moreOptions = true;
          valueOption = "";
          blockButton = true;
          resetBtnVariables(blockButton, false);
          break;
        default:
          moreOptions = false;
          valueOption = option.nameOption;
          blockButton = false;
          resetBtnVariables(blockButton, false);
      }
    }
  };

  let changeOtherOption = () => {
    setFontFamily(null);
    const element = document.getElementById("valueOption").value.trim().length;
    if (element == 0 || valueOption.length == 0) {
      valueOption = "";
      blockButton = true;
      resetBtnVariables(blockButton, false);
    } else {
      blockButton = false;
      resetBtnVariables(blockButton, false);
    }
  };

  let eventInput = () => {
    inputactive = true;
  };

  let handleSend = () => {
    saving = true;
    if (valueOption && !blockButton) {
      blockButton = false;
      resetBtnVariables(blockButton, false);
      sendOptionCancel(valueOption);
    }
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  setFontFamily(null);

  let resetBtnVariables = (blockButton, loginError) => {};

  validationColor();
</script>

<div class="form col s12">
  <div class="cont__title col s12">
    <span
      class="text__title {$locale}"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_(title)}
    </span>
  </div>
  <div class="cont__option col s12">
    {#each optionsForm as option, i}
      {#if index == i}
        <div
          class="col s12 {optionWhite ? 'option-for-white' : 'option-for-dark'}"
          use:styling={{ border: `1px solid${managementColors.color}` }}
          on:keyup={() => null}
          on:click={() => clickOption(option, i)}
        >
          <span
            class=" {optionWhite
              ? 'text__option-for-white'
              : 'text__option-for-dark'} {$locale}"
            use:styling={{ color: managementColors.color }}
          >
            {$_(option.nameOption)}
          </span>
        </div>
      {:else}
        <div
          class="col s12 {optionWhite ? 'option-for-white' : 'option-for-dark'}"
          use:styling={{ border: `1px solid${colorSelectBase}` }}
          on:keyup={() => null}
          on:click={() => clickOption(option, i)}
        >
          <span
            class=" {optionWhite
              ? 'text__option-for-white'
              : 'text__option-for-dark'} {$locale}"
            use:styling={{ color: colorSelectBase }}
          >
            {$_(option.nameOption)}
          </span>
        </div>
      {/if}
    {/each}
  </div>

  {#if moreOptions}
    <div class="cols 12 cont__other__option">
      <div class="input-field col s12">
        <input
          use:styling={{
            "border-bottom": ` 1px solid ${managementColors.color}`,
          }}
          maxlength="150"
          placeholder={$_("Detail your answer")}
          id="valueOption"
          on:input={() => eventInput()}
          on:click={() => eventInput()}
          bind:value={valueOption}
          on:input={changeOtherOption}
          type="text"
          class="validate {managementColors.general.colorMode == 'white'
            ? 'inputs-fields--white'
            : managementColors.general.colorMode == 'dark'
              ? 'inputs-fields--dark'
              : ''}"
        />
        <label
          use:styling={{ color: `${managementColors.color}` }}
          class="active label__input {$locale}"
          class:activeinput={inputactive == true}
          for="valueOption"
        >
          {$_("Please detail your answer")}
        </label>
        <span class="input__other__option--helper">
          {valueOption.length + "/150"}
        </span>
      </div>
    </div>
  {/if}

  <div class="cont__actions col s12">
    <ButtonReloading
      bind:handleLogin={handleSend}
      bind:resetBtnVariables
      bind:managementColors
      bind:textBtn
    />
  </div>
</div>

<style>
  .form {
    padding: 0px !important;
  }

  .cont__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 24px 16px 24px !important;
    text-align: left;
  }

  .text__title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .cont__option {
    padding: 0px 24px 0px 24px !important;
  }

  .cont__other__option {
    padding: 0px 24px 16px 12px !important;
  }

  .option-for-white {
    display: flex;
    align-items: center;
    height: 35px;
    border: 1px solid #212121;
    margin-bottom: 16px !important;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  .option-for-dark {
    display: flex;
    align-items: center;
    height: 35px;
    border: 1px solid #f4f4f4;
    margin-bottom: 16px !important;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  .text__option-for-white {
    font-size: 0.875rem;
    font-weight: 500;
    color: #212121;
  }

  .text__option-for-dark {
    font-size: 0.875rem;
    font-weight: 500;
    color: #f4f4f4;
  }

  .cont__actions {
    padding-right: 34% !important;
  }

  .input__other__option--helper {
    font-size: 0.875rem;
    color: #686c6d;
    float: right;
  }
</style>
