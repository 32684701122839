<script>
  export let contact;
  export let managementColors;
  let icon, myclass;
let href = "";
  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  switch (contact.contenType) {
    case "phone":
      icon = "local_phone";
      myclass = null;
      href = "tel:";
      break;
    case "mail":
      icon = "local_post_office";
      myclass = null;
      href = "mailTo:";
      break;
    case "website":
      icon = "insert_link";
      myclass = null;
      href = "";
      break;
    case "instagram":
      icon = null;
      myclass = "fab fa-instagram icon-social";
      href = "";
      break;
    case "facebook":
      icon = null;
      myclass = "fab fa-facebook-square icon-social";
      href = "";
      break;
    case "twitter":
      icon = null;
      myclass = "fab fa-twitter icon-social";
      href = "";
      break;
    case "youtube":
      icon = null;
      myclass = "fab fa-youtube icon-social";
      href = "";
      break;
    case "linkedin":
      icon = null;
      myclass = "fab fa-linkedin icon-social";
      href = "";
      break;
    case "whatsapp":
      icon = null;
      myclass = "fab fa-whatsapp icon-social";
      href = "";
      break;
    default:
      icon = null;
      myclass = null;
      href = "";
      break;
  }

  const hover = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px${managementColors.color}`;

      const element = document.getElementById(`${i}`);
      element
        ? element.style.setProperty("background", managementColors.color)
        : null;
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty(
            "color",
            managementColors.general.backgroundColorCards
          )
        : null;

      const elementDivIcon = document.getElementById(`${i}cont-icon`);
      element && elementDivIcon
        ? elementDivIcon.style.setProperty(
            "background",
            managementColors.general.backgroundColorCards
          )
        : null;

      const elementIcon = document.getElementById(`${i}icon`);
      element && elementIcon
        ? elementIcon.style.setProperty("color", managementColors.color)
        : null;
    }
  };

  const handleMouseOut = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px rgb(0 0 0 / 20%)`;

      const element = document.getElementById(`${i}`);
      element
        ? element.style.setProperty(
            "background",
            managementColors.general.backgroundColorCards
          )
        : null;
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty(
            "color",
            managementColors.general.colorLetter
          )
        : null;

      const elementDivIcon = document.getElementById(`${i}cont-icon`);
      element && elementDivIcon
        ? elementDivIcon.style.setProperty(
            "background",
            managementColors.color
          )
        : null;

      const elementIcon = document.getElementById(`${i}icon`);
      element && elementIcon
        ? elementIcon.style.setProperty(
            "color",
            managementColors.general.backgroundColorCards
          )
        : null;
    }
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<div
  id={contact._id}
  on:mouseover={() => hover(contact._id)}
  on:mouseout={() => handleMouseOut(contact._id)}
  class="notifications col s12 m3 l3 xl3"
  use:styling={{
    background: managementColors.general.backgroundColorCards, 
  }}
>
  <div class="icon__cont col s1 noppading">
    <div
      use:styling={{ background: managementColors.color }}
      class="notification__icon"
    >
      {#if !contact.preview}
        {#if icon}
          <i
            use:styling={{
              color: managementColors.general.backgroundColorCards,
            }}
            class="material-icons icon-contac"
          >
            {icon}
          </i>
        {:else if myclass}
          <i
            use:styling={{ color: managementColors.general.backgroundColor }}
            class={myclass}
          />
        {/if}
      {:else}
        <img
          src={contact.preview}
          alt="{contact.contenType}-{contact.title}"
          style="width: 100%;"
        />
      {/if}
    </div>
  </div>

  <div class=" col s11 noppading">
    <div class="notification__count">
      <a href="{href}{contact.link}">
        <span
          class="text_contact"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {contact.title.substr(0, 30)}
        </span>
      </a>
    </div>
  </div>
</div>

<style>
  .contacts {
    padding: 16px 0px 0px 0px !important;
  }

  .notifications {
    min-height: 48px !important;
    min-width: 200px;
    height: auto;
    word-break: break-word;
    display: flex;
    align-items: center;
    border-radius: 24px;
    margin-bottom: 16px !important;
    padding-left: 6px !important;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
    opacity: 1;
    cursor: pointer;
  }

  .notification__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1ab394;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px !important;
  }

  .notification__icon > img {
    width: 100%;
    border-radius: 50px;
  }

  .notification__count {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .icon-contac {
    font-size: 1.5rem;
    opacity: 1;
  }

  .text_notifications {
    font-size: 0.875rem;
    color: #212121;
  }

  .num_notifications {
    font-size: 0.875rem;
    color: #1ab394;
    font-weight: 700;
    margin: 0px 3px 0px 3px !important;
  }

  .text_contact {
    font-size: 0.875rem;
    color: #1ab394;
    opacity: 1;
  }

  .noppading {
    padding: 0px !important;
  }

  .icon__cont {
    max-width: 37px !important;
    min-width: 36px !important;
    margin: 0px 8px 0px 0px !important;
  }

  @media only screen and (min-width: 601px) {
    .notifications {
      margin-right: 16px;
    }

    .text_contact {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .text_contact {
      color: #212121;
    }
  }
</style>
