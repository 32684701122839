<script>
  import { _ } from "svelte-i18n";
  import { format } from "date-fns";

  export let dataRequest;
  export let managementColors;
  export let filterSvg;
  export let defaultStyles;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div
  class="col s12 card card--request"
  style="padding: 0px;"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
>
  <div class="div__nomobile">
    <span class="txt__date__reser">
      {format(new Date(dataRequest.executionDate), "dd")}
      {$_(format(new Date(dataRequest.executionDate), "MMM"))}
      {format(new Date(dataRequest.executionDate), "yyyy")}
      {format(new Date(dataRequest.executionDate), "HH:mm")}
      <span class="txt__idreser">
        #{dataRequest.idReference}
        <!-- #{dataRequest.reservationId
          ? dataRequest.reservationId
          : dataRequest.idReference} -->
      </span>
    </span>
  </div>
  <div class="col s12 div__info__service">
    <div
      use:styling={{ background: managementColors.color }}
      class="cont__header-icon--create"
    >
      <img
        class={filterSvg && managementColors.general.colorMode == "white"
          ? "header-icon--white"
          : filterSvg && managementColors.general.colorMode == "dark"
            ? "header-icon--dark"
            : "header-icon--completed"}
        alt="img"
        src="/img/reservation.svg"
      />
    </div>

    <span
      class="header_nameproperty"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_("Check in")}
    </span>
  </div>

  <div class="col s12 div__text">
    <!-- <span
      class="div__name__text"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
    {$_("Reservation")}
      <span class="amount__reser"> $ 21000</span> 
    </span>-->
    <div style="padding-top: 0px;">
      <span
        class="name__count"
        style="text-transform: uppercase; color: {managementColors.general
          .colorLetterNumId}"
      >
        {dataRequest.firstName}
        {dataRequest.lastName}
        {#if dataRequest.companions.length > 0}
          <span
            use:styling={{
              background: managementColors.general.backgroundDivFotter,
              color: managementColors.general.colorLetterNumId,
            }}
            class="cont__amoun__person"
          >
            <i
              class="material-icons icon__person"
              style="color: {managementColors.general.colorLetterNumId}"
            >
              person</i
            >
            {dataRequest.companions.length}
          </span>
        {/if}
      </span>
    </div>
  </div>

  {#if dataRequest.rateAmount && !dataRequest.isCompanion}
    <div clas="col s12">
      <div
        class="col s12 cont__subtotal"
        use:styling={{
          background: managementColors.general.backgroundDivFotter,
        }}
      >
        <span
          class="price__subtotal"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          SUBTOTAL
          <span
            class="price__subtotal"
            style="float: right;"
            use:styling={{ color: managementColors.general.colorLetter }}
            >${dataRequest.rateAmount}
          </span>
        </span>
      </div>
    </div>
  {/if}
</div>

<div
  class="col s12 card card--request"
  style="padding: 0px;"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
>
  <div class="cont__img__id">
    <img
      style="width: 100%; border-radius: 4px;"
      src={dataRequest.documentImagenFront.url}
      alt=""
    />
    {#if dataRequest.documentImagenBack.url}
      <img
        style="width: 100%; border-radius: 4px;"
        src={dataRequest.documentImagenBack.url}
        alt=""
      />
    {/if}
  </div>

  <div class="cont__information">
    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("FIRST NAME")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.firstName}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("LAST NAME")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.lastName}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("BIRTH DATE")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {format(new Date(dataRequest.birthday), "dd/MM/yyyy")}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("NATIONALITY")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.nationality.name}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("TYPE OF DOCUMENT")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {[
          {
            codename: "passport",
            name: $_("Passport"),
          },
          {
            codename: "dni",
            name: $_("Identity card"),
          },
          {
            codename: "licence-driver",
            name: $_("Drive Licence"),
          },
          {
            codename: "birth-certificate",
            name: $_("Birth Certificate"),
          },
        ].find((doc) => doc.codename == dataRequest.documentType).name}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("ID NUMBER")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.documentNumber}
      </span>
    </div>

    {#if dataRequest.documentType != "birth-certificate"}
      <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
        <span
          class="title__information"
          use:styling={{ color: managementColors.general.colorSame }}
        >
          {$_("DOCUMENT EXPIRATION DATE")}
        </span>

        <span
          class="data__text"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {format(new Date(dataRequest.documentExpirationDate), "dd/MM/yyyy")}
        </span>
      </div>
    {/if}

    {#if dataRequest.typePerson != "child" && dataRequest.typePerson != "baby"}
      <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
        <span
          class="title__information"
          use:styling={{ color: managementColors.general.colorSame }}
        >
          {$_("PROFESSION")}
        </span>

        <span
          class="data__text"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {#if dataRequest.profession != true}
            {dataRequest.profession}
          {:else}
            {$_("No data")}
          {/if}
        </span>
      </div>
    {/if}

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("PHONE")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.phone}
      </span>
    </div>

    <div class="cont__desktop__columns" style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("EMAIL")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.email}
      </span>
    </div>

    <div style="padding: 0px 0px 24px 0px">
      <span
        class="title__information"
        use:styling={{ color: managementColors.general.colorSame }}
      >
        {$_("RESIDENCE ADDRESS")}
      </span>

      <span
        class="data__text"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {dataRequest.address}
      </span>
    </div>

    {#if dataRequest.typePerson != "child" && dataRequest.typePerson != "baby"}
      <div>
        <span
          class="title__information"
          use:styling={{ color: managementColors.general.colorSame }}
        >
          {$_("AFFILIATE NUMBER")}
        </span>

        <span
          class="data__text"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {#if dataRequest.afiliateNumber && dataRequest.afiliateNumber.length > 0}
            {dataRequest.afiliateNumber}
          {:else}
            {$_("No data")}
          {/if}
        </span>
      </div>
    {/if}
  </div>
</div>

<style>
  .card--request {
    box-shadow: 0px 0px 8px #00000029 !important;
    border-radius: 4px !important;
  }

  .txt__date__reser {
    font-size: 14px;
    color: #686c6d;
  }

  .txt__idreser {
    font-weight: 700;
    margin-left: 16px;
  }

  .cont__check {
    padding: 0px !important;
  }
  .div__text {
    padding: 0px 16px 16px 16px !important;
    word-break: break-word;
  }

  .cont__amoun__person {
    background: #363636;
    border-radius: 4px;
    padding: 5px 7px 5px 7px !important;
    position: relative;
    left: 9px;
    top: 1px;
    font-weight: 700;
    font-size: 12px;
  }

  .icon__person {
    position: relative;
    left: -4px;
    top: 4px;
    font-size: 18px;
    color: #999999;
  }
  .div__name__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
  }

  .amount__reser {
    float: right;
    font-weight: 600;
  }

  .div__name__text--category {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div__info__service {
    display: flex;
    align-items: center;
    padding: 16px 16px 19px 16px !important;
  }

  .cont__header-icon--create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
  }

  .header-icon--white {
    width: 28px;
    height: 28px;
    filter: invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg)
      brightness(117%) contrast(100%);
  }

  .header-icon--dark {
    width: 28px;
    height: 28px;
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
  }

  .header-icon--completed {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 4px 8px #00000029;
  }

  .header_nameproperty {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .div__date {
    padding: 16px 16px 4px 16px !important;
  }

  .request__id {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .price__subtotal {
    font-weight: 700;
    font-size: 14px;
  }

  .cont__subtotal {
    padding: 16px 16px 16px 16px !important;
    border-radius: 0px 0px 4px 4px;
  }

  .title__information {
    display: block;
    font-size: 10px;
    font-weight: 600;
    color: #686c6d !important;
  }

  .cont__information {
    padding: 19px 16px 24px 16px !important;
  }

  @media only screen and (min-width: 20px) {
    .div__name__text {
      font-size: 14px;
      font-weight: 600;
    }

    .name__count {
      font-size: 10px;
    }

    .div__name__text--category {
      font-size: 14px;
    }

    .header_nameproperty {
      font-size: 16px;
    }

    .data__text {
      font-size: 14px;
    }

    .title__information {
      font-size: 10px;
    }

    .check__and__checkout {
      text-align: left;
      width: 100%;
      display: block;
      font-size: 10px;
    }

    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 16px;
      font-weight: 700;
    }

    .div__info__service {
      display: flex;
      align-items: center;
      padding: 16px 16px 19px 16px !important;
    }

    .div__checkin {
      padding: 8px 0px 8px 16px;
    }

    .div__checkout {
      padding: 8px 16px 8px 0px;
    }

    .div__text {
      padding: 0px 16px 16px 16px !important;
      word-break: break-word;
    }

    .cont__subtotal {
      padding: 16px 16px 16px 16px !important;
    }

    .cont__img__id {
      padding: 16px 16px 0px 16px;
    }

    .cont__information {
      padding: 19px 16px 24px 16px !important;
    }

    .cont__desktop__columns {
      width: 100% !important;
    }

    .div__nomobile {
      padding: 16px 16px 0px 16px !important;
    }
  }

  @media only screen and (min-width: 601px) {
    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 24px;
      font-weight: 700;
    }

    .div__info__service {
      display: flex;
      align-items: center;
      padding: 16px 32px 19px 32px !important;
    }

    .div__checkin {
      padding: 8px 0px 8px 32px;
    }

    .div__checkout {
      padding: 8px 32px 8px 0px;
    }

    .div__text {
      padding: 0px 32px 16px 32px !important;
      word-break: break-word;
    }

    .cont__subtotal {
      padding: 16px 32px 16px 32px !important;
    }

    .cont__img__id {
      padding: 16px 32px 0px 32px;
    }

    .cont__information {
      padding: 19px 32px 24px 32px !important;
    }

    .div__nomobile {
      padding: 16px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .header_nameproperty {
      font-size: 24px;
    }

    .div__name__text {
      font-size: 16px;
    }

    .name__count {
      font-size: 12px;
    }

    .data__text {
      font-size: 16px;
    }

    .title__information {
      font-size: 12px;
    }

    .check__and__checkout {
      text-align: left;
      width: 100%;
      display: block;
      font-size: 12px;
    }

    .div__nomobile {
      padding: 16px 32px 0px 32px !important;
      display: block;
    }

    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 32px;
      font-weight: 700;
    }

    .cont__desktop__columns {
      width: 50% !important;
      display: inline-block;
    }

    .cont__information {
      display: inline-table;
    }
  }
</style>
