<script>
  import { _, locale } from "svelte-i18n";
  import NavigationTop from "../components/navigation/top-component.svelte";
  import Reloading from "../components/navigation/reloading-component.svelte";
  import ContactInformationComponent from "./contact-information.component.svelte";
  import Location from "../components/location/card-component.svelte";
  import AditionalFilesComponent from "./aditional-files.component.svelte";
  import Button_share from "../components/float-button/component.svelte";
  import PropertyFeatures from "../property-features-service";
  import Reservations from "../reservations-service";
  import RoutesServices from "../management-routes-service";
  import { location, querystring } from "svelte-spa-router";
  import Toast from "../components/toast/component.svelte";

  export let params = {};
  let reload = true;
  let propertyData = {};
  let language;
  let languagesEnabled = [];
  let faeturesList = [];
  let iframeFromLanding;
  let showBtnBack = true;
  let propEmpty = "";
  let dowloadFile = false;
  let fileDowloadText;
  let backgroundColorView;
  let managementColors = {};
  let phoneProperty = null;

  let state = Object.assign({ features: [], reservation: undefined }, params);

  JSON.parse(localStorage.getItem("managementColors"))
    ? (managementColors = JSON.parse(localStorage.getItem("managementColors")))
    : (managementColors = {
        color: "",
        title: "",
        general: "",
      });
  const validationColors = (guestappsettins) => {
    managementColors = {
      filter: guestappsettins.filter,
      color: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .color,
      title: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .title,
      general: Reservations.getColorsContrast(guestappsettins),
    };

    localStorage.setItem("managementColors", JSON.stringify(managementColors));
  };

  const init = (lang) => {
    faeturesList = [];

    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(([features, guestappsettins, propertyInformation]) => {
      propertyInformation ? (reload = false) : true;
      propertyData = propertyInformation;

      faeturesList = features.filter(
        (fet) => fet.enabled || fet.codename == "property.details",
      );

      validationLanguage(
        guestappsettins.languages,
        propertyInformation.language.code,
      );
      validationDesciptionProperty();
      validationCallIframe();
      validationShowBtn();
      validationColors(guestappsettins);
      setFontFamily(guestappsettins.fontFamily);
    });
  };

  init(
    JSON.parse(localStorage.getItem("language")) ||
      window.navigator.language.split("-")[0],
  );

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  const validationCallIframe = () => {
    $querystring ? (iframeFromLanding = true) : (iframeFromLanding = false);
  };

  const validationShowBtn = () => {
    JSON.parse(localStorage.getItem("showBtnBack")) == false
      ? (showBtnBack = false)
      : null;

    if (faeturesList.length > 1) {
      showBtnBack = true;
      RoutesServices.setLastRoute("pop");
      //goThisRoute = `/properties/${state.propertyId}`;
    }
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleLanguage = (e) => {
    init(e);
  };

  let handleFileDownloading = (e) => {
    dowloadFile = true;
    fileDowloadText = "File downloading";
  };

  let getNameByLanguage = (names) => {
    return names.find(
      (n) =>
        n.code ==
          (localStorage.getItem("lang")
            ? JSON.parse(localStorage.getItem("lang")).lang
            : "en") && n.value.toString().trim().length > 0,
    )
      ? names.find(
          (n) =>
            n.code ==
              (localStorage.getItem("lang")
                ? JSON.parse(localStorage.getItem("lang")).lang
                : "en") && n.value.toString().trim().length > 0,
        ).value
      : names.find((n) => n.value.toString().trim().length > 0)
        ? names.find((n) => n.value.toString().trim().length > 0).value
        : names[0]
          ? names[0].value
          : "";
  };

  let validationDesciptionProperty = () => {
    setTimeout(() => {
      const element = document.getElementById("descriptionProperty");

      element
        ? (element.innerHTML = propertyData.descriptions.find(
            (desc) => desc.lang == JSON.parse(localStorage.getItem("language")),
          ).value)
        : null;
    }, 1000);
  };
</script>

<div class="container-fluid row">
  {#if !reload}
    <div class="header_component row col s12 nopadding">
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
            ? 'navifation-cont-border--dark'
            : ''}"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={propertyData.id}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:managementColors
          bind:iframeFromLanding
          bind:showBtnBack
        />
      </div>
    </div>
    <div
      class="property__cont col s12 row nopadding"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      {#if propertyData.cover.url}
        <div class="img-font__cont row col s12">
          <img class="img-font" src={propertyData.cover.url} />
        </div>
      {/if}
      <div
        class="card info__card col s12 row nopadding"
        use:styling={{ background: managementColors.general.backgroundColor }}
        class:info__card--top={propertyData.cover.url}
      >
        <div
          class="card-content col l10 offset-l1 xl8 offset-xl2 s12 nopadding"
        >
          <div class="title-content col s12">
            <!--<i class="material-icons icon--back">arrow_back</i>-->
            <span
              class="text_title {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {propertyData.name}
            </span>
          </div>

          {#if propertyData.lang != "en"}
            <div class="name-content col s12">
              <span
                class="text_name"
                use:styling={{ color: managementColors.general.colorLetter }}
              >
                {getNameByLanguage(propertyData.descriptions || [])}
              </span>
            </div>
          {/if}

          {#if propertyData.description}
            <div
              class="description-content col s12"
              id="descriptionProperty"
              use:styling={{ color: managementColors.general.colorLetter }}
            />
          {/if}

          {#if propertyData.hourCheckIn && propertyData.hourCheckOut}
            <div class="time-content col s12 m9 l8 xl6">
              <div
                class="time-card col s12"
                use:styling={{
                  background: managementColors.general.backgroundColorCards,
                }}
              >
                <div class="col s5 time-left">
                  <div>
                    <span
                      class="text_check"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {$_("Check in hour")}
                    </span>
                  </div>
                  <div>
                    <span
                      class="text_hour"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {propertyData.hourCheckIn} hrs
                    </span>
                  </div>
                </div>
                <div class="col s1 time-arrow">
                  <i
                    class="material-icons icon-time"
                    use:styling={{ color: managementColors.color }}
                  >
                    keyboard_arrow_right
                  </i>
                </div>
                <div class="col s5 time-right">
                  <div>
                    <span
                      class="text_check"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {$_("Check out hour")}
                    </span>
                  </div>
                  <div>
                    <span
                      class="text_hour"
                      use:styling={{
                        color: managementColors.general.colorLetter,
                      }}
                    >
                      {propertyData.hourCheckOut} hrs
                    </span>
                  </div>
                </div>
              </div>
            </div>
          {/if}

          {#if propertyData.contactGuestInformation.length > 0}
            <div class="contact-content col s12">
              <span
                class="text_contact {$locale}"
                use:styling={{ color: managementColors.general.colorLetter }}
              >
                {$_("Contact information")}
              </span>
              <div class="contacts col s12">
                {#each propertyData.contactGuestInformation as contact, i}
                  <ContactInformationComponent
                    bind:contact
                    bind:managementColors
                  />
                {/each}
              </div>
            </div>
          {/if}
          <div class="location-content col s12">
            <span
              class="text_contact {$locale}"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {$_("Location")}
            </span>
            <a href={`${propertyData.locationUrl}`}>
              <Location
                bind:propertyName={propertyData.name}
                bind:propertyLongitud={propertyData.locationLongitude}
                bind:propertyLattud={propertyData.locationLatitude}
                bind:direction={propertyData.address}
                bind:city={propertyData.city}
                bind:country={propertyData.country.name}
                bind:managementColors
              />
            </a>
          </div>

          {#if propertyData.files.length > 0}
            <div class="add-content col s12">
              <span
                class="text_contact {$locale}"
                use:styling={{ color: managementColors.general.colorLetter }}
              >
                {$_("Additional information")}
              </span>
              <div class="addinfo">
                {#each propertyData.files as file, i}
                  <AditionalFilesComponent
                    bind:file
                    bind:handleFileDownloading
                    bind:managementColors
                  />
                {/each}
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
    <div class="cont__share">
      <Button_share bind:managementColors bind:dataShare={propertyData} />
    </div>
    {#if dowloadFile}
      <Toast
        bind:toastActive={dowloadFile}
        bind:messageToast={fileDowloadText}
      />
    {/if}
  {:else if managementColors.general}
    <div
      class="loader col s12"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <Reloading bind:managementColors />
    </div>
  {/if}
</div>

<style>
  .container-fluid {
    height: 100%;
    /* pointer-events: none; */
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }

  .property__cont {
    height: 100%;
    padding: 0px !important;
    position: relative;
    top: 60px;
  }

  .img-font__cont {
    height: 248px;
    position: fixed;
    z-index: 0;
    padding: 0px !important;
  }

  .img-font {
    object-fit: cover;
    object-position: center center;
    height: 248px;
    width: 100%;
  }

  .nopadding {
    padding: 0px !important;
  }

  .info__card {
    margin: 0px;
    border-radius: 35px 35px 0px 0px !important;
    box-shadow: none;
    z-index: 3;
    /* pointer-events: none; */
  }

  .info__card--top {
    margin: 0px;
    border-radius: 35px 35px 0px 0px !important;
    top: 200px;
    box-shadow: none;
    z-index: 3;
  }

  .card-content {
    padding: 30px 0px 0px 0px !important;
  }

  .title-content {
    display: flex;
    align-items: center;
  }

  .icon--back {
    font-size: 24px;
    color: #2f4050;
    margin-right: 8px !important;
  }

  .text_title {
    font-size: 18px;
    font-weight: 700;
  }

  .name-content {
    padding-top: 24px !important;
  }

  .description-content {
    padding-top: 24px !important;
  }

  .header_component {
    position: fixed;
    z-index: 10;
  }

  .time-content {
    padding-top: 32px !important;
  }

  .time-card {
    min-height: 67px !important;
    height: auto;
    border-radius: 8px;
    padding: 0px !important;
    opacity: 1;
    display: flex;
  }

  .contact-content {
    padding-top: 44px !important;
  }

  .social-content {
    padding-top: 16px !important;
    display: flex;
  }

  .location-content {
    padding-top: 34px !important;
  }

  .add-content {
    padding-top: 41px !important;
  }

  .time-left {
    height: 100%;
    float: left;
    padding-top: 5px !important;
  }

  .time-right {
    height: 100%;
    float: right;
    padding-top: 5px !important;
  }

  .time-arrow {
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin: 0% 5% 0% 4% !important;
  }

  .icon-time {
    color: #1ab394;
    font-size: 32px;
    opacity: 1;
  }

  .text_check {
    font-size: 10px;
    font-weight: 700;
  }

  .text_hour {
    font-size: 24px;
    font-weight: 700;
    white-space: nowrap;
  }

  .text_contact {
    font-size: 14px;
    font-weight: 700;
  }

  .cont__share {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 65px;
    min-height: 86px;
    z-index: 100 !important;
    margin: 0px;
    padding: 0px;
    background: transparent;
    z-index: -1;
    pointer-events: none;
  }

  .text_name {
    font-size: 14px;
  }

  .text_description {
    font-size: 14px;
    color: #212121;
  }

  .contacts {
    padding: 16px 0px 0px 0px !important;
  }

  .addinfo {
    padding-top: 16px !important;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 601px) {
    .card-content {
      padding: 32px 32px 0px 32px !important;
    }

    .text_title {
      font-size: 24px;
    }

    .text_name {
      font-size: 16px;
      font-weight: 700;
    }

    .text_description {
      font-size: 16px;
    }

    .text_contact {
      font-size: 16px;
    }

    .info__card--top {
      top: 294px;
    }

    .img-font__cont {
      height: 350px;
    }

    .img-font {
      height: 350px;
    }

    .addinfo {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0px 32px;
    }
  }

  @media only screen and (min-width: 992px) {
    .card-content {
      padding: 48px 0px 0px 0px !important;
    }

    .info__card--top {
      top: 384px;
    }

    .img-font__cont {
      height: 450px;
    }

    .img-font {
      height: 450px;
    }

    .cont__share {
      display: none;
    }
  }

  @media only screen and (min-width: 1200px) {
    .time-arrow {
      margin: 0% 10% 0% 10% !important;
    }
  }
</style>
