<script>
  import { _ } from "svelte-i18n";

  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div class="gueshub-services-reloading">
  <div class="preloader-wrapper big active">
    <div
      class="spinner-layer"
      use:styling={{
        "border-color": managementColors
          ? managementColors.color
          : null,
      }}
    >
      <div class="circle-clipper left">
        <div class="circle" />
      </div>
      <div class="gap-patch">
        <div class="circle" />
      </div>
      <div class="circle-clipper right">
        <div class="circle" />
      </div>
    </div>
  </div>
</div>

<style>
  .gueshub-services-reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
