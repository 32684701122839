<script>
  import { _, locale } from "svelte-i18n";
  import Reloading from "../../components/navigation/reloading-component.svelte";

  export let closeview;
  export let btnClose;
  export let imgView;
  export let reloading;
  export let title;
  export let numberPage;
  export let message;
  export let btnActionDesktop;
  export let btnActionMobile;
  export let textGoBtn;
  export let goBtn;
  export let secondBtn;
  export let textSecondBtn;
  export let textCallBtn;
  export let callBtn;
  export let ownerPhone;
  export let classImg;
  export let handleAction;
  export let handleActionSecond;
  export let classBtnGo;
  export let classBtnGoText;
  export let classBtnCall;
  export let managementColors;
  export let colorImgSvg;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const init = () => {
    callLanguageActive();
  };

  const callLanguageActive = () => {
    if (JSON.parse(localStorage.getItem("language"))) {
      locale.set(JSON.parse(localStorage.getItem("language")));
    } else {
      locale.set(window.navigator.language.split("-")[0]);
    }
  };

  let handleClose = () => {
    handleAction("again");
  };

  init();
</script>

<div
  class="col s12 message-view__cont row"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  <div
    class="message-view row col s12"
    use:styling={{
      background: managementColors.general.backgroundColor,
    }}
  >
    <div class="col s12 header">
      {#if btnClose}
        <i
          class="material-icons icon-close"
          use:styling={{ color: managementColors.general.colorLetter }}
          on:click={handleClose}
        >
          close
        </i>
      {/if}
    </div>
    <div style="width: 100%;" class="col s12 col l9 m12 s12">
      <div class="col s12 img-cont">
        {#if imgView}
          <img class={colorImgSvg} src={`/img/${imgView}`} alt="" />
        {/if}
        {#if reloading}
          <Reloading  bind:managementColors={managementColors} />
        {/if}
      </div>
      {#if numberPage}
        <div class="col s12 message-cont">
          <span class="message-cont__number">{numberPage}</span>
        </div>
      {/if}
      {#if title}
        <div class="col s12 message-cont">
          <span
            class="message-cont__text {$locale}"
            use:styling={{ color: managementColors.color }}
          >
            {$_(title)}
          </span>
        </div>
      {/if}

      {#if message}
        <div class="col s12 text-cont">
          <span
            class="text-cont__text {$locale}"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {$_(message)}
          </span>
        </div>
      {/if}

      <div class="col s12 row message-view__actions-second--desktop">
        {#if secondBtn}
          <div class="col message-home">
            <button
              class="btn-success class-second-btn"
              use:styling={{ background: managementColors.color }}
              on:click={() => handleActionSecond("go")}
            >
              <span
                use:styling={{ color: managementColors.general.colorLetterBaseButton }}
                class="class-second-btn__text  {$locale}"
              >
                {$_(textSecondBtn)}
              </span>
            </button>
          </div>
        {/if}
      </div>

      <div class="col s12 row message-view__actions--desktop">
        {#if goBtn}
          <div class="col   message-home">
            <button
              use:styling={{ background: managementColors.color }}
              class="btn-success {classBtnGo}"
              on:click={() => handleAction("again")}
            >
              <span
                use:styling={{ color: managementColors.general.colorLetterBaseButton }}
                class="text--button-home  {classBtnGoText} {$locale}"
              >
                {$_(textGoBtn)}
              </span>
            </button>
          </div>
        {/if}
        {#if callBtn}
          <div class="col  row message__button ">
            <a
              class="btn btn-success button--continuebase--desktop"
              use:styling={{ background: managementColors.color }}
              href={`tel:${ownerPhone}`}
            >
              <span class="text--button  {$locale}">{$_(textCallBtn)}</span>
            </a>
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div class="col s12 row message-view__actions">
    {#if secondBtn}
      <div class="col s12">
        <button
          class="btn-success  class-second-btn"
          use:styling={{ background: managementColors.color }}
          on:click={() => handleActionSecond("go")}
        >
          <span class="class-second-btn__text {$locale}"
            >{$_(textSecondBtn)}</span
          >
        </button>
      </div>
    {/if}
    {#if goBtn}
      <div class="col s12">
        <button
          use:styling={{ background: managementColors.color }}
          class="btn-success {classBtnGo}"
          on:click={() => handleAction("again")}
        >
          <span
            use:styling={{ color: managementColors.general.colorLetterBaseButton }}
            class="text--button-home {classBtnGoText}  {$locale}"
          >
            {$_(textGoBtn)}
          </span>
        </button>
      </div>
    {/if}
    {#if callBtn}
      <div class="col s12 row message-home ">
        <a
          use:styling={{ background: managementColors.color }}
          class="btn btn-success button--continuebase {classBtnCall}"
          href={`tel:${ownerPhone}`}
        >
          <span class="text--button {$locale}">{$_(textCallBtn)}</span>
        </a>
      </div>
    {/if}
  </div>
</div>

<style>
  .message-view__cont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0px;
  }

  .icon-close {
    width: 24px;
    height: 24px;
    float: right;
    font: normal normal normal 24px/29px Material Icons;
    letter-spacing: 0px;
    color: #707070;
    cursor: pointer;
    opacity: 1;
  }

  .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
  }

  .message-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    text-align: center;
  }

  .message-cont__text {
    font-size: 2.5rem;
    letter-spacing: 0px;
    color: #212121;
    font-weight: 500;
    line-height: 50px;
    opacity: 1;
  }

  .text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 0rem 0rem 0.5rem !important;
  }

  .text-cont__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .img-no-found {
    width: 170px;
    height: 135px;
    opacity: 1;
  }

  .img-error {
    width: 83px;
    height: 83px;
    opacity: 1;
  }

  .message-view {
    border-radius: 20px 20px 0px 0px;
    height: 80%;
    min-height: 377px;
    opacity: 1;
    position: relative;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
  }

  .header {
    padding-bottom: 17px;
  }

  .message-view__body {
    padding-top: 100px !important;
  }

  .message-view__actions {
    bottom: 0;
    margin: 0px;
    position: absolute;
    height: 11%;
    align-items: center;
  }

  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: #2f4050 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .button-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: #2f4050 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .button-green {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: #1ab394 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .message-home {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px !important;
  }

  .button--home {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: transparent 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
    opacity: 1;
  }

  .text--button-home {
    font-size: 1rem;
    color: #212121;
    font-weight: 700;
    text-transform: initial;
  }

  .text--button-home--dark {
    font-size: 1rem;
    color: white;
    font-weight: 700;
    text-transform: initial;
  }

  .button--block {
    background: #c3c3c3 0% 0% no-repeat padding-box !important;
  }

  .text--button {
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: initial;
  }

  .loader {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-view__actions--desktop {
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    display: none;
  }

  .message-view__actions-second--desktop {
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    margin: 0rem !important;
    display: none;
  }

  .message__button {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    padding-top: 24px !important;
  }

  .class-second-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    box-shadow: none;
    border: none;
    padding-bottom: 1rem;
    opacity: 1;
  }

  .class-second-btn__text {
    font-size: 1rem;
    letter-spacing: 0px;
    color: #686c6d;
    opacity: 1;
  }

  .message-cont__number {
    font-size: 3.75rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .svg-white {
    filter: invert(100%) sepia(43%) saturate(2%) hue-rotate(179deg)
      brightness(113%) contrast(101%);
  }

  .svg-dark {
    width: 30%;
    
  }

  @media only screen and (min-width: 601px) {
    .message-view {
      height: 90%;
      padding: 32px 32px 32px 32px;
    }

    .message-view__actions {
      padding: 0px 32px 0px 32px;
      position: relative;
    }

    .text-cont__text {
      font-size: 1rem;
    }

    .text--button {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .message-view {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }

    .message-view__actions {
      display: none;
    }

    .button--continuebase--desktop {
      width: auto;
      background: #2f4050 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }

    .message-view__actions--desktop {
      display: flex;
      padding-top: 32px !important;
    }

    .message-view__actions-second--desktop {
      display: flex;
      padding-top: 32px !important;
    }

    .button--home {
      width: auto;
    }

    .message-home {
      padding: 0px 0px 0px 0px !important;
      width: auto;
    }

    .message__button {
      width: auto;
      margin: 0px !important;
      justify-content: flex-start;
      padding: 0px !important;
    }
  }
</style>
