<script>
  import { _, locale } from "svelte-i18n";

  export let managementColors;
  export let status;
  export let stateStates;
  export let newtext;
  export let acceptText;
  export let finishedText;

  let screenPxs = window.innerWidth;
  let borderPx = "4px";

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let resizeView = () => {
    window.addEventListener("resize", () => {
      screenPxs = window.innerWidth;
      validationZiseScreen();
    });
  };

  let validationZiseScreen = () => {
    if (screenPxs > 992) {
      borderPx = "8px";
    } else if (screenPxs > 601) {
      borderPx = "6px";
    } else if (screenPxs < 600) {
      borderPx = "4px";
    }
  };

  validationZiseScreen();
  resizeView();
</script>

{#if stateStates == "initial"}
  <div class="status__bar">
    <div
     
      class="status__bar__section__left {status !== 'Validate'
        ? 'status__validate'
        : ''}"
    >
      {#if status == "Validate"}
        <div class="preloader-wrapper big active">
          <div
            class="spinner-layer {managementColors.general.colorMode == 'white'
              ? 'spinner-layer--white'
              : managementColors.general.colorMode == 'dark'
                ? 'spinner-layer--dark'
                : ''}"
          >
            <div class="circle-clipper left">
              <div class="circle" />
            </div>
            <div class="gap-patch">
              <div class="circle" />
            </div>
            <div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      {/if}

      <div
        class="{['Accepted', 'Finished', 'Processed', 'Delivered'].includes(
          status
        )
          ? 'circle__icon--next-status'
          : 'circle__icon'} "
        use:styling={{
          border: `${borderPx} solid${managementColors.general.backgroundIconStatusBar}`,
        }}
      >
        <i
          class="material-icons {[
            'Accepted',
            'Finished',
            'Processed',
            'Delivered',
          ].includes(status)
            ? 'icon--validate--next-status'
            : 'icon--validate'} "
          use:styling={{
            color: managementColors.general.backgroundIconStatusBarValidate,
          }}
        >
          error
        </i>
      </div>
      <span
        class=" {['Accepted', 'Finished', 'Processed', 'Delivered'].includes(
          status
        )
          ? 'validate__status__text--next-status'
          : 'validate__status__text'} {$locale}"
        use:styling={{
          color: managementColors.general.backgroundIconStatusBarValidate,
        }}>{$_(newtext)}</span
      >
    </div>

    <div class="status__bar__section__half">
      <div
        class="{['Accepted', 'Finished', 'Processed', 'Delivered'].includes(
          status
        )
          ? 'line__connect__left--next-status'
          : 'line__connect__left'} "
        use:styling={{
          background: managementColors.general.backgroundIconStatusBar,
        }}
      />
      <div
        class="{status == 'Finished' || status == 'Delivered'
          ? 'circle__icon--next-status'
          : 'circle__icon'} "
        use:styling={{
          border: `${borderPx} solid${managementColors.general.backgroundIconStatusBar}`,
        }}
      >
        <i
          class="material-icons {status == 'Accepted' || status == 'Processed'
            ? 'icon--accept--next-status'
            : 'icon--accept'} "
          class:icon--accept--last-status={status == "Finished" ||
            status == "Delivered"}
          use:styling={{
            color: managementColors.general.backgroundIconStatusBar,
          }}
        >
          watch_later
        </i>
      </div>
      <div
        class="{status == 'Finished' || status == 'Delivered'
          ? 'line__connect__right--next-status'
          : 'line__connect__right'} "
        use:styling={{
          background: managementColors.general.backgroundIconStatusBar,
        }}
      />
      <span
        class="{status == 'Accepted' || status == 'Processed'
          ? 'accept__status__text--next-status'
          : 'accept__status__text'} {$locale}"
        class:accept__status__text--last-status={status == "Finished" ||
          status == "Delivered"}
        use:styling={{
          color: managementColors.general.backgroundIconStatusBar,
        }}>{$_(acceptText)}</span
      >
    </div>

    <div class="status__bar__section__right">
      <div
        class="{status == 'Finished' || status == 'Delivered'
          ? 'circle__icon--last-status'
          : 'circle__icon'} "
        use:styling={{
          border: `${borderPx} solid${managementColors.general.backgroundIconStatusBar}`,
        }}
      >
        <i
          class="material-icons {status == 'Finished' || status == 'Delivered'
            ? 'icon--finish--last-status'
            : 'icon--finish'} "
          use:styling={{
            color: managementColors.general.backgroundIconStatusBar,
          }}
        >
          check_circle
        </i>
      </div>
      <span
        class="{status == 'Finished' || status == 'Delivered'
          ? 'finish__status__text--last-status'
          : 'finish__status__text'} {$locale}"
        use:styling={{
          color: managementColors.general.backgroundIconStatusBar,
        }}
      >
        {$_(finishedText)}
      </span>
    </div>
  </div>
{:else if stateStates == "Finished"}
  <div class="col s12 div__status__finish">
    <div
      class="circle__status__finish {managementColors.general.colorMode ==
      'white'
        ? 'circle__status__finish--white'
        : managementColors.general.colorMode == 'dark'
          ? 'circle__status__finish--dark'
          : ''}"
    >
      <i class="material-icons icon--status--finish"> check_circle </i>
    </div>
  </div>
{:else if stateStates == "Cancelled"}
  <div class="col s12 div__status__cancel">
    <div
      class="circle__status__cancel {managementColors.general.colorMode ==
      'white'
        ? 'circle__status__cancel--white'
        : managementColors.general.colorMode == 'dark'
          ? 'circle__status__cancel--dark'
          : ''}"
    >
      <i class="material-icons icon--status--cancel"> cancel</i>
    </div>
  </div>
{/if}

<style>
  .status__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .preloader-wrapper {
    display: block !important;
    position: absolute;
    width: 48px !important;
    height: 48px !important;
  }

  .spinner-layer--white {
    border-color: #686c6d !important;
  }

  .spinner-layer--dark {
    border-color: #f4f4f4 !important;
  }

  .status__bar__section__left {
    text-align: center;
    position: relative;
    top: 13px;
    left: 6px;
  }

  .status__bar__section__half {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .status__bar__section__right {
    text-align: center;
    position: relative;
    top: 15px;
  }

  .icon--accept {
    right: 20px;
    font-size: 32px;
  }

  .icon--finish {
    right: 20px;
    font-size: 32px;
  }

  .icon--finish--last-status {
    right: 20px;
    font-size: 32px;
    color: #1ab394 !important;
  }

  .icon--validate {
    right: 20px;
    font-size: 32px;
  }

  .icon--validate--next-status {
    right: 20px;
    font-size: 26px;
    color: #686c6d;
  }

  .icon--accept--next-status {
    right: 20px;
    font-size: 32px;
    color: #ffa64d !important;
  }

  .icon--accept--last-status {
    right: 20px;
    font-size: 26px;
    color: #ffa64d !important;
  }

  .line__connect__left {
    flex-grow: 100;
    height: 3px;
    width: 20px;
    float: left;
  }

  .line__connect__left--next-status {
    flex-grow: 100;
    height: 3px;
    width: 20px;
    background: #1ab394 !important;
    float: left;
  }

  .line__connect__right--next-status {
    flex-grow: 100;
    height: 3px;
    width: 20px;
    background: #1ab394 !important;
    float: right;
  }

  .line__connect__right {
    flex-grow: 100;
    height: 3px;
    width: 20px;
    float: right;
  }

  .circle__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    opacity: 1;
  }

  .circle__icon--next-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border: 4px solid #1ab394 !important;
    border-radius: 50%;
    opacity: 1;
  }

  .circle__icon--last-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 4px solid #1ab394 !important;
    border-radius: 50%;
    opacity: 1;
  }

  .validate__status__text {
    position: absolute;
    top: 55px;
    left: -33px;
    min-width: 120px;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .validate__status__text--next-status {
    min-width: 120px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #1ab394 !important;
    letter-spacing: 0px;
    opacity: 1;
    position: relative;
    top: 18px;
  }

  .accept__status__text {
    position: absolute;
    top: 55px;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .accept__status__text--next-status {
    position: absolute;
    top: 55px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #ffa64d !important;
    letter-spacing: 0px;
    opacity: 1;
  }

  .accept__status__text--last-status {
    position: absolute;
    top: 48px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #1ab394 !important;
    letter-spacing: 0px;
    opacity: 1;
  }

  .finish__status__text {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
    position: relative;
    top: 8px;
  }

  .finish__status__text--last-status {
    font-size: 0.875rem;
    font-weight: 700;
    color: #1ab394 !important;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div__status__finish {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div__status__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle__status__finish {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    opacity: 1;
  }

  .circle__status__finish--white {
    background: #ccf5f4 0% 0% no-repeat padding-box;
  }

  .circle__status__finish--dark {
    background: rgba(26, 179, 148, 0.3);
  }

  .circle__status__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    opacity: 1;
  }

  .circle__status__cancel--white {
    background: #ffdadd 0% 0% no-repeat padding-box;
  }

  .circle__status__cancel--dark {
    background: rgba(255, 72, 85, 0.3);
  }

  .icon--status--finish {
    font-size: 56px;
    letter-spacing: 0px;
    color: #1ab394;
    text-transform: lowercase;
    opacity: 1;
  }

  .icon--status--cancel {
    font-size: 56px;
    letter-spacing: 0px;
    color: #ff4855;
    text-transform: lowercase;
    opacity: 1;
  }

  @media only screen and (min-width: 20px) {

    

    .validate__status__text--next-status {
      position: relative;
      top: 10px;
      /* left: -10px; */
      left: -1rem;
    }

    .status__bar__section__left {
      position: relative;
      top: 0px;
      left: 1px;
    }

    .finish__status__text {
      position: relative;
      top: 5px;
    }

    .status__bar__section__right {
      position: relative;
      top: 11px;
    }

    .status__validate {
      left: 32px;
        width: 8rem;
        top: 0.8rem;
    }

    
  }

  @media only screen and (min-width: 601px) {
    .icon--validate {
      font-size: 60px;
    }

    .icon--accept {
      font-size: 60px;
    }

    .icon--finish {
      font-size: 60px;
    }

    .circle__icon {
      width: 78px;
      height: 78px;
      border: 6px solid #c3c3c3;
    }

    .preloader-wrapper {
      width: 78px !important;
      height: 78px !important;
    }

    .circle {
      border-width: 6px !important;
    }

    .validate__status__text--next-status {
      left: 0px;
    }

    .validate__status__text {
      font-size: 1rem;
      top: 90px;
      left: -18px;
    }

    .status__validate {
      left: 20px;
      top: 25px ;
    }

    .accept__status__text {
      font-size: 1rem;
      top: 90px;
    }

    .finish__status__text {
      font-size: 1rem;
      top: 10px;
    }
    .status__bar__section__left {
      top: 25px ;
      left: 0px;
    }

    .line__connect__left {
      height: 4px;
      width: 20px;
    }

    .line__connect__right {
      height: 4px;
      width: 20px;
    }

    .icon--validate--next-status {
      font-size: 48px;
    }

    .circle__icon--next-status {
      width: 72px;
      height: 72px;
      border: 6px solid #1ab394 !important;
    }

    .validate__status__text--next-status {
      font-size: 1rem;
      position: relative;
      top: 13px;
    }

    .line__connect__left--next-status {
      flex-grow: 100;
      height: 4px;
      width: 20px;
    }

    .icon--accept--next-status {
      font-size: 60px;
    }

    .accept__status__text--next-status {
      font-size: 1rem;
      top: 90px;
    }

    .icon--accept--last-status {
      font-size: 48px;
    }

    .accept__status__text--last-status {
      font-size: 1rem;
      top: 75px;
    }

    .icon--finish--last-status {
      font-size: 60px;
    }

    .circle__icon--last-status {
      width: 78px;
      height: 78px;
      border: 6px solid #1ab394 !important;
    }

    .finish__status__text--last-status {
      font-size: 1px;
    }

    .line__connect__right--next-status {
      flex-grow: 100;
      height: 4px;
      width: 20px;
    }

    .circle__status__finish {
      width: 102px;
      height: 102px;
    }

    .circle__status__cancel {
      width: 102px;
      height: 102px;
    }

    .icon--status--finish {
      font-size: 80px;
    }

    .icon--status--cancel {
      font-size: 80px;
    }

    .status__bar {
      margin: 0 auto;
      width: 64% !important;
    }
  }
  @media only screen and (min-width: 601px) {
    .icon--validate {
      font-size: 60px;
    }

    .icon--accept {
      font-size: 60px;
    }

    .icon--finish {
      font-size: 60px;
    }

    .circle__icon {
      width: 78px;
      height: 78px;
      border: 6px solid #c3c3c3;
    }

    .preloader-wrapper {
      width: 78px !important;
      height: 78px !important;
    }

    .circle {
      border-width: 6px !important;
    }

    .validate__status__text--next-status {
      left: 0px;
    }

    .validate__status__text {
      font-size: 1rem;
      top: 90px;
      left: -18px;
    }

    .status__validate {
      left: 20px;
      top: 25px ;
    }

    .accept__status__text {
      font-size: 1rem;
      top: 90px;
    }

    .finish__status__text {
      font-size: 1rem;
      top: 10px;
    }
    .status__bar__section__left {
      top: 10px ;
      left: 0px;
    }

    .line__connect__left {
      height: 4px;
      width: 20px;
    }

    .line__connect__right {
      height: 4px;
      width: 20px;
    }

    .icon--validate--next-status {
      font-size: 48px;
    }

    .circle__icon--next-status {
      width: 72px;
      height: 72px;
      border: 6px solid #1ab394 !important;
    }

    .validate__status__text--next-status {
      font-size: 1rem;
      position: relative;
      top: 13px;
    }

    .line__connect__left--next-status {
      flex-grow: 100;
      height: 4px;
      width: 20px;
    }

    .icon--accept--next-status {
      font-size: 60px;
    }

    .accept__status__text--next-status {
      font-size: 1rem;
      top: 90px;
    }

    .icon--accept--last-status {
      font-size: 48px;
    }

    .accept__status__text--last-status {
      font-size: 1rem;
      top: 75px;
    }

    .icon--finish--last-status {
      font-size: 60px;
    }

    .circle__icon--last-status {
      width: 78px;
      height: 78px;
      border: 6px solid #1ab394 !important;
    }

    .finish__status__text--last-status {
      font-size: 1px;
    }

    .line__connect__right--next-status {
      flex-grow: 100;
      height: 4px;
      width: 20px;
    }

    .circle__status__finish {
      width: 102px;
      height: 102px;
    }

    .circle__status__cancel {
      width: 102px;
      height: 102px;
    }

    .icon--status--finish {
      font-size: 80px;
    }

    .icon--status--cancel {
      font-size: 80px;
    }

    .status__bar {
      margin: 0 auto;
      width: 64% !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .icon--validate {
      font-size: 64px;
    }

    .icon--accept {
      font-size: 64px;
    }

    .icon--finish {
      font-size: 64px;
    }

    .circle__icon {
      width: 86px;
      height: 86px;
      border: 8px solid #c3c3c3;
    }

    .preloader-wrapper {
      width: 86px !important;
      height: 86px !important;
    }

    .circle {
      border-width: 8px !important;
    }

    .validate__status__text {
      font-size: 1.25rem;
      top: 95px;
      left: -15px;
    }

    .accept__status__text {
      font-size: 1.25rem;
      top: 95px;
    }

    .finish__status__text {
      font-size: 1.25rem;
    }

    .line__connect__left {
      height: 5px;
      width: 20px;
    }

    .line__connect__right {
      height: 5px;
      width: 20px;
    }

    .icon--validate--next-status {
      font-size: 48px;
    }

    .circle__icon--next-status {
      width: 72px;
      height: 72px;
      border: 8px solid #1ab394 !important;
      margin-top: 2rem;
      margin-left: 3.3rem;
    }

    .validate__status__text--next-status {
      font-size: 1.25rem;
      position: relative;
      top: 15px;
      left: 15px;
    }

    .status__bar__section__left {
      left: 0px !important;
      top: 0px;
    }

    .status__validate {
        left: 15px;
        display: grid;
        justify-items: center;
        text-align: -webkit-right;
    }
    

    .line__connect__left--next-status {
      height: 5px;
      width: 20px;
    }

    .icon--accept--next-status {
      font-size: 64px;
    }

    .accept__status__text--next-status {
      font-size: 1.25rem;
      top: 95px;
    }

    .icon--accept--last-status {
      font-size: 48px;
    }

    .accept__status__text--last-status {
      font-size: 1.25rem;
      top: 80px;
    }

    .icon--finish--last-status {
      font-size: 64px;
    }

    .circle__icon--last-status {
      width: 86px;
      height: 86px;
      border: 8px solid #1ab394 !important;
    }

    .finish__status__text--last-status {
      font-size: 1.25rem;
    }

    .line__connect__right--next-status {
      height: 5px;
      width: 20px;
    }

    .circle__status__finish {
      width: 142px;
      height: 142px;
    }

    .circle__status__cancel {
      width: 142px;
      height: 142px;
    }

    .icon--status--finish {
      font-size: 104px;
    }

    .icon--status--cancel {
      font-size: 104px;
    }

    .div__status__finish {
      padding-top: 20px !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .line__connect__left {
      width: 20px;
    }

    .line__connect__right {
      width: 20px;
    }

    .line__connect__left--next-status {
      width: 20px;
    }

    .line__connect__right--next-status {
      width: 20px;
    }

    .status__bar {
      width: 45% !important;
      margin: 0 auto;
    }
  }

  @media only screen and (min-width: 1600px) {
    .line__connect__left {
      width: 20px;
    }

    .line__connect__right {
      width: 20px;
    }

    .line__connect__left--next-status {
      width: 20px;
    }

    .line__connect__right--next-status {
      width: 20px;
    }

    .status__bar {
      width: 35% !important;
      margin: 0 auto;
    }
  }
</style>
