import { push, pop } from "svelte-spa-router";

var listViews = [
  {
    name: "viewoutlet",
    route: `/properties/$propertyId/$outleType/$outletId`,
  },
  {
    name: "viewroomservice",
    route: `/properties/$propertyId/room-services`,
  },
  {
    name: "viewrestaurantslist",
    route: `/properties/$propertyId/restaurants`,
  },
  {
    name: "viewpromotionslist",
    route: `/properties/$propertyId/promotions`
  },
  {
    name: "viewlanding",
    route: `/properties/$propertyId`,
  },
  {
    name: "viewmyrequests",
    route: `/properties/$propertyId/my-experiences`,
  },
  {
    name: "viewlogin",
    route: `/properties/$propertyId/user-validation`,
  },
  {
    name: "viewrequestcomplete",
    route: `/properties/$propertyId/my-experience/$requestId`,
  },
];


var setLastRoute = (lastRoute) => {
  localStorage.setItem("lastRoute", JSON.stringify(lastRoute));
};

var goLastRoute = (propertyId) => {
  var lastRoute = JSON.parse(localStorage.getItem("lastRoute"));

  if (lastRoute && lastRoute == "close-terms") {
    console.warn("Debería volver a el detalle de la promocion.");
  } else if (lastRoute && lastRoute == "pop") {
    pop();
  } else {
    var url = listViews.find((view) => view.name == lastRoute)
      ? listViews.find((view) => view.name == lastRoute)
      : listViews.find((view) => view.name == 'viewlanding');
    var finalUrl = url.route.replace("$propertyId", propertyId);
    if (lastRoute && lastRoute == "viewoutlet") {
      finalUrl = finalUrl.replace("$outleType", localStorage.getItem('outleType'))
        .replace("$outletId", localStorage.getItem('outletId'));
    } else if (lastRoute && lastRoute == "viewroomservice" && (
      localStorage.getItem('amountMenusRoomService')
        ? JSON.parse(localStorage.getItem('amountMenusRoomService')) == 1
        : false
    )) {
      url = listViews.find((view) => view.name == 'viewlanding');
      finalUrl = url.route.replace("$propertyId", propertyId);
      localStorage.removeItem('amountMenusRoomService');
    } else if (lastRoute && (lastRoute == "viewrestaurantslist" || lastRoute == "viewserviceslist" || lastRoute == "viewpromotionslist") && (
      localStorage.getItem('amountRestaurants')
        ? JSON.parse(localStorage.getItem('amountRestaurants')) == 1
        : false
    )) {
      url = listViews.find((view) => view.name == 'viewlanding');
      finalUrl = url.route.replace("$propertyId", propertyId);
      localStorage.removeItem('amountMenus');
      localStorage.removeItem('amountRestaurants');
      localStorage.removeItem('amountServices');
    }
    push(finalUrl);
  }
};

var goRoute = (propertyId, requestId) => {
  var auth = JSON.parse(localStorage.getItem("auth"));
  var reservationId = '';
  auth ?
    reservationId = auth.reservationId :
    reservationId = null;

  var lastRoute = JSON.parse(localStorage.getItem("lastRoute"));
  var url = listViews.find((view) => view.name == lastRoute);
  if (requestId) {
    var finalUrl
    finalUrl = url.route.replace("$propertyId", propertyId).replace("$reservationId", reservationId).replace("$requestId", requestId);

  } else {
    var finalUrl = url.route.replace("$propertyId", propertyId);
  }

  push(finalUrl);
};

var setEntryPoint = (value) => {
  localStorage.setItem("entryPoint", JSON.stringify(value));
};

export default {
  setLastRoute,
  goLastRoute,
  goRoute,
  setEntryPoint,
};
