<script>
  import { location, querystring } from "svelte-spa-router";
  import { push } from "svelte-spa-router";
  import MessageView from "../../components/view-messages/component.svelte";
  import PropertyFeatures from "../../property-features-service";
  import ValidationUser from "../../authentication-service";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let title;
  let message;
  let reloading;
  let imgView;
  let btnActionMobile;
  let textCallBtn;
  let textGoBtn;
  let callBtn;
  let goBtn;
  let classImg;
  let classBtnGo;
  let classBtnGoText;
  let secondBtn;
  let textSecondBtn;
  let btnClose;
  let managementColors;
  let colorImgSvg;

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");

    managementColors.general.backgroundColor != "#FFFFFF"
      ? (colorImgSvg = "svg-white")
      : (colorImgSvg = "svg-dark");
  };

  validationColor();

  const init = () => {
    Promise.all([
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(([propertyInformation]) => {
      propertyData = propertyInformation;
    });

    validationViewMessage(true);
  };

  let validationViewMessage = (typeView) => {
    switch (typeView) {
      case true:
        btnClose = true;
        title = "We are sorry";
        message =
          "Your comment could not be sent as your request has finished. If you need anything, please create a new request.";
        imgView = "error_message.svg";
        classBtnGo = "button-dark";
        btnActionMobile = true;
        reloading = false;
        goBtn = true;
        textGoBtn = "Go back";
        secondBtn = true;
        textSecondBtn = "Create new request";
        classImg = "img-error";
        classBtnGoText = "text--button-home--dark";
        reloading = false;
        break;
      default:
        break;
    }
  };

  let handleBack = (e) => {
    push(`/properties/${state.propertyId}/my-experiences`);
  };

  let handleGoCreateRequest = (e) => {
    push(`/properties/${state.propertyId}/create-requests`);
  };

  init();
</script>

<div class="view-magic-link">
  <MessageView
    bind:btnClose
    bind:title
    bind:message
    bind:reloading
    bind:callBtn
    bind:goBtn
    bind:secondBtn
    bind:imgView
    bind:btnActionMobile
    bind:textCallBtn
    bind:textGoBtn
    bind:textSecondBtn
    bind:ownerPhone={propertyData.ownerPhone}
    bind:classImg
    bind:classBtnGo
    bind:classBtnGoText
    bind:managementColors
    bind:colorImgSvg
    bind:handleAction={handleBack}
    bind:handleActionSecond={handleGoCreateRequest}
  />
</div>

<style>
  .view-magic-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
