<script>
  import { _, locale } from "svelte-i18n";
  import { pop, location } from "svelte-spa-router";
  import PropertyFeatures from "../property-features-service";
  import { push } from "svelte-spa-router";

  export let params = {};

  let managementColors;
  let colorImgSvg;

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");

    if (managementColors.general.backgroundColor != "#FFFFFF") {
      colorImgSvg = "svg-white";
    } else {
      colorImgSvg = "svg-dark";
    }
  };

  validationColor();

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};

  const init = () => {
    Promise.all([
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(([propertyInformation]) => {
      propertyData = propertyInformation;
      setFontFamily(null);

    });

    callLanguageActive();
  };

  const callLanguageActive = () => {
    if (JSON.parse(localStorage.getItem("language"))) {
      locale.set(JSON.parse(localStorage.getItem("language")));
    } else {
      locale.set(window.navigator.language.split("-")[0]);
    }
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  }

  let handleClose = () => {
    push(`/properties/${state.propertyId}`);
  };

  let handleGoHome = () => {
    push(`/properties/${state.propertyId}`);
  };

  init();
</script>

<div
  class="col s12 message-request__cont row"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  <div
    class="message-request row col s12"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <div class="cols 12 header">
      <i
        class="material-icons icon-close"
        on:click={handleClose}
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        close
      </i>
    </div>
    <div class="cols 12 message-request__body col l8 offset-l2 m12 s12">
      <div class="cols 12 img-cont">
        <img class={colorImgSvg} src="/img/novalidation.svg" alt="" />
      </div>
      <div class="cols 12 message-cont">
        <span
          class="message-cont__text {$locale}"
          use:styling={{ color: managementColors.color }}
        >
          {$_("We are sorry")}
        </span>
      </div>

      <div class="cols 12 text-cont">
        <span
          class="text-cont__text {$locale}"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {$_("This feature is only available for guests")}
        </span>
      </div>

      <div class="col s12  message-home">
        <button class=" btn-success button--home" on:click={handleGoHome}>
          <span
            class="text--button-home {$locale}"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {$_("Return to home menu")}
          </span>
        </button>
      </div>
      <div class="col s12 row message-request__actions--desktop">
        <div class="col  row message__button ">
          <a
            class="btn btn-success button--continuebase--desktop"
            use:styling={{ background: managementColors.color }}
            href={`tel:${propertyData.ownerPhone}`}
          >
            <span
              use:styling={{ color: managementColors.general.colorLetterBaseButton }}
              class="text--button {$locale}"
            >
              {$_("Call the operator")}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col s12 row message-request__actions">
    <div class="col s12 row message-home ">
      <a
        class="btn btn-success button--continuebase"
        use:styling={{ background: managementColors.color }}
        href={`tel:${propertyData.ownerPhone}`}
      >
        <span
          use:styling={{ color: managementColors.general.colorLetterBaseButton }}
          class="text--button {$locale}"
        >
          {$_("Call the operator")}
        </span>
      </a>
    </div>
  </div>
</div>

<style>
  .message-request__cont {
    height: 100%;
    margin: 0px;
  }

  .message-request__header {
    background: transparent;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-close {
    width: 24px;
    height: 24px;
    float: right;
    font: normal normal normal 24px/29px Material Icons;
    letter-spacing: 0px;
    color: #707070;
    cursor: pointer;
    opacity: 1;
  }

  .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
  }

  .message-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    text-align: center;
  }

  .message-cont__text {
    font-size: 2.5rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .text-cont__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .message-request {
    border-radius: 20px 20px 0px 0px;
    height: 80%;
    min-height: 377px;
    opacity: 1;
    position: relative;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
  }

  .header {
    padding-bottom: 17px;
  }

  .message-request__body {
    padding-top: 100px !important;
  }

  .message-request__actions {
    bottom: 0;
    margin: 0px;
    position: fixed;
    height: 11%;
    align-items: center;
  }

  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    opacity: 1;
  }

  .message-home {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px !important;
  }

  .button--home {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 100%;
    background: transparent 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
    opacity: 1;
  }

  .text--button-home {
    font-size: 1rem;
    color: #212121;
    font-weight: 700;
    text-transform: initial;
  }

  .button--block {
    background: #c3c3c3 0% 0% no-repeat padding-box !important;
  }

  .text--button {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: initial;
  }

  .loader {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-request__actions--desktop {
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    display: none;
  }

  .message__button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px !important;
  }

  .svg-white {
    filter: invert(100%) sepia(43%) saturate(2%) hue-rotate(179deg)
      brightness(113%) contrast(101%);
    width: 170px;
    height: 135px;
    opacity: 1;
  }

  .svg-dark {
    width: 170px;
    height: 135px;
    opacity: 1;
  }

  @media only screen and (min-width: 601px) {
    .message-request {
      height: auto;
      padding: 32px 32px 32px 32px;
    }

    .message-request__actions {
      padding: 0px 32px 0px 32px;
    }

    .text-cont__text {
      font-size: 1rem;
    }

    .text--button {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .message-request {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }

    .message-request__actions {
      display: none;
    }

    .button--continuebase--desktop {
      width: auto;
      border-radius: 4px;
      opacity: 1;
    }

    .message-request__actions--desktop {
      display: flex;
      padding-top: 59px !important;
    }

    .button--home {
      width: auto;
    }

    .message-home {
      padding: 44px 0px 0px 0px !important;
      width: auto;
    }

    .message__button {
      width: auto;
      margin: 0px !important;
      justify-content: flex-start;
      padding: 0px !important;
    }
  }
</style>
