<script>
  import { Loader } from "@googlemaps/js-api-loader";

  export let propertyLongitud;
  export let propertyLattud;

  const apiKey = "AIzaSyDPPSEh38cY2ReagB0kou-1Lc6xdJeRSko";
  //AIzaSyAUuWQgSq4MzHcwm0_N-5Oi3ZLX6LGbp5I

  const init = () => {
    let map;
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
    });

    loader.load().then(() => {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: Number(propertyLattud), lng: Number(propertyLongitud) },
        zoom: 12,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });

      const marker = new google.maps.Marker({
        position: {
          lat: Number(propertyLattud),
          lng: Number(propertyLongitud),
        },
        map: map,
      });
    });
  };

  init();
</script>

<div class="col s12 nopadding">
  <!-- <img
    src="https://maps.googleapis.com/maps/api/staticmap?center={Number(
      propertyLattud,
    )},{Number(propertyLongitud)}&zoom=12&size=510x200&key={apiKey}"
    alt="Mapa de Google"
  /> -->

  <div id="map" />
</div>

<style>
  #map {
    height: 100% !important;
    min-height: 200px !important;
    width: 100% !important;
  }

  .nopadding {
    padding: 0px !important;
  }
</style>
