import environments from "./environments";

const getByReviewId = (id, propertyId) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/survey/${id}`
  ).then((res) => res.json());

const getSocialMedia = (propertyId) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/social-media`
  ).then((res) => res.json());

const updateByReviewId = (data, id, propertyId) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/survey/${id}`,
    {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Guesthub-Context": JSON.stringify(
          Object.assign({
            properties: [propertyId],
            user: 0,
          })
        ),
      },
      body: JSON.stringify(data),
    }
  );

const clikSocial = (url, id, propertyId) =>
  fetch(
    `${
      environments.reservations.api
    }/properties/${propertyId}/survey/${id}/click?url=${encodeURIComponent(
      url
    )}`,
    {
      method: "PUT",
      body: JSON.stringify({}),
    }
  );

export default {
  getByReviewId,
  getSocialMedia,
  updateByReviewId,
  clikSocial,
};
