<script>
  import { pop } from "svelte-spa-router";
  import MessageView from "../components/view-messages/component.svelte";
  import { _, } from "svelte-i18n";
  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);

  let handleAction = (e) => {
    pop()
  };

  
</script>

<div class="view-magic-link">
  <div class="col s12 message-view__cont row">
    <div class="message-view row col s12">
      <div style="width: 100%;" class="col s12 col l9 m12 s12">
        <div class="col s12 img-cont">
          <img style="width: 50%;" class="svg-black" src={`/img/Guesthub-404.png`} alt="" />
        </div>
      </div>

      <h1 class="message-home" style="text-align: center; margin-top:20px">
        { $_("Oops!, Page not Found")}
      </h1>
      <div style="text-align: center;">

        <button  on:click={handleAction} class="button-green"  type="submit"> { $_("Go to back")}</button>
      </div>
    </div>

  </div>
</div>

<style>


.button-green {
    top: 644px;
left: 579px;
width: 122px;
height: 38px;
/* UI Properties */
background: #1BB394 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 8px #1BB39452;
border-radius: 4px;
opacity: 1;
color: white;
  }

  .message-home {
    top: 579px;
left: 450px;
width: 364px;
/* UI Properties */
text-align: center;
font: normal normal 800 32px/43px Open Sans;
letter-spacing: 0px;
color: #293846;
opacity: 1;
  }





  .message-view__cont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0px;
  }

  .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    margin-bottom: 2%;
  }

  .message-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17px;
    text-align: center;
  }

  .message-cont__text {
    font-size: 2.5rem;
    letter-spacing: 0px;
    color: #212121;
    font-weight: 500;
    line-height: 50px;
    opacity: 1;
  }

  .text-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 0rem 0rem 0.5rem !important;
  }

  .text-cont__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .img-no-found {
    width: 170px;
    height: 135px;
    opacity: 1;
  }

  .img-error {
    width: 83px;
    height: 83px;
    opacity: 1;
  }

  .message-view {
    border-radius: 20px 20px 0px 0px;
    height: 80%;
    min-height: 377px;
    opacity: 1;
    position: relative;
    padding: 12px 12px 0px 12px;
    margin-bottom: 0px;
  }





  
  .svg-white {
    filter: invert(100%) sepia(43%) saturate(2%) hue-rotate(179deg)
      brightness(113%) contrast(101%);
  }

  .svg-dark {
    width: 30%;
  }

  @media only screen and (min-width: 601px) {
    .message-view {
      height: 90%;
      padding: 32px 32px 32px 32px;
    }

    .message-view__actions {
      padding: 0px 32px 0px 32px;
      position: relative;
    }

    .text-cont__text {
      font-size: 1rem;
    }

    .text--button {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 992px) {
    .message-view {
      border-radius: 20px 20px 20px 20px;
      height: auto;
      min-height: 0px;
      padding: 32px 32px 30px 32px;
    }

    .message-view__actions {
      display: none;
    }

    .button--continuebase--desktop {
      width: auto;
      background: #2f4050 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }

    .message-view__actions--desktop {
      display: flex;
      padding-top: 32px !important;
    }

    .message-view__actions-second--desktop {
      display: flex;
      padding-top: 32px !important;
    }

    .button--home {
      width: auto;
    }

    .message-home {
      padding: 0px 0px 0px 0px !important;
      width: auto;
    }

    .message__button {
      width: auto;
      margin: 0px !important;
      justify-content: flex-start;
      padding: 0px !important;
    }
  }
  .view-magic-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
