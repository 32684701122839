<script>
  import { _, locale } from "svelte-i18n";
  import { querystring, push } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import Information_add from "../../components/add-information/component.svelte";
  import RoutesServices from "../../management-routes-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import CardMemu from "../../components/card-menu/component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";

  export let params = {};
  let state = {};

  let featureDetail = "";
  let propertyData = {};
  let languagesEnabled = [];
  let faeturesList = [];
  let itemList = [];
  let managementColors = {};
  let iframeFromLanding;
  let showBtnBack = true;
  let language;
  let reload = true;
  let dowloadFile = false;
  let fileDowloadText;

  const validationColors = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");
  };

  validationColors();

  const init = (lang) => {
    let state = Object.assign({ features: [], reservation: undefined }, params);
    reload = true;
    Promise.all([
      PropertyFeatures.list({ propertyId: state.propertyId }, lang),
      PropertyFeatures.get(
        { propertyId: state.propertyId, featureId: state.featureId },
        lang
      ),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({ propertyId: state.propertyId }),
    ]).then(
      ([
        features,
        featureInformation,
        guestappsettins,
        propertyInformation,
      ]) => {
        faeturesList = features.filter(
          (fet) => fet.enabled || fet.codename == "property.details"
        );

        featureInformation == "404"
          ? push(`/properties/${state.propertyId}/page-no-found`)
          : (featureDetail = featureInformation);

        propertyData = propertyInformation;
        featureDetail.href ? (reload = false) : (reload = true);
        itemList = featureDetail.href;
        RoutesServices.setLastRoute("pop");
        localStorage.setItem(
          "amountMenus",
          JSON.stringify(featureDetail.href.length)
        );
        validationLanguage(
          guestappsettins.languages,
          propertyInformation.language.code
        );
        setFontFamily(guestappsettins.fontFamily);
        validationCallIframe();
      }
    );
  };

  init(window.navigator.language.split("-")[0]);

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  }

  const validationLanguage = (enabledLanguges, languageDefault) => {
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  const validationCallIframe = () => {
    $querystring ? (iframeFromLanding = true) : (iframeFromLanding = false);
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleLanguage = (e) => {
    init(e);
  };

  let handleFileDownloading = (e) => {
    dowloadFile = true;
    fileDowloadText = "File downloading";
  };
</script>

<div
  id="headerid"
  use:styling={{ background: managementColors.general.backgroundColor }}
>
  {#if reload}
    <div class="container-fluid reloading row">
      <Reloading bind:managementColors />
    </div>
  {:else}
    <div
      class="cont__body row"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
          ? 'navifation-cont-border--dark'
          : ''}"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={propertyData.id}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:managementColors
          bind:iframeFromLanding
          bind:showBtnBack
        />
      </div>
      <div
        class="card-content col l10 offset-l1  xl8 offset-xl2  s12 nopadding"
        style="margin-top: 61px;"
      >
        {#if featureDetail.type == "file"}
          <div class="add-content col s12">
            <span class="text_contact ">{featureDetail.caption}</span>
            <div class="addinfo">
              {#each itemList as file, i}
                <Information_add
                  bind:name={file.name}
                  bind:href={file.link}
                  bind:nameLink={file.nameLink}
                  bind:propertyId={propertyData.id}
                  bind:featureId={featureDetail._id}
                  bind:managementColors
                  bind:handleFileDownloading
                />
              {/each}
            </div>
          </div>
        {:else if featureDetail.type == "link"}
          <div class="addinfo">
            {#each itemList as link, i}
              {#if link.type == "menu"}
                {#if link.enable}
                  <Information_add
                    bind:name={link.name}
                    bind:img={link.img}
                    bind:href={link.link}
                    bind:propertyId={propertyData.id}
                    bind:featureId={featureDetail._id}
                    bind:typeFile={featureDetail.type}
                    bind:canIframe={link.canIframe }
                    bind:managementColors
                    bind:handleFileDownloading
                  />
                {/if}
              {:else}
                <Information_add
                  bind:name={link.name}
                  bind:img={link.img}
                  bind:href={link.link}
                  bind:propertyId={propertyData.id}
                  bind:featureId={featureDetail._id}
                  bind:typeFile={featureDetail.type}
                  bind:canIframe={link.canIframe}
                  bind:managementColors
                  bind:handleFileDownloading
                />
              {/if}
            {/each}
          </div>
        {:else}
          <div class="col s12 l12 xl12 menus-list">
            {#each itemList as menu, i}
              <CardMemu
                bind:dataMenu={menu}
                bind:propertyId={propertyData.id}
                bind:featureId={featureDetail._id}
                bind:managementColors
              />
            {/each}
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  #headerid {
    height: 100%;
  }

  .container-fluid {
    height: 100%;
  }

  .cont__body {
    height: auto;
    padding: 0px 0px 100px 0px !important;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-content {
    padding-top: 41px !important;
  }

  .menus-list {
    column-count: 1;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    column-gap: 1em;
    padding-top: 32px !important;
  }

  .addinfo {
    padding-top: 16px !important;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  @media only screen and (min-width: 601px) {
    .addinfo {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0px 32px;
    }

    .menus-list {
      column-count: 2;
    }
  }

  @media only screen and (min-width: 992px) {
    .menus-list {
      column-count: 3;
    }
  }
</style>
