<script>
  import { _, locale } from "svelte-i18n";
  import { pop, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import DatesCustom from "../../components/dates-custom/component.svelte";

  export let dataRequest;
  export let closeMessages;
  export let managementColors;
  let active = false;
  let cliclInter = false;
  let blockButton = true;
  let comment = "";
  let saving = false;
  let defaultStyles = true;

  const init = () => {
    validationScrollPosition();
    setFontFamily(null);
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("viewmessages") &&
      document.getElementById("viewmessages").contains(e.target)
    ) {
      setTimeout(function () {
        if (!cliclInter && document.getElementById("cardmessages"))
          closeMessages();
      }, 50);
    } else {
    }
  });

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("cardmessages") &&
      document.getElementById("cardmessages").contains(e.target)
    ) {
      cliclInter = true;
    } else {
      cliclInter = false;
    }
  });

  let validationScrollPosition = () => {
    setTimeout(() => {
      if (document.getElementById("cardmessages")) {
        let side = document.getElementById("cardmessages");
        let heightSide = side.offsetHeight;
        side.scrollTop = heightSide;
      }
    }, 10);
  };

  let handleComment = () => {
    if (!blockButton && !saving) {
      saving = true;
      let guestName;
      JSON.parse(localStorage.getItem("auth")) &&
      JSON.parse(localStorage.getItem("auth")).guestName
        ? (guestName = JSON.parse(localStorage.getItem("auth")).guestName)
        : (guestName = dataRequest.guestName);

      let data = {
        body: comment,
        guestName,
      };

      PropertyFeatures.commentsRequest(
        dataRequest.propertyId,
        data,
        dataRequest.id
      )
        .then(
          function (response) {
            comment = "";
            document.querySelector("textarea").style.height = '36px';
            blockButton = true;
            saving = false;
          }.bind(this)
        )
        .catch((res) => {});
    }
  };

  const onKeyPress = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
    }
  }

  const onKeyUp = (event) =>  {
    if (comment.toString().trim().length > 0) {
      blockButton = false;
      document.querySelector("textarea").style.height = '0';
    document.querySelector("textarea").style.height = document.querySelector("textarea").scrollHeight + 'px';
      if (event.keyCode == 13 && !event.shiftKey) {
        document.querySelector("textarea").style.height = '0';
        document.querySelector("textarea").style.height = document.querySelector("textarea").scrollHeight + 'px';
        handleComment();
      }
    } else {
      document.querySelector("textarea").style.height = '0';
      document.querySelector("textarea").style.height = document.querySelector("textarea").scrollHeight + 'px';
      blockButton = true;
    }
  }



  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  init();
</script>

<div
  id="viewmessages"
  class:mydrawer__base={true}
  class="mydrawer__base-hide col s12 row"
  class:off={active}
>
  <div
    id="cardmessages"
    class="mydrawer"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    <div
      class="title-nav {managementColors.general.colorMode == 'white'
        ? 'title-nav--white'
        : managementColors.general.colorMode == 'dark'
        ? 'title-nav--dark'
        : ''}"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <div class="title-nav__cont__left">
        <span
          class="title-nav__text"
          use:styling={{ color: managementColors.general.colorLetter }}
          >{$_("Messages")}</span
        >
      </div>
      <div class="title-nav__cont__right">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class="button__circle"
          use:styling={{
            background: managementColors.general.backgroundButtonCircle,
          }}
          on:click={closeMessages}
        >
          <i
            class="material-icons button__circle__icon-close"
            use:styling={{ color: managementColors.general.colorLetter }}
            >close</i
          >
        </div>
      </div>
    </div>
    <div
      class="col s12 subtitle-nav {managementColors.general.colorMode == 'white'
        ? 'subtitle-nav-border--white'
        : managementColors.general.colorMode == 'dark'
        ? 'subtitle-nav-border--dark'
        : ''}"
      use:styling={{ background: managementColors.general.backgroundDivFotter }}
    >
      <span
        class="type"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {#if dataRequest.type === "request"}
          {$_("Request")}
        {:else if dataRequest.type === "restaurant"}
          {$_("Restaurant")}
        {:else if dataRequest.type === "service"}
          {$_("Service")}
        {:else if dataRequest.type === "check-in"}
          {$_("Check in")}
        {:else}
          {$_("Food & Beverage")}
        {/if}
      </span>
      <span
        class="name"
        use:styling={{ color: managementColors.general.colorLetter }}
      >
        {#if dataRequest.type === "check-in"}
          {dataRequest.firstName} {dataRequest.lastName}
        {:else}
          {dataRequest.requestText}
        {/if}
      </span>
    </div>

    <div class="col s12 messages">
      {#if dataRequest.comments.length == 0}
        <div class="not-messages">
          <i
            class="material-icons icon-not-message {dataRequest.requestText} "
            use:styling={{ color: managementColors.color }}>check_circle</i
          >
          <span
            class="not-messages__text"
            use:styling={{ color: managementColors.general.colorLetter }}
            >{$_("No messages entered")}</span
          >
        </div>
      {/if}
      {#each dataRequest.comments as comment, i}
        {#if comment.guestName || !comment.authorId}
          <div class="cont__mesagge__chat col s12">
            <div
              class="message-internal {managementColors.general.colorMode ==
              'white'
                ? 'message-internal-border--white'
                : managementColors.general.colorMode == 'dark'
                ? 'message-internal-border--dark'
                : ''}"
              use:styling={{
                background: managementColors.general.backgroundColor,
              }}
            >
              <span
                class="name"
                use:styling={{ color: managementColors.general.colorLetter }}
              >
                {comment.guestName || comment.userFirstName}
              </span>
              <span class="date">
                <DatesCustom
                  bind:date={comment.creationDate}
                  bind:colorLetterDates={managementColors.general
                    .colorLetterDates}
                  bind:defaultStyles
                />
              </span>
              <span
                class="body col s12 padding_0"
                use:styling={{ color: managementColors.general.colorLetter }}
              >
                {@html comment.body.replaceAll('\n','<br>')}
              </span>
            </div>
          </div>
        {:else if !comment.guestName}
          <div class="cont__mesagge__chat col s12">
            <div
              class="message-guest"
              use:styling={{
                background: managementColors.general.cardShipColor,
              }}
            >
              {#if comment.userPicture}
                <div class="con_avatar">
                  <img
                    class="message-guest-photo"
                    width="100%"
                    src={comment.userPicture}
                  />
                </div>
              {/if}

              <div class="col s12 padding0">
                <span
                  class="name"
                  use:styling={{ color: managementColors.general.colorLetter }}
                >
                  {#if comment.userFirstName}
                    {comment.userFirstName}
                  {/if}
                  {#if comment.userLastName}
                    {comment.userLastName}
                  {/if}
                </span>
                <span class="date">
                  <DatesCustom
                    bind:date={comment.creationDate}
                    bind:colorLetterDates={managementColors.general
                      .colorLetterDates}
                    bind:defaultStyles
                  />
                </span>
                <span
                  class="body col s12 padding_0"
                  use:styling={{ color: managementColors.general.colorLetter }}
                >
                  {@html comment.body}
                </span>
              </div>
            </div>
          </div>
        {/if}
      {/each}
      <p>
        <br /> <br />
      </p>
    </div>
    <div
      class="footer-message {managementColors.general.colorMode == 'white'
        ? 'footer-message--white'
        : managementColors.general.colorMode == 'dark'
        ? 'footer-message--dark'
        : ''}"
      use:styling={{ background: managementColors.general.backgroundDivFotter }}
    >
      {#if dataRequest.status == "Validate" || dataRequest.status == "Processed" || dataRequest.status == "Accepted"}
        <textarea
          placeholder={$_("Do you have a comment? type here")}
          id="comment"
          bind:value={comment}
          type="text"
          rows="4"
          on:keyup={onKeyUp}
          on:keypress={onKeyPress}
          class="validate textarea-field {managementColors.general.colorMode ==
          'white'
            ? 'textarea-field--white'
            : managementColors.general.colorMode == 'dark'
            ? 'textarea-field--dark'
            : ''}"
          use:styling={{
            background: managementColors.general.bacgroundCardStatusBar,
          }}
        />
        {#if blockButton}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="send__clicle {managementColors.general.colorMode == 'white'
              ? 'send__clicle--white'
              : managementColors.general.colorMode == 'dark'
              ? 'send__clicle--dark'
              : ''}"
            on:click={handleComment}
          >
          
            <i
              use:styling={{
                color: managementColors.general.backgroundDivFotter,
              }}
              class="material-icons send__clircle--icon">send</i
            >
          </div>
        {:else}
          <div
            class="send__clicle"
            use:styling={{ background: managementColors.color }}
            on:click={handleComment}
          >
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <i
              use:styling={{
                color: managementColors.general.backgroundDivFotter,
              }}
              class="material-icons send__clircle--icon"
              >send</i
            >
          </div>
        {/if}
      {:else}
        <p
          class="message-inactive"
          use:styling={{ color: managementColors.general.colorLetter }}
        >
          {#if dataRequest.type === "request" || dataRequest.type === "restaurant" || dataRequest.type === "service"}
            {$_(
              "Sending messages is disabled because the request/order has already been closed"
            )}
          {:else}
            {$_(
              "Sending messages is disabled because the reservation has already been closed"
            )}
          {/if}
        </p>
      {/if}
    </div>
  </div>
</div>

<style>
  .messages {
    position: relative;
    top: 90px;
    height: auto;
    padding-bottom: 175px;
  }

  .title-nav {
    position: fixed;
    height: 60px;
    top: 0;
    background: white;
    z-index: 99;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    color: #363636;
    font-size: 24px;
    text-transform: capitalize;
    padding: 16px 24px;
    font-weight: 700;
    margin: 0px;
  }

  .title-nav--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .title-nav--dark {
    border-bottom: 1px solid #686c6d;
  }

  .subtitle-nav {
    position: fixed;
    top: 60px;
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 0px;
    z-index: 99;
  }

  .subtitle-nav-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .subtitle-nav-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .subtitle-nav > .type {
    color: #686c6d;
    font-weight: 700;
    font-size: 12px;
    padding-left: 16px;
  }

  .subtitle-nav > .name {
    color: #686c6d;
    font-weight: 400;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .con_avatar {
    width: auto;
    margin: 0px 6px 0px 12px;
    float: left;
  }

  .message-guest-photo {
    border-radius: 50%;
    height: 36px;
    width: 36px;
  }

  .message-guest {
    border-radius: 2px 16px 16px 16px;
    word-break: break-word;
    margin-left: 24px;
    min-height: 75px;
    width: auto !important;
    max-width: 80% !important;
    padding: 16px 16px 16px 0;
    float: left;
    margin: 4px 0px;
    display: flex;
  }

  .message-guest > div > .name {
    color: #363636;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
  }
  .message-guest > .date {
    color: #686c6d;
    font-weight: 300;
    font-size: 14px;
  }
  .message-guest > .body {
    color: #363636;
    font-size: 16px;
    font-weight: 400;
  }

  .message-internal {
    word-break: break-word;
    border-radius: 16px 16px 2px 16px;
    margin-right: 24px;
    min-height: 75px;
    width: auto;
    max-width: 70% !important;
    padding: 16px;
    float: right;
    margin: 4px 0px;
  }

  .message-internal-border--white {
    border: 1px solid #f4f4f4;
  }

  .message-internal-border--dark {
    border: 1px solid #363636;
  }

  .message-internal > .name {
    color: #363636;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
  }
  .message-internal > .date {
    color: #686c6d;
    font-weight: 300;
    font-size: 14px;
  }
  .message-internal > .body {
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  .mydrawer__base-hide {
    display: none; /* Hidden by default */
  }

  .mydrawer__base {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    padding: 0px !important;
  }

  .mydrawer {
    display: block !important;
    position: relative;
    overflow: hidden;
    z-index: 102 !important;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000003d;
    opacity: 1;
    float: right;
    padding: 0px !important;
    overflow-y: auto;
    width: 100%;
  }

  .footer-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    float: left;
    min-height: 76px;
    width: 100%;
    padding: 20px 0px 40px 0px !important;
  }

  .footer-message--white {
    border-top: solid 1px #e3e3e3;
  }

  .footer-message--dark {
    border-top: 1px solid #686c6d;
  }

  .send__clicle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-left: 16px;
    cursor: pointer;
    margin-top: -6px;

  }

  .send__clicle--white {
    background: #c3c3c3;
  }

  .send__clicle--dark {
    background: #686c6d;
  }

  .send__clircle--icon {
    font-size: 24px;
  }

  .padding_0 {
    padding: 0px !important;
  }

  .textarea-field {
    width: 85% !important;
    border-radius: 16px !important;
    max-height: 116px !important;
    height: 36px;
    resize: none;
    padding: 9px 8px 5px 7px !important;
    word-break: break-all !important;
  }

  .textarea-field--white {
    border: solid 1px #c3c3c3 !important;
  }

  .textarea-field--dark {
    border: solid 1px #686c6d !important;
  }

  .return-link {
    display: none !important;
  }

  .cont__mesagge__chat {
    padding: 0px 16px 0px 16px !important;
  }

  .button__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 8px !important;
    float: right;
    cursor: pointer;
  }

  .button__circle__icon-close {
    font-size: 18px !important;
    letter-spacing: 0px;
    color: #686c6d;
    opacity: 1;
    cursor: pointer;
  }

  .title-nav__text {
    font-size: 1.25rem;
    font-weight: 700;
    float: left;
  }

  .title-nav__cont__left {
    float: left;
    width: 50%;
  }

  .title-nav__cont__right {
    float: left;
    width: 50%;
  }

  .message-inactive {
    padding-left: 16px;
  }

  @media only screen and (min-width: 992px) {
    .mydrawer {
      width: 45%;
    }

    .footer-message {
      width: 45%;
    }

    .title-nav {
      width: 45%;
    }
  }

  @media (max-width: 1000px) {
    .title-nav {
      text-align: center;
    }
    .subtitle-nav {
      margin: 0px !important;
    }
    .return-link {
      display: block !important;
      color: #363636;
      background: #e3e3e3;
      border-radius: 50%;
      padding: 5px;
      font-size: 12px;
      float: left;
      left: 12px;
      top: 24px;
      cursor: pointer;
      position: relative;
    }
    .message-internal {
      max-width: 95% !important;
    }
    .off {
      display: none;
    }
    .close {
      display: none !important;
    }
  }

  .status-green {
    color: #1ab394;
  }

  .status-red {
    color: #ff4855;
  }
  .status-yellow {
    color: #ffa64d;
  }

  .not-messages {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
  }

  .not-messages__text {
    font-size: 14px;
    font-weight: 700;
  }

  .icon-not-message {
    position: relative;
    font-size: 16px;
    margin-right: 4px;
  }
  .messages-inactive {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .close {
    color: #363636;
    background: #e3e3e3;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    display: block;
    top: 10px;
  }
  .off {
    display: none !important;
  }
</style>
