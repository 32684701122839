<script>
  import { _, locale } from "svelte-i18n";

  export let roomNumber;
  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div class="card__indicator">
  <i
    class="material-icons icon--indicator">
    location_on
  </i>
  &nbsp;
  <span
    class="room__text">
    {$_("Room")}
  </span>
  &nbsp;
  <span
    class="room__number">
    {roomNumber}
  </span>
</div>

<style>
  .card__indicator {
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    background: #F4F4F4;
    border-radius: 4px;
    padding: 0px 4px 0px 4px !important;
  }

  .icon--indicator {
    font-size: 1rem;
    color: #686C6D;
  }

  .room__text {
    font-size: 0.75rem;
    color: #212121;
  }

  .room__number {
    font-size: 0.75rem;
    font-weight: 700;
    color: #212121;
  }

  @media only screen and (min-width: 601px) {
    .icon--indicator {
      font-size: 0.75rem;
    }

    .room__text {
      font-size: 0.75rem;
    }

    .room__number {
      font-size: 0.75rem;
    }
  }
</style>
