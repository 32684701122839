<script>
    import { _, locale } from "svelte-i18n";

    export let onAccepted;
    export let propertyData;
    const getFullYear = new Date().getFullYear();
</script>

<div class="col l6 offset-l3 m12 s12 feedback">
    <div class="col s12 feedback__cont__logo">
        {#if propertyData.logo}
            <img class="feedback__logo" alt="logo" src={propertyData.logo} />
        {/if}
    </div>

    <div class="col s12 feedback__description">
        <span class="feedback__description__text {$locale}">
            <h6>Términos y Condiciones - Ley de Protección de Datos Ecuador</h6>
            <p>
                Bienvenido/a nuestra encuesta de satisfacción. Antes de utilizar
                nuestros servicios, lea detenidamente los siguientes Términos y
                Condiciones, que describen sus derechos y responsabilidades en
                relación con la Ley de Protección de Datos en Ecuador. Al
                acceder, usted acepta cumplir con estos términos y condiciones.
            </p>
            <h6>Recopilación de datos personales:</h6>
            <p>
                Nos comprometemos a respetar su privacidad y a proteger sus
                datos personales de acuerdo con la Ley de Protección de Datos en
                Ecuador. Durante su visita a nuestro sitio web, podemos
                recopilar y procesar cierta información personal, como su
                nombre, dirección de correo electrónico y otra información
                relevante para brindarle nuestros servicios. Su consentimiento
                será solicitado antes de recopilar cualquier dato personal.
            </p>
            <h6>Uso y almacenamiento de datos personales:</h6>
            <p>
                Los datos personales que recopilemos serán utilizados únicamente
                con fines específicos relacionados con la prestación de nuestros
                servicios y serán almacenados de forma segura. No venderemos,
                alquilaremos ni divulgaremos sus datos personales a terceros sin
                su consentimiento explícito, a menos que así lo exija la ley.
            </p>
            <h6>Derechos del titular de los datos:</h6>
            <p>
                Como titular de los datos personales, usted tiene derecho a
                acceder, corregir, actualizar y eliminar su información personal
                almacenada en nuestra base de datos. Puede ejercer estos
                derechos enviándonos una solicitud por escrito a través de los
                medios de contacto proporcionados en nuestro sitio web.
            </p>
            <h6>Seguridad de los datos:</h6>
            <p>
                Implementamos medidas técnicas y organizativas apropiadas para
                proteger sus datos personales contra el acceso no autorizado, la
                divulgación o la destrucción. Sin embargo, no podemos garantizar
                la seguridad absoluta de la transmisión de datos a través de
                Internet.
            </p>
            <h6>Uso de cookies:</h6>
            <p>
                Podemos utilizar cookies y tecnologías similares para mejorar su
                experiencia en nuestro sitio web. Estas cookies recopilan
                información sobre su navegación y preferencias, pero no
                contienen datos personales identificables.
            </p>
            <h6>Modificaciones a estos términos y condiciones:</h6>
            <p>
                Nos reservamos el derecho de modificar estos Términos y
                Condiciones en cualquier momento. Cualquier cambio será efectivo
                desde el momento de su publicación en nuestro sitio web. Le
                recomendamos que revise regularmente estos términos y
                condiciones para mantenerse informado sobre cualquier
                actualización.
            </p>
            <br />
            <p>
                Al utilizar nuestro sitio web, usted reconoce y acepta estos
                Términos y Condiciones, así como nuestra política de privacidad.
                Si no está de acuerdo con alguno de estos términos, le
                recomendamos que no utilice nuestro sitio web. Si tiene alguna
                pregunta o inquietud acerca de nuestros Términos y Condiciones,
                no dude en ponerse en contacto con nosotros a través de los
                medios de contacto proporcionados en nuestro sitio web.
            </p>
        </span>
    </div>

    <br />
    <br />
    <div
        class="col s12"
        style="display:flex;justify-content: space-between; align-items: center;margin-top: 2rem;"
    >
        <button class="btn button--continuebase" on:click={onAccepted(true)}
            >Aceptar</button
        >
        <button class="btn button--continuebase" on:click={onAccepted(false)}>
            Declinar</button
        >
    </div>
</div>
<div class="col s12 feedback__footer">
    <div class="col s12 m5 feedback__footer__powered">
        <div class="feedback__footer__powered__move">
            <span class="feedback__footer__text"> Powered by</span>
            <img
                class="feedback__footer__logo"
                alt="footerlogo"
                src="img/logo-guesthub.png"
            />
        </div>
    </div>
    <div class="col s12 m7 feedback__footer__copyright">
        <span class="feedback__footer__text"> © {getFullYear} GuestHub </span>
        <span class="feedback__footer__text {$locale}">
            {$_("All rights reserved")}</span
        >
    </div>
</div>

<style>
    .container-fluid {
        height: 100% !important;
    }

    .feedback {
        min-height: 95% !important;
    }

    .feedback__cont__logo {
        display: flex;
        justify-content: center;
        padding: 3.099rem 0rem 0rem 0rem !important;
    }

    .feedback__logo {
        width: 58px;
        height: 58px;
        opacity: 1;
    }

    .feedback__title {
        padding: 1.681rem 0rem 0rem 0rem !important;
    }

    .feedback__title__text {
        font-size: 1.5rem;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }

    .feedback__title__text--bold {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }

    .feedback__description {
        padding: 1rem 0rem 0rem 0rem !important;
    }

    .feedback__description__text {
        font-size: 1rem;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }

    .feedback__question {
        text-align: center;
        padding: 2rem 0rem 0rem 0rem !important;
    }

    .feedback__question__text {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }

    .feedback__starts {
        padding: 0.5rem 0rem 0rem 0rem !important;
    }

    .feedback__starts__individual {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 0rem 0rem 0rem 0rem !important;
    }

    .feedback__starts__message {
        text-align: center;
        padding: 0rem 0rem 0rem 0rem;
    }

    .feedback__starts__message__text {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        opacity: 1;
    }

    .strborder {
        font-size: 2rem;
        padding-right: 1rem;
        color: #c3c3c3;
        cursor: pointer;
    }

    .startRed {
        font-size: 2rem;
        padding-right: 1rem;
        color: #ff4855 !important;
        cursor: pointer;
    }

    .startOrange {
        font-size: 2rem;
        padding-right: 1rem;
        color: #ffa64d !important;
        cursor: pointer;
    }

    .startYellow {
        font-size: 2rem;
        padding-right: 1rem;
        color: #fedf61 !important;
        cursor: pointer;
    }

    .feedback__textarea {
        padding: 2rem 0rem 0rem 0rem !important;
    }

    textarea {
        min-height: 6.063rem;
    }

    .label__input {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        margin-bottom: 0.5rem;
        opacity: 1;
    }

    .feedback__actions {
        padding: 1.938rem 0rem 0rem 0rem !important;
    }

    .button--continuebase {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f4050 0% 0% no-repeat padding-box !important;
        float: right;
        cursor: pointer;
        width: auto;
        height: 38px;
        border-radius: 8px;
        opacity: 1;
    }

    .button--block {
        background: #c3c3c3 0% 0% no-repeat padding-box !important;
    }

    .text--button {
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: capitalize;
        opacity: 1;
    }

    .feedback__footer {
        bottom: 0;
        padding-top: 3.438rem !important;
        z-index: -1;
    }

    .feedback__footer__logo {
        width: 6rem;
        height: 2rem;
        margin-bottom: 5px;
        opacity: 1;
    }

    .feedback__footer__powered {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0rem 0rem 0rem 0rem !important;
    }

    .feedback__footer__copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0rem 0rem 0rem 0rem !important;
    }

    .feedback__footer__text {
        font-size: 0.75rem;
        letter-spacing: 0px;
        color: #212121;
        margin-left: 0.3rem !important;
        opacity: 1;
    }

    .feedback__footer__powered__move {
        display: flex;
        align-items: center;
    }

    @media only screen and (min-width: 601px) {
        .feedback__starts {
            display: flex;
            align-items: center;
        }

        .feedback__starts__message__text {
            float: left;
        }

        .feedback__footer {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .feedback__footer__powered__move {
            width: auto;
            float: right;
        }

        .feedback__question {
            text-align: left;
        }

        .feedback__footer__powered__move {
            float: right;
        }

        .feedback__footer__powered {
            display: block;
            width: auto !important;
            margin: 0rem !important;
        }

        .feedback__footer__text {
            float: left;
        }

        .feedback__footer__copyright {
            display: block;
            width: auto !important;
            margin: 0rem !important;
        }

        .feedback__starts__individual {
            text-align: left;
        }
    }
</style>
