<script>
  import { push } from "svelte-spa-router";
  import MessageView from "../components/view-messages/component.svelte";
  import PropertyFeatures from "../property-features-service";

  export let params = {};

  let state = Object.assign({ features: [], reservation: undefined }, params);

  let title;
  let message;
  let reloading;
  let imgView;
  let classImg;
  let btnActionMobile;
  let callBtn;
  let classBtnCall;
  let classBtnGoText;
  let textCallBtn;
  let goBtn;
  let classBtnGo;
  let textGoBtn;
  let btnClose;
  let ownerPhone;
  let managementColors;
  let colorImgSvg;

  const validationColor = () => {
    JSON.parse(localStorage.getItem("managementColors"))
      ? (managementColors = JSON.parse(
          localStorage.getItem("managementColors")
        ))
      : (managementColors.color = "#2bbbad");

    if (managementColors.general.backgroundColor != "#FFFFFF") {
      colorImgSvg = "svg-white";
    } else {
      colorImgSvg = "svg-dark";
    }
  };

  validationColor();

  let validationViewMessage = () => {
    title = "Check your email";
    message =
      "The validation code will be sent the email associated with your reservation";
    imgView = "support-guest.svg";
    textGoBtn = "Home";
    goBtn = true;
    classBtnGo = "button-green";
    classBtnGoText = "text--button-home--dark";
    btnClose = true;
  };

  validationViewMessage();

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  }

  setFontFamily(null);

  let handleAction = (e) => {
    push(`/properties/${state.propertyId}`);
  };

  let closeview = (e) => {
    push(`/properties/${state.propertyId}`);
  };
</script>

<div class="view-magic-link">
  <MessageView
    bind:closeview
    bind:btnClose
    bind:title
    bind:message
    bind:reloading
    bind:callBtn
    bind:goBtn
    bind:imgView
    bind:btnActionMobile
    bind:textCallBtn
    bind:textGoBtn
    bind:ownerPhone
    bind:classImg
    bind:classBtnGo
    bind:classBtnGoText
    bind:classBtnCall
    bind:managementColors={managementColors}
    bind:colorImgSvg
    bind:handleAction
  />
</div>

<style>
  .view-magic-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
