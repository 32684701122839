<script>
  import { _, locale } from "svelte-i18n";
  export let handleLogin;
  export let resetBtnVariables;
  export let managementColors;
  export let textBtn;

  let enabledBtn = false;
  let saving;
  let colorLetterBaseButton;

  const styling = (node, vars) =>
    Object.entries(vars).forEach(([p, v]) => (node.style[p] = v));

  const validationColors = (color) => {
    if (managementColors.general.backgroundColor != "#FFFFFF") {
      colorLetterBaseButton = color;
    } else {
      colorLetterBaseButton = "#FFFFFF";
    }
  };

  validationColors("#999999");

  resetBtnVariables = (blockButton, loginError) => {
    if (!blockButton) {
      enabledBtn = true;
      validationColors("#2C2C2C");
    } else {
      enabledBtn = false;
      saving = false;
      validationColors("#999999");
    }
    loginError ? (saving = false) : null;
  };

  let save = () => {
    handleLogin();
    enabledBtn ? (saving = true) : null;
  };
</script>

{#if enabledBtn || saving}
  <button
    use:styling={{ background: managementColors.color }}
    class="btn-success button--continuebase"
    disabled={saving}
    on:click={save}
  >
    {#if !saving}
      <span
        use:styling={{ color: colorLetterBaseButton }}
        class="text--button {$locale}"
      >
        {$_(textBtn)}
      </span>
    {:else}
      <div class="preloader-wrapper small active preloader--button">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle" />
          </div>
          <div class="gap-patch">
            <div class="circle" />
          </div>
          <div class="circle-clipper right">
            <div class="circle" />
          </div>
        </div>
      </div>
    {/if}
  </button>
{:else}
  <button
    class=" btn-success btn button--continuebase"
    use:styling={{
      background: managementColors.general.buttonsBackgroundBase,
    }}
  >
    {#if !saving}
      <span
        use:styling={{ color: colorLetterBaseButton }}
        class="text--button {$locale}"
      >
        {$_(textBtn)}
      </span>
    {:else}
      <div class="preloader-wrapper small active preloader--button">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle" />
          </div>
          <div class="gap-patch">
            <div class="circle" />
          </div>
          <div class="circle-clipper right">
            <div class="circle" />
          </div>
        </div>
      </div>
    {/if}
  </button>
{/if}

<style>
  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    cursor: pointer;
    width: 50%;
    height: 38px;
    background: #c3c3c3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    opacity: 1;
  }

  .text--button {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: initial;
  }

  .preloader--button {
    width: 25px !important;
    height: 25px !important;
  }

  .spinner-blue-only {
    border-color: white;
  }
</style>
