<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  export let outlet;
  export let propertyId;
  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleOutletDetail = (data) => {
    push(`/properties/${propertyId}/${data.type}/${data.id}`);
  };
</script>

<div
  class="col s12 m12 card card-outlet"
  use:styling={{ background: managementColors.general.backgroundColorCards }}
  on:click={handleOutletDetail(outlet)}
>
  <div
    class={!outlet.inService
      ? "card__image-cont off-active"
      : "card__image-cont"}
  >
    {#if outlet.cover}
      <img class="card__image" src={outlet.cover} alt="" />
    {/if}
  </div>
  <div
    class={!outlet.inService ? "card-content padding-top-off" : "card-content"}
  >
  <div style="word-break: break-all;">
    <span
      class={!outlet.inService ? "card__title off-txt" : "card__title"}
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {outlet.name}
    </span>
  </div>
    <div class="card__cont-text">
      <p class={!outlet.inService ? "card__text sub__txt__off" : "card__text"}
        use:styling={{ color: managementColors.general.colorCardTxt }}
      >
      {outlet.description}
      </p>
    </div>
  </div>
  {#if outlet.cover && !outlet.inService}
    <div class="out-of-service">
      <span>
        {$_("Off hours")}
      </span>
    </div>
  {/if}
</div>

<style>
  .card-outlet {
    position: relative !important;
    padding: 0px;
    height: 290px !important;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    min-height: 60px;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    margin-top: 0px !important;
    opacity: 1;
    cursor: pointer;
  }

  .off-txt {
    color: #686c6d !important;
  }
  .card-outlet{
    height: auto !important;
  }

  @media only screen and (min-width: 0px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      
    }

    .card__text {
      width: 97% !important;
    }

    .out-of-service {
      bottom: 159px !important;
    }
  }

  @media only screen and (min-width: 334px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      bottom: 137px !important;
    }
  }

  @media only screen and (min-width: 479px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      bottom: 114px !important;
    }
  }

  @media only screen and (min-width: 732px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      bottom: 138px !important;
    }
  }

  @media only screen and (min-width: 870px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      margin-top: 162px !important;
    }
  }

  @media only screen and (min-width: 1201px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      margin-top: 179px !important;
    }
  }

  @media only screen and (min-width: 1589px) {
    .card-outlet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }

    .out-of-service {
      bottom: 112px !important;
    }
  }

  .card__image {
    width: 100%;
    height: 160px;
    object-fit: cover;
    object-position: center center;
    border-radius: 4px 4px 0px 0px !important;
  }

  .card__photo {
    width: 70px;
    height: 70px;
    /* object-fit: cover; */
    /* object-position: center right; */
    border-radius: 4px !important;
    box-shadow: 0px 0px 8px #00000029;
    position: relative;
    right: 8px;
    top: -57px;
    float: right;
  }

  .card-content {
    padding: 14px 0px 0px 16px !important;
  }

  .card__title {
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 97%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card__text {
    font-size: 14px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card__cont-text {
    display: block;
    padding-bottom: 20px;
  }

  @media only screen and (min-width: 992px) {
    .card__image {
      height: 196px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card__image {
      height: 215px;
    }
  }
  .off-active {
    opacity: 24%;
    /*background: #FFFFFF;*/
  }
  .out-of-service {
    position: absolute;
    bottom: 102px;
    left: 10px;
    background: #e3e3e3;
    padding: 5px 5px 2px 0px;
    border-radius: 4px;
    width: auto;
    min-width: 83px;
    text-align: center;
    height: 24px;
    top: 0px;
    margin-top: 125px;
  }
  .out-of-service > span {
    font-size: 12px;
    color: #212121;
    text-transform: uppercase;
    position: relative;
    top: -6.5px;
    font-weight: 700;
    right: -2.5px;
  }
  .padding-top-off {
    padding-bottom: 0px !important;
    padding-top: 14px !important;
  }

  .sub__txt__off{
    opacity: 0.5 !important;
  }
</style>
