<script>
  import { push } from "svelte-spa-router";

  export let file;
  export let handleFileDownloading;
  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const hover = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px${managementColors.color}`;

      const element = document.getElementById(`${i}`);
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty("color", managementColors.color)
        : null;
    }
  };

  const handleMouseOut = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px rgb(0 0 0 / 20%)`;

      const element = document.getElementById(`${i}`);
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty(
            "color",
            managementColors.general.colorLetter
          )
        : null;
    }
  };

  let goFile = (file) => {
    handleFileDownloading(true);
    window.open(file.link, "_blank").focus();
    //let pdfDefault = `https://docs.google.com/viewerng/viewer?url=${file.link}`;
    //window.open(pdfDefault, '_blank').focus();
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<div
  id={file._id}
  on:mouseover={() => hover(file._id)}
  on:mouseout={() => handleMouseOut(file._id)}
  class="div__add col s12"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
  on:click={() => goFile(file)}
>
  {#if file.preview}
    <div class="div__image">
      <img class="link-img" src={file.preview} alt={file.title} />
    </div>
  {/if}

  <div class="div__text">
    <span
      class="text_addinfo"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {file.title.substr(0, 40)}
    </span>
  </div>
</div>

<!--div
  id={name}
  on:mouseover={() => hover(name)}
  on:mouseout={() => handleMouseOut(name)}
  class="div__add col s12"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
  on:click={() => goFile(href)}
>
  {#if /\.(pdf)$/i.test(href)}
  {:else if img}
    <div class="div__image">
      <img class="link-img" src={img} alt="" />
    </div>
  {/if}

  <div class="div__text">
    <span
      id={`${name}text`}
      class="text_addinfo"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {name}
    </span>
  </div>
</div--->

<style>
  .div__add {
    height: 67px;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 0px !important;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  .div__image {
    width: 20%;
    height: 67px;
    float: left;
  }

  .div__text {
    display: flex;
    align-items: center;
    min-height: 67px;
    height: auto;
    float: left;
    word-break: break-word;
    width: 70%;
    margin-left: 16px !important;
  }

  .text_addinfo {
    font-size: 0.875 rem;
    font-weight: 700;
  }

  .link-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
</style>
