<script>
  export let contentUrl;
  let showIframe;
</script>

<iframe
  title="content"
  style="margin-top:4rem,overflow-y:hidden"
  src={`${contentUrl}`}
  width="100%"
  height="100%"
/>
