<script>
  import { _ } from "svelte-i18n";
  import { format } from "date-fns";

  export let dataRequest;
  export let managementColors;
  export let filterSvg;
  export let defaultStyles;
  const offset = new Date().getTimezoneOffset();
  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  function correctDate(date) {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d;
  }
</script>

<div
  class="col s12 card card--request cont__check"
  use:styling={{
    background: managementColors.general.cardShipColor,
  }}
>
  <div
    style="display: flex;border-radius: 8px;margin-top: 30px !important;    width: 100%;
    justify-content: space-between;margin: 0 auto;"
  >
    <div class="div__checkin">
      <span
        class="check__and__checkout"
        style="color: {managementColors.general.colorLetter}; font-weight: 600;"
      >
        CHECK-IN</span
      >

      <span
        class="date__check"
        style="color: {managementColors.general.colorLetter}; "
      >
        {#if dataRequest.checkInDate}
          {format(correctDate(dataRequest.checkInDate), "dd")}
          {$_(format(correctDate(dataRequest.checkInDate), "MMM"))}
          {format(correctDate(dataRequest.checkInDate), "yyyy")}
        {:else}
          {$_("No data")}
        {/if}
      </span>

      <span
        class="check__and__checkout"
        style="color: {managementColors.general.colorLetter};"
        >{$_("ARRIVAL")} {dataRequest.checkInHour}</span
      >
    </div>
    <div
      style="
    display: flex;
    align-items: center;"
    >
      <i
        class="material-icons"
        style="font-size: 32px;
        margin-bottom: 2px;"
        use:styling={{ color: managementColors.color }}
      >
        keyboard_arrow_right
      </i>
    </div>
    <div class="div__checkout">
      <span
        class="check__and__checkout"
        style="color: {managementColors.general
          .colorLetter};  font-weight: 600;">CHECKOUT</span
      >

      <span
        class="date__check"
        style="color: {managementColors.general.colorLetter};"
      >
        {#if dataRequest.checkOutDate}
          {format(correctDate(dataRequest.checkOutDate), "dd")}
          {$_(format(correctDate(dataRequest.checkOutDate), "MMM"))}
          {format(correctDate(dataRequest.checkOutDate), "yyyy")}
        {:else}
          {$_("No data")}
        {/if}
      </span>

      <span
        class="check__and__checkout"
        style="
        color: {managementColors.general.colorLetter}; "
        >{$_("DEPARTURE")} {dataRequest.checkOutHour}</span
      >
    </div>
  </div>
</div>

<style>
  .card--request {
    box-shadow: 0px 0px 8px #00000029 !important;
    border-radius: 4px !important;
  }

  .txt__date__reser {
    font-size: 14px;
    color: #686c6d;
  }

  .txt__idreser {
    font-weight: 700;
    margin-left: 16px;
  }

  .cont__check {
    padding: 0px !important;
  }
  .div__text {
    padding: 0px 16px 16px 16px !important;
    word-break: break-word;
  }

  .cont__amoun__person {
    background: #363636;
    border-radius: 4px;
    padding: 5px 7px 5px 7px !important;
    position: relative;
    left: 9px;
    top: 1px;
    font-weight: 700;
    font-size: 12px;
  }

  .icon__person {
    position: relative;
    left: -4px;
    top: 4px;
    font-size: 18px;
    color: #999999;
  }
  .div__name__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
  }

  .amount__reser {
    float: right;
    font-weight: 600;
  }

  .div__name__text--category {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div__info__service {
    display: flex;
    align-items: center;
    padding: 16px 16px 19px 16px !important;
  }

  .cont__header-icon--create {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 16px;
    opacity: 1;
  }

  .header-icon--white {
    width: 28px;
    height: 28px;
    filter: invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg)
      brightness(117%) contrast(100%);
  }

  .header-icon--dark {
    width: 28px;
    height: 28px;
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%);
  }

  .header-icon--completed {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 4px 8px #00000029;
  }

  .header_nameproperty {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
  }

  .div__date {
    padding: 16px 16px 4px 16px !important;
  }

  .request__id {
    font-size: 0.875rem;
    font-weight: 700;
  }

  .price__subtotal {
    font-weight: 700;
    font-size: 14px;
  }

  .cont__subtotal {
    padding: 16px 16px 16px 16px !important;
    border-radius: 0px 0px 4px 4px;
  }

  .title__information {
    display: block;
    font-size: 10px;
    font-weight: 600;
    color: #686c6d !important;
  }

  .cont__information {
    padding: 19px 16px 24px 16px !important;
  }

  @media only screen and (min-width: 20px) {
    .div__name__text {
      font-size: 14px;
      font-weight: 600;
    }

    .name__count {
      font-size: 10px;
    }

    .div__name__text--category {
      font-size: 14px;
    }

    .header_nameproperty {
      font-size: 16px;
    }

    .data__text {
      font-size: 14px;
    }

    .title__information {
      font-size: 10px;
    }

    .check__and__checkout {
      text-align: left;
      width: 100%;
      display: block;
      font-size: 10px;
    }

    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 16px;
      font-weight: 700;
    }

    .div__info__service {
      display: flex;
      align-items: center;
      padding: 16px 16px 19px 16px !important;
    }

    .div__checkin {
      padding: 8px 0px 8px 16px;
    }

    .div__checkout {
      padding: 8px 16px 8px 0px;
    }

    .div__text {
      padding: 0px 16px 16px 16px !important;
      word-break: break-word;
    }

    .cont__subtotal {
      padding: 16px 16px 16px 16px !important;
    }

    .cont__img__id {
      padding: 16px 16px 0px 16px;
    }

    .cont__information {
      padding: 19px 16px 24px 16px !important;
    }

    .cont__desktop__columns {
      width: 100% !important;
    }

    .div__nomobile {
      padding: 16px 16px 0px 16px !important;
    }
  }

  @media only screen and (min-width: 601px) {
    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 24px;
      font-weight: 700;
    }

    .div__info__service {
      display: flex;
      align-items: center;
      padding: 16px 32px 19px 32px !important;
    }

    .div__checkin {
      padding: 8px 0px 8px 32px;
    }

    .div__checkout {
      padding: 8px 32px 8px 0px;
    }

    .div__text {
      padding: 0px 32px 16px 32px !important;
      word-break: break-word;
    }

    .cont__subtotal {
      padding: 16px 32px 16px 32px !important;
    }

    .cont__img__id {
      padding: 16px 32px 0px 32px;
    }

    .cont__information {
      padding: 19px 32px 24px 32px !important;
    }

    .div__nomobile {
      padding: 16px 32px 0px 32px !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .header_nameproperty {
      font-size: 24px;
    }

    .div__name__text {
      font-size: 16px;
    }

    .name__count {
      font-size: 12px;
    }

    .data__text {
      font-size: 16px;
    }

    .title__information {
      font-size: 12px;
    }

    .check__and__checkout {
      text-align: left;
      width: 100%;
      display: block;
      font-size: 12px;
    }

    .div__nomobile {
      padding: 16px 32px 0px 32px !important;
      display: block;
    }

    .date__check {
      width: 100%;
      text-align: left;
      display: block;
      font-size: 32px;
      font-weight: 700;
    }

    .cont__desktop__columns {
      width: 50% !important;
      display: inline-block;
    }

    .cont__information {
      display: inline-table;
    }
  }
</style>
