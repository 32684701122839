import environments from "./environments";

let dictionaryColors = [
  {
    title: "redfilter",
    color: "#FD0D1B",
  },
  {
    title: "orangefilter",
    color: "#FD9726",
  },
  {
    title: "yellowfilter",
    color: "#FFCC21",
  },
  {
    title: "greenfilter",
    color: "#6AA84E",
  },
  {
    title: "bluefilter",
    color: "#0A23FB",
  },
  {
    title: "violetfilter",
    color: "#9825FB",
  },
  {
    title: "purplefilter",
    color: "#FD28FC",
  },
  {
    title: "greyfilter",
    color: "#969696",
  },
  {
    title: "redwinefilter",
    color: "#A51C0E",
  },
  {
    title: "mustardfilter",
    color: "#F4B92A",
  },
  {
    title: "gcreamfilter",
    color: "#1AB394",
  },
  {
    title: "blightfilter",
    color: "#3C74D6",
  },
  {
    title: "darkvioletfilter",
    color: "#674EA6",
  },
  {
    title: "dpurplefilter",
    color: "#A74F79",
  },
  {
    title: "gdarkfilter",
    color: "#404040",
  },
  {
    title: "cdarkfilter",
    color: "#5B0F04",
  },
  {
    title: "goldfilter",
    color: "#E69239",
  },
  {
    title: "clightfilter",
    color: "#B25D16",
  },
  {
    title: "ydarkfilter",
    color: "#BF8F1F",
  },
  {
    title: "greendarkfilter",
    color: "#264F13",
  },
  {
    title: "bluetwofilter",
    color: "#1B4682",
  },
  {
    title: "bluedarkfilter",
    color: "#1F124D",
  },
  {
    title: "purpledarkfilter",
    color: "#4B102F",
  },
  {
    title: "blackcolor",
    color: "#000000",
  },
  {
    title: "ligthredfilter",
    color: "#EF9A9A",
  },
  {
    title: "ligthorangefilter",
    color: "#FFCC80",
  },
  {
    title: "ligthyellowfilter",
    color: "#FDF5CC",
  },
  {
    title: "ligthgreenfilter",
    color: "#C5E1A5",
  },
  {
    title: "ligthbluefilter",
    color: "#C7DAFA",
  },
  {
    title: "ligthvioletfilter",
    color: "#E2D9FF",
  },
  {
    title: "ligthpurplefilter",
    color: "#E8D2DC",
  },
  {
    title: "ligthgreyfilter",
    color: "#E3E3E3",
  },
  {
    title: "ligthredwinefilter",
    color: "#E06767",
  },
  {
    title: "ligthmustardfilter",
    color: "#F8B36B",
  },
  {
    title: "ligthgcreamfilter",
    color: "#FED865",
  },
  {
    title: "ligthblightfilter",
    color: "#6AA84E",
  },
  {
    title: "ligthdarkvioletfilter",
    color: "#A3C2F4",
  },
  {
    title: "ligthdpurplefilter",
    color: "#C4B1F7",
  },
  {
    title: "ligthgdarkfilter",
    color: "#FFA5D0",
  },
  {
    title: "ligthcdarkfilter",
    color: "#969696",
  },
  {
    title: "ligthgoldfilter",
    color: "#E42527",
  },
  {
    title: "ligthclightfilter",
    color: "#FF8600",
  },
  {
    title: "ligthydarkfilter",
    color: "#FFC50F",
  },
  {
    title: "ligthgreendarkfilter",
    color: "#1BB394",
  },
  {
    title: "ligthbluetwofilter",
    color: "#589AFF",
  },
  {
    title: "ligthbluedarkfilter",
    color: "#B45FFF",
  },
  {
    title: "ligthpurpledarkfilter",
    color: "#FF4EA3",
  },
  {
    title: "ligthblackcolor",
    color: "#BF8F1F",
  },
];

const getColorComponentsAndIcons = (color) => {
  return dictionaryColors.find((item) => item.color == color)
    ? dictionaryColors.find((item) => item.color == color)
    : {
      title: "custom",
      color: color,
    };
};


const getColorsContrast = (guestappsettins) => {
  let managmentColor;

  if (guestappsettins.background.toUpperCase() == "#FFFFFF") {
    document.body.style.backgroundColor = "#FFFFFF";
    managmentColor = {
      backgroundColor: guestappsettins.background.toUpperCase(),
      colorLetter: "#212121",
      backgroundColorCards: guestappsettins.background.toUpperCase(),
      colorCardTxt: "#686c6d",
      colorSame: "#686C6D",
      colorMode: "white",
      cardShipColor: "#F4F4F4",
      colorLetterBaseButton: guestappsettins.background.toUpperCase(),
      iconFotter: "img/logo-guesthub.png",
      backgroundColorChipState: "#F4F4F4",
      colorLetterDates: "#212121",
      backgroundButtonCircle: "#e3e3e3",
      bacgroundCardIcon: guestappsettins.background.toUpperCase(),
      bacgroundCardStatusBar: "#F4F4F4",
      backgroundIconStatusBar: "#c3c3c3",
      backgroundIconStatusBarValidate: "#686c6d",
      colorLetterNumId: "#686c6d",
      colorletterButtonLInks: "#686c6d",
      backgroundDivFotter: "#F4F4F4",
      colorOff: "#686C6D",
      colorAlternative: "#FFFFFF",
      colorButton: "#FFFFFF",
      backColorAlert: "#F9F9F9",
      backColorPromotions: "#FFF",
      backgroundButtCircle: "#e3e3e3",
      backColorIcon: "#F4F4F4",

      buttonsBackgroundBase: "#C3C3C3 0% 0% no-repeat padding-box",

    };
  } else if (guestappsettins.background.toUpperCase() == "#212121") {
    document.body.style.backgroundColor = "#212121";
    managmentColor = {
      backgroundColor: guestappsettins.background.toUpperCase(),
      colorLetter: "#F4F4F4",
      backgroundColorCards: "#2C2C2C",
      colorCardTxt: "#E3E3E3",
      colorMode: "dark",
      colorSame: "#686C6D",
      cardShipColor: "#2C2C2C",
      colorLetterBaseButton: guestappsettins.background.toUpperCase(),
      iconFotter: "img/logo-guesthub-dark.svg",
      backgroundColorChipState: "#686C6D",
      colorLetterDates: "#C3C3C3",
      backgroundButtonCircle: "#363636",
      bacgroundCardIcon: "#363636",
      bacgroundCardStatusBar: "#363636",
      backgroundIconStatusBar: "#686C6D",
      backgroundIconStatusBarValidate: "#F4F4F4",
      colorLetterNumId: "#C3C3C3",
      colorletterButtonLInks: "#C3C3C3",
      backgroundDivFotter: "#363636",
      colorOff: "#999999",
      colorAlternative: "#212121",
      colorButton: "#FFFFFF",
      backColorAlert: "#2C2C2C",
      backColorPromotions: "#2C2C2C",
      backgroundButtCircle: "#363636",
      backColorIcon: "#212121",


      buttonsBackgroundBase: "#2C2C2C 0% 0% no-repeat padding-box",

    };
  }

  

  const styleSettings = {
    "--dynamic-color": guestappsettins.color,
    "--colorLetter": managmentColor.colorLetter,
    "--buttonCircle": managmentColor.backgroundButtCircle,
    "--backColorAlert": managmentColor.backColorAlert,
    "--backColorPromotions": managmentColor.backColorPromotions,
    "--backColorIcon": managmentColor.backColorIcon,
  };
  Object.keys(styleSettings).forEach((key) =>
    document.querySelector(":root").style.setProperty(key, styleSettings[key])
  );
  return managmentColor

};

export default {
  getColorComponentsAndIcons,
  getColorsContrast,
};
