<script>
  import { push } from "svelte-spa-router";

  export let name;
  export let href;
  export let img;
  export let nameLink;
  export let propertyId;
  export let featureId;
  export let typeFile;
  export let handleFileDownloading;
  export let managementColors;
  export let canIframe;

  let validUrl;


  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const hover = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px${managementColors.color}`;

      const element = document.getElementById(`${i}`);
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty("color", managementColors.color)
        : null;
    }
  };

  const handleMouseOut = (i) => {
    if (window.innerWidth >= 992) {
      let shadow = `0px 4px 8px rgb(0 0 0 / 20%)`;

      const element = document.getElementById(`${i}`);
      element ? element.style.setProperty("box-shadow", shadow) : null;

      const elementText = document.getElementById(`${i}text`);
      element && elementText
        ? elementText.style.setProperty(
            "color",
            managementColors.general.colorLetter
          )
        : null;
    }
  };

  let validationTypeFile = () => {


    if(!canIframe){
      validUrl = false;
    }else{
      validUrl = true;
    }


    if ((typeFile == "link" && validUrl) || href.split("services.").length == 2) {
      return "IframeItem";
    } else if (
      /\.(png)$/i.test(href) ||
      /\.(jpg)$/i.test(href) ||
      /\.(jpeg)$/i.test(href) ||
      /\.(gif)$/i.test(href) ||
      /\.(bmp)$/i.test(href) ||
      /\.(tif)$/i.test(href) ||
      /\.(tiff)$/i.test(href)
    ) {
      return "ImgItem";
    } else if ((typeFile == "link" && !validUrl)){
      return "OtherTab"
    }else {
      return "fileDownload";
    }

   
  };

  let goFile = (href) => {
    let type = validationTypeFile();


    if (type == "IframeItem") {
      JSON.parse(localStorage.getItem("ImgItem"))
        ? localStorage.removeItem("ImgItem")
        : null;

      localStorage.setItem("IframeItem", JSON.stringify(href));
      push(
        `/properties/${propertyId}/feature/${featureId}/detail-item/${name}`
      );
    } else if (type == "ImgItem") {
      JSON.parse(localStorage.getItem("IframeItem"))
        ? localStorage.removeItem("IframeItem")
        : null;

      localStorage.setItem("ImgItem", JSON.stringify(href));
      push(
        `/properties/${propertyId}/feature/${featureId}/detail-item/${name}`
      );
    } else if (type == "OtherTab") {
      window.open(href, '_blank').focus();
    }else {
      let pdfDefault = `https://docs.google.com/viewerng/viewer?url=${href}`
      //handleFileDownloading(true);
      window.location.href = pdfDefault;
    }
  };
</script>

<div
  id={name}
  on:mouseover={() => hover(name)}
  on:mouseout={() => handleMouseOut(name)}
  class="div__add col s12"
  use:styling={{
    background: managementColors.general.backgroundColorCards,
  }}
  on:click={() => goFile(href)}
>
  {#if /\.(pdf)$/i.test(href)}
    <!--pdf file-->
  {:else if img}
    <div class="div__image">
      <img class="link-img" src={img} alt="" />
    </div>
  {/if}

  <div class="div__text">
    <span
      id={`${name}text`}
      class="text_addinfo"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {name}
    </span>
  </div>
</div>

<style>
  .div__add {
    height: 67px;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 0px !important;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  .div__image {
    width: 20%;
    height: 67px;
    float: left;
  }

  .div__text {
    display: flex;
    align-items: center;
    min-height: 67px;
    height: auto;
    float: left;
    word-break: break-word;
    width: 70%;
    margin-left: 16px !important;
  }

  .text_addinfo {
    font-size: 0.875 rem;
    font-weight: 700;
  }

  .link-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
</style>
