<script>
  export let managementColors;
  export let dataShare;

  let actionShare = () => {
    let shareData = {
      title: dataShare.name,
      url: window.location.href, 
    };
    const btn = document.querySelector(".button-link");

    // Must be triggered some kind of "user activation"
    btn.addEventListener("click", async () => {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error(`Couldn't share because of`, err.message);
      }
    });
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<div
  class="button-link"
  use:styling={{ background: managementColors.color }}
  on:click={actionShare}
>
  <i class="material-icons icon-share">share</i>
</div>

<div class="result" />

<style>
  .button-link {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #1ab394 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #1bb39452;
    margin-right: 8px;
    float: right;
    cursor: pointer;
    opacity: 1;
  }

  .icon-share {
    font-size: 2rem;
    color: white;
  }
</style>
