<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  export let dataMenu;
  export let propertyId;
  export let featureId;
  export let managementColors;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let handleRoomServiceDetail = (data) => {
    localStorage.setItem("IframeItem", JSON.stringify(data.link));
    push(
      `/properties/${propertyId}/feature/${featureId}/detail-item/${data.name}`
    );
  };
  
</script>

<div
  class=" card card-menu-room-service"
  use:styling={{ background: managementColors.general.backgroundColorCards }}
  on:click={() => handleRoomServiceDetail(dataMenu)}
>
  <div class="card__image-cont">
    {#if dataMenu.photoUrl}
      <img class="card__image" src={dataMenu.photoUrl} alt="" />
    {/if}
    {#if dataMenu.img}
      <img class="card__image" src={dataMenu.img} alt="" />
    {/if}
  </div>
  <div class="card-content">
    <span class="card__title"
    use:styling={{ color: managementColors.general.colorLetter }}>{dataMenu.name}</span>
    {#if dataMenu.subName}
      <div class="card__cont-text">
        <p class="card__text"
        use:styling={{ color: managementColors.general.colorLetter }}
        >{dataMenu.subName}</p>
      </div>
    {/if}
  </div>
</div>

<style>
  .card-menu-room-service {
    position: inherit !important;
    padding: 0px;
    height: fit-content !important;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    min-height: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    margin-top: 0px;
    opacity: 1;
    cursor: pointer;
  }

  .card__image {
    width: 100%;
    height: 110px;
    object-fit: cover;
    object-position: center center;
    border-radius: 4px 4px 0px 0px !important;
  }

  .card-content {
    padding: 16px !important;
  }

  .card__title {
    font-size: 1.125rem;
    color: #212121;
    font-weight: 600;
  }

  .card__text {
    font-size: 0.875rem;
    color: #686c6d;
  }
</style>
