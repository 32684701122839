<script>
  import { _, locale } from "svelte-i18n";
  import ModalForm from "../form/component.svelte";
  import PropertyFeatures from "../../../property-features-service";

  export let closeModal;
  export let title;
  export let message;
  export let returnText;
  export let actionText;
  export let onAction;
  export let sendOptionCancel;
  export let showForm;
  export let formTitle;
  export let optionsForm;
  export let managementColors;

  let cliclInter = false;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("modal-desktop") &&
      document.getElementById("modal-desktop").contains(e.target)
    ) {
      setTimeout(function () {
        if (!cliclInter && document.getElementById("card-modal-desktop"))
        closeModal();
      }, 50);
    } else {
    }
  });

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("card-modal-desktop") &&
      document.getElementById("card-modal-desktop").contains(e.target)
    ) {
      cliclInter = true;
    } else {
      cliclInter = false;
    }
  });

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  }

  setFontFamily(null);
</script>

<div 
id="modal-desktop"
class:modal__base={true} class="modal__base-hide">
  <div
    id="card-modal-desktop"
    class="modal"
    use:styling={{ background: managementColors.general.backgroundColor }}
  >
    {#if !showForm}
      <div class="modal__cont col s12">
        <div class="cont__title">
          <span
            class="text__title"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {$_(title)}
          </span>
        </div>
        <div class="cont__message">
          <span
            class="text_-message"
            use:styling={{ color: managementColors.general.colorLetter }}
          >
            {$_(message)}
          </span>
        </div>

        <div class="cont__buttons">
          <button
            class="btn btn-success row button--return"
            on:click={closeModal}
          >
            <span
              class="text--return"
              use:styling={{ color: managementColors.general.colorLetter }}
            >
              {$_(returnText)}
            </span>
          </button>

          <button
            class="btn btn-success row button--delete"
            on:click={onAction}
          >
            <span
              class="text--delete" use:styling={{ color: managementColors.general.colorButton }}
            >
              {$_(actionText)}
            </span>
          </button>

        </div>
      </div>
    {:else}
      <div
        class="form__cont col s12"
        use:styling={{ background: managementColors.general.backgroundColor }}
      >
        <ModalForm
          bind:title={formTitle}
          bind:managementColors
          bind:optionsForm
          bind:sendOptionCancel
        />
      </div>
    {/if}
  </div>
</div>

<style>
  .modal__base-hide {
    display: none; /* Hidden by default */
  }

  .modal__base {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Stay in place */
    z-index: 105; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal {
    display: block !important;
    z-index: 106 !important;
    height: auto;
    transform: scaleX(1) scaleY(1);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding-bottom: 32px;
    opacity: 1;
    margin: 0 auto;
  }

  .modal__cont {
    padding: 0px;
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modal__cont::-webkit-scrollbar {
    display: none;
  }

  .cont__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 40px 0px 40px;
    text-align: left;
  }

  .cont__message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 40px 0px 40px;
    text-align: left;
  }

  .text_-message {
    font-size: 1rem;
  }

  .text__title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .cont__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
  }

  .button--return {
    background: transparent;
    border: none;
    box-shadow: none;
    margin: 0px 0px 0px 20px !important;
  }

  .button--delete {
    background: #ff4855 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    margin: 0px !important;
  }

  .button--delete > .text--delete {
    font-size: 1rem;
    font-weight: 500;
    color: #FFFFFF;
    text-transform: initial;
  }

  .text--return {
    font-size: 1rem;
    font-weight: 500;
    text-transform: initial;
  }

  .form__cont {
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background: white;
    border-radius: 20px 20px 0px 0px;
    padding: 0px;
  }

  .form__cont::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    .text--return {
      font-size: 1rem;
    }

    .text--delete {
      font-size: 1rem;
    }

    .modal {
      width: 40% !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .cont__buttons {
      justify-content: flex-end;
      padding: 40px 40px 0px 40px;
    }

    .cont__message {
      padding: 8px 40px 0px 40px !important;
    }
    
  }
</style>
