export default {
  reservations: {
    api:
      window["cordova"]
        ? "https://reservations-api.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:3009"
          : window.location.origin.replace("reservations", "reservations-api"),
  },
  events: {
    api:
      window["cordova"]
        ? "https://events-api.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:3020"
          : window.location.origin.replace("reservations", "events-api"),
  },
  app: {
    api:
      window["cordova"]
        ? "https://app-api.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:3000"
          : window.location.origin.replace("reservations", "app-api"),
  },
  guestsServices: {
    web:
      window["cordova"]
        ? "https://services.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://services.qa.norelian.com"
          : window.location.origin.replace("reservations", "services"),
  },
  reservationsServices: {
    web:
      window["cordova"]
        ? "https://reservations-services.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:4211"
          : window.location.origin.replace("reservations", "reservations-services"),
  },
  pickupGuests: {
    web:
      window["cordova"]
        ? "https://guests.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:4200"
          : window.location.origin.replace("reservations", "guests"),
  },
  guestapp: {
    web:
      window["cordova"]
        ? "https://guestapp.app.guesthub.io"
        : window.location.origin.includes("localhost")
          ? "http://localhost:4206"
          : window.location.origin.replace("reservations", "guestapp"),
  },
};
