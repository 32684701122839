<script>
  import { _, locale } from "svelte-i18n";

  export let propertyData;
  export let sendSurvey;
  export let nameGuest;

  const getFullYear = new Date().getFullYear();
  let comments = "";
  let disabledButton = false;
  let inputactive = false;
  let statesExperience = [true, true, true, true, true];
  let statesRecomendate = [true, true, true, true, true];
  let typeExperience = true;
  let typeRecomended = true;
  let textExperience = "";
  let textRecomended = "";
  let classStar = "strborder";
  let classStarRecom = "strborder";
  let indexe = null;
  let indexr = null;
  let classStarBase = "strborder";

  let updateStates = (type, index) => {
    if (type === "experience") {
      for (let i = 0; i < statesExperience.length; ++i) {
        if (i > index) {
          statesExperience[i] = false;
        } else {
          statesExperience[i] = true;
        }
      }
      validationType(type, index);
    } else if (type === "recomendate") {
      for (let i = 0; i < statesRecomendate.length; ++i) {
        if (i > index) {
          statesRecomendate[i] = false;
        } else {
          statesRecomendate[i] = true;
        }
      }
      validationType(type, index);
    }
  };

  let validationType = (type, i) => {
    if (type === "experience") {
      indexe = i;
      typeExperience = true;
      switch (statesExperience.filter((r) => r).length) {
        case 1:
          textExperience = "Very bad";
          classStar = "startRed";
          break;
        case 2:
          textExperience = "Bad";
          classStar = "startRed";
          break;
        case 3:
          textExperience = "Neutral";
          classStar = "startOrange";
          break;
        case 4:
          textExperience = "Good";
          classStar = "startYellow";
          break;
        case 5:
          textExperience = "Very good!!!";
          classStar = "startYellow";
          break;
        default:
          break;
      }
    } else {
      indexr = i;
      typeRecomended = true;
      switch (statesRecomendate.filter((r) => r).length) {
        case 1:
          textRecomended = "Very unlikely";
          classStarRecom = "startRed";
          break;
        case 2:
          textRecomended = "Unlikely";
          classStarRecom = "startRed";
          break;
        case 3:
          textRecomended = "Maybe";
          classStarRecom = "startOrange";
          break;
        case 4:
          textRecomended = "Likely";
          classStarRecom = "startYellow";
          break;
        case 5:
          textRecomended = "Very likely";
          classStarRecom = "startYellow";
          break;
        default:
          break;
      }
    }
  };

  let validationHover = (type, i) => {
    if (type === "experience") {
      indexe = i;
      typeExperience = true;
      switch (indexe) {
        case 0:
          textExperience = "Very bad";
          classStar = "startRed";
          break;
        case 1:
          textExperience = "Bad";
          classStar = "startRed";
          break;
        case 2:
          textExperience = "Neutral";
          classStar = "startOrange";
          break;
        case 3:
          textExperience = "Good";
          classStar = "startYellow";
          break;
        case 4:
          textExperience = "Very good!!!";
          classStar = "startYellow";
          break;
        default:
          typeExperience = false;
          textExperience = "";
          classStar = "strborder";
          break;
      }
    } else {
      indexr = i;
      typeRecomended = true;
      switch (indexr) {
        case 0:
          textRecomended = "Very unlikely";
          classStarRecom = "startRed";
          break;
        case 1:
          textRecomended = "Unlikely";
          classStarRecom = "startRed";
          break;
        case 2:
          textRecomended = "Maybe";
          classStarRecom = "startOrange";
          break;
        case 3:
          textRecomended = "Likely";
          classStarRecom = "startYellow";
          break;
        case 4:
          textRecomended = "Very likely";
          classStarRecom = "startYellow";
          break;
        default:
          typeRecomended = false;
          textRecomended = "";
          classStarRecom = "strborder";
          break;
      }
    }
  };

  const hover = (type, index) => {
    if (
      statesExperience.filter((r) => r).length == 0 &&
      statesRecomendate.filter((r) => r).length == 0
    )
      validationHover(type, index);
  };

  const handleMouseOut = (type, index) => {
    if (
      statesExperience.filter((r) => r).length == 0 &&
      statesRecomendate.filter((r) => r).length == 0
    )
      validationHover(type, index);
  };

  let handleSendSurvey = () => {
    disabledButton = true ;
      sendSurvey(statesExperience, statesRecomendate, comments);
  };

  const init = () => {
    updateStates("experience", 4);
    updateStates("recomendate", 4);
  };

  init();
</script>

<div class="col l6 offset-l3 m12 s12 feedback">
  <div class="col s12 feedback__cont__logo">
    <img class="feedback__logo" alt="logo" src={propertyData.logo} />
  </div>

  <div class="col s12 feedback__title">
    <span class="feedback__title__text {$locale}"> {$_("Dear")} </span>
    {#if nameGuest.length > 0}
      <span class="feedback__title__text--bold">{nameGuest},</span>
    {:else}
      <span class="feedback__title__text--bold {$locale}"> {$_("Guest,")}</span>
    {/if}
    <span class="feedback__title__text {$locale}">
      {$_("please rate us")}
    </span>
  </div>

  <div class="col s12 feedback__description">
    <span class="feedback__description__text {$locale}"
      >{$_(
        "We would appreciate your valuable feedback so we can continue to provide service that not only meets, but exceeds our guests expectations."
      )}
    </span>
  </div>

  <div class="col s12 feedback__question">
    <span class="feedback__question__text {$locale}">
      {$_("Can you rate your stay at")}
    </span>
    <span class="feedback__question__text"> {propertyData.name}? </span>
  </div>

  <div class="col s12 feedback__starts">
    <div class=" feedback__starts__individual">
      {#each statesExperience as star, i}
        <i
          class="material-icons {i <= indexe ? classStar : classStarBase}"
          on:mouseover={() => hover("experience", i)}
          on:mouseout={() => handleMouseOut("experience", -1)}
          on:click={() => updateStates("experience", i)}
        >
          star
        </i>
      {/each}
    </div>
    <div class="col s12 m7 feedback__starts__message">
      {#if typeExperience}
        <span class="feedback__starts__message__text {$locale}"
          >{$_(textExperience)}</span
        >
      {/if}
    </div>
  </div>

  <div class="col s12 feedback__question">
    <span class="feedback__question__text {$locale}">
      {$_("Would you recommend")}
    </span>
    <span class="feedback__question__text"> {propertyData.name} </span>
    <span class="feedback__question__text {$locale}">
      {$_("to your friends and family?")}
    </span>
  </div>

  <div class="col s12  feedback__starts">
    <div class="feedback__starts__individual">
      {#each statesRecomendate as starc, i}
        <i
          class="material-icons {i <= indexr ? classStarRecom : classStarBase}"
          on:mouseover={() => hover("recomendate", i)}
          on:mouseout={() => handleMouseOut("recomendate", -1)}
          on:click={() => updateStates("recomendate", i)}
        >
          star
        </i>
      {/each}
    </div>
    <div class="col s12 m7 feedback__starts__message">
      {#if typeRecomended}
        <span class="feedback__starts__message__text {$locale}"
          >{$_(textRecomended)}
        </span>
      {/if}
    </div>
  </div>

  <div class="col s12 feedback__textarea">
    <label
      class="active label__input {$locale}"
      class:activeinput={inputactive == true}
      for="comments"
    >
      {$_("Share details of your experience")}
    </label>
    <textarea
      placeholder={$_("Write your feedback here")}
      id="comments"
      bind:value={comments}
      type="text"
      class="validate "
    />

    <div class="col s12 feedback__actions">
      <button
        class="btn button--continuebase"
        class:disabled={disabledButton}
        on:click={handleSendSurvey}
      >
        <span class="text--button {$locale}">{$_("Submit")}</span>
      </button>
    </div>
  </div>
</div>
<div class="col s12 feedback__footer">
  <div class="col s12 m5 feedback__footer__powered">
    <div class="feedback__footer__powered__move">
      <span class="feedback__footer__text"> Powered by</span>
      <img
        class="feedback__footer__logo"
        alt="footerlogo"
        src="img/logo-guesthub.png"
      />
    </div>
  </div>
  <div class="col s12 m7 feedback__footer__copyright">
    <span class="feedback__footer__text"> © {getFullYear} GuestHub </span>
    <span class="feedback__footer__text {$locale}">
      {$_("All rights reserved")}</span
    >
  </div>
</div>

<style>

textarea:focus {
    outline: none !important;
    border-color: #000000de !important;
}
  .container-fluid {
    height: 100% !important;
  }

  .feedback {
    min-height: 95% !important;
  }

  .feedback__cont__logo {
    display: flex;
    justify-content: center;
    padding: 3.099rem 0rem 0rem 0rem !important;
  }

  .feedback__logo {
    width: 58px;
    height: 58px;
    opacity: 1;
  }

  .feedback__title {
    padding: 1.681rem 0rem 0rem 0rem !important;
  }

  .feedback__title__text {
    font-size: 1.5rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__title__text--bold {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__description {
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .feedback__description__text {
    font-size: 1rem;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__question {
    text-align: center;
    padding: 2rem 0rem 0rem 0rem !important;
  }

  .feedback__question__text {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .feedback__starts {
    padding: 0.5rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__individual {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__starts__message {
    text-align: center;
    padding: 0rem 0rem 0rem 0rem;
  }

  .feedback__starts__message__text {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }

  .strborder {
    font-size: 2rem;
    padding-right: 1rem;
    color: #c3c3c3;
    cursor: pointer;
  }

  .startRed {
    font-size: 2rem;
    padding-right: 1rem;
    color: #ff4855 !important;
    cursor: pointer;
  }

  .startOrange {
    font-size: 2rem;
    padding-right: 1rem;
    color: #ffa64d !important;
    cursor: pointer;
  }

  .startYellow {
    font-size: 2rem;
    padding-right: 1rem;
    color: #fedf61 !important;
    cursor: pointer;
  }

  .feedback__textarea {
    padding: 2rem 0rem 0rem 0rem !important;
  }

  textarea {
    min-height: 6.063rem;
  }

  .label__input {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    margin-bottom: 0.5rem;
    opacity: 1;
  }

  .feedback__actions {
    padding: 1.938rem 0rem 0rem 0rem !important;
  }

  .button--continuebase {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f4050 0% 0% no-repeat padding-box !important;
    float: right;
    cursor: pointer;
    width: auto;
    height: 38px;
    border-radius: 8px;
    opacity: 1;
  }

  .button--block {
    background: #c3c3c3 0% 0% no-repeat padding-box !important;
  }

  .text--button {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 1;
  }

  .feedback__footer {
    bottom: 0;
    padding-top: 3.438rem !important;
    z-index: -1;
  }

  .feedback__footer__logo {
    width: 6rem;
    height: 2rem;
    margin-bottom: 5px;
    opacity: 1;
  }

  .feedback__footer__powered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .feedback__footer__text {
    font-size: 0.75rem;
    letter-spacing: 0px;
    color: #212121;
    margin-left: 0.3rem !important;
    opacity: 1;
  }

  .feedback__footer__powered__move {
    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: 601px) {
    .feedback__starts {
      display: flex;
      align-items: center;
    }

    .feedback__starts__message__text {
      float: left;
    }

    .feedback__footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .feedback__footer__powered__move {
      width: auto;
      float: right;
    }

    .feedback__question {
      text-align: left;
    }

    .feedback__footer__powered__move {
      float: right;
    }

    .feedback__footer__powered {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }

    .feedback__footer__text {
      float: left;
    }

    .feedback__footer__copyright {
      display: block;
      width: auto !important;
      margin: 0rem !important;
    }

    .feedback__starts__individual {
      text-align: left;
    }
  }
</style>
