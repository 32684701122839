<script>
  export let contentUrl;

  const url = `https://drive.google.com/viewerng/viewer?embedded=true&url=${contentUrl}`;

  const conditionalPdfIFrameReloadTimeout = () =>
    setTimeout(() => {
      let iFrame = document.GetElementById("pdfIframe");
      if (iFrame.contentDocument /*pdf iframe failed to load*/) {
        iFrame.src = null;
        iFrame.src = url;
        conditionalPdfIFrameReloadTimeout();
      }
    }, 6000);

  conditionalPdfIFrameReloadTimeout();
</script>

<iframe
  id="pdfIframe"
  title="content"
  style="margin-top:4rem,overflow-y:hidden"
  src={url}
  width="100%"
  height="100%"
/>
