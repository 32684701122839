<script>
  import { _, locale } from "svelte-i18n";
  import { push, location } from "svelte-spa-router";
  import PropertyFeatures from "../../property-features-service";
  import RoutesServices from "../../management-routes-service";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import environments from "../../environments";
  import LandingPage from "../../landing-page/list-features/component.svelte";

  export let params = {};

  let linkIframe = null;
  let state = Object.assign({ features: [], reservation: undefined }, params);
  let propertyData = {};
  let languagesEnabled = [];
  let language;
  let faeturesList = [];
  let managementColors = {};
  let showBtnBack = true;
  let propEmpty = "";
  let reload = true;
  let dataHeader;
  let veiliframe = false;
  let myContent;
  let landing;

  let onFinishedLoading = () => {
    landing = true;

    const validationColor = () => {
      JSON.parse(localStorage.getItem("managementColors"))
        ? (managementColors = JSON.parse(
            localStorage.getItem("managementColors"),
          ))
        : (managementColors.color = "#2bbbad");
    };

    let constructorIframe = (lang, contentDetail, fontFamily) => {
      var auth = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth"))
        : null;
      var reservation = localStorage.getItem("reservation")
        ? JSON.parse(localStorage.getItem("reservation"))
        : null;
      var browserIdentify = localStorage.getItem("browser-identify")
        ? JSON.parse(localStorage.getItem("browser-identify"))
        : null;

      if (auth && reservation) {
        var queryParams = `?iframe=detailmenu&lang=${lang.toUpperCase()}&fontFamily=${encodeURIComponent(
          fontFamily,
        )}&guestName=${encodeURIComponent(
          auth.guestName,
        )}&roomNumber=${encodeURIComponent(
          auth.roomNumber,
        )}&reservation=${reservation}${
          auth && auth.reservationId
            ? `&reservationId=${encodeURIComponent(auth.reservationId)}`
            : ``
        }&reservation=${reservation}${
          auth && auth.reservationVip
            ? `&reservationVip=${encodeURIComponent(auth.reservationVip)}`
            : ``
        }&browserIdentify=${encodeURIComponent(
          browserIdentify,
        )}&serviceId=${encodeURIComponent(
          dataHeader.featureId,
        )}&outleType=${encodeURIComponent(
          contentDetail.outlet.type,
        )}&outletId=${encodeURIComponent(contentDetail.outlet.id)}`;
      } else {
        var queryParams = `?iframe=detailmenu&lang=${lang.toUpperCase()}&fontFamily=${encodeURIComponent(
          fontFamily,
        )}&browserIdentify=${encodeURIComponent(
          browserIdentify,
        )}&serviceId=${encodeURIComponent(
          dataHeader.featureId,
        )}&outleType=${encodeURIComponent(
          contentDetail.outlet.type,
        )}&outletId=${encodeURIComponent(contentDetail.outlet.id)}`;
      }

      var route = `${environments.reservationsServices.web}/#/properties/${state.propertyId}/menus/${contentDetail.id}`;

      if (linkIframe) {
        linkIframe = null;
        setTimeout(() => {
          linkIframe =
            ' <iframe id="jqt"   src="' +
            route +
            queryParams +
            '"  frameborder="0"   style=" padding:0px; height: 100% !important; position: relative !important; background:black !important;" class="col s12 l12 xl12" ></iframe>';
        }, 500);
      } else {
        linkIframe =
          ' <iframe id="jqt"   src="' +
          route +
          queryParams +
          '"  frameborder="0"   style=" padding:0px; height: 100% !important; position: relative !important; background:black !important;" class="col s12 l12 xl12" ></iframe>';
      }
    };

    const init = (lang, entryPoint) => {
      reload = true;
      validationColor();

      Promise.all([
        PropertyFeatures.list({ propertyId: state.propertyId }, lang),
        PropertyFeatures.guestAppSettings({
          propertyId: state.propertyId,
        }),
        PropertyFeatures.proppertyInformation({
          propertyId: state.propertyId,
        }),
        PropertyFeatures.outletContentDetail({
          propertyId: state.propertyId,
          outleType: window.location.hash.includes("room-service")
            ? "room-service"
            : window.location.hash.includes("service")
              ? "service"
              : window.location.hash.includes("other")
                ? "other"
                : "restaurant",
          language: lang,
          id: state.idOutlet,
          idContent: state.idContent,
        }),
      ]).then(
        ([features, guestappsettins, propertyInformation, contentDetail]) => {
          myContent = contentDetail;
          propertyData = propertyInformation;
          faeturesList = features.filter(
            (fet) => fet.enabled || fet.codename == "property.details",
          );
          if (contentDetail.contenType != "wysiwyg") {
            constructorIframe(
              lang || propertyData.language.code,
              Object.assign({}, contentDetail, {
                outlet: Object.assign({}, contentDetail.outlet, {
                  type: window.location.hash.includes("room-service")
                    ? "room-service"
                    : window.location.hash.includes("service")
                      ? "service"
                      : window.location.hash.includes("other")
                        ? "other"
                        : "restaurant",
                }),
              }),
              guestappsettins.fontFamily,
            );
          }
          validationLanguage(
            guestappsettins.languages,
            propertyInformation.language.code,
          );
          setFontFamily(guestappsettins.fontFamily);

          dataHeader.nameFeature = contentDetail.outlet.name;
          dataHeader.imgFeature =
            contentDetail.outlet.photo || contentDetail.outlet.cover;
          localStorage.setItem("dataHeader", JSON.stringify(dataHeader));

          localStorage.setItem("lastRoute", '"pop"');
          reload = false;
        },
      );
    };

    dataHeader = JSON.parse(localStorage.getItem("dataHeader"));
    !dataHeader.linkLogin
      ? init(JSON.parse(localStorage.getItem("language")), false)
      : init(
          JSON.parse(localStorage.getItem("language")),
          JSON.parse(localStorage.getItem("entryPoint")),
        );

    let setFontFamily = (fontFamily) => {
      PropertyFeatures.setFontFamily(fontFamily);
    };

    const connectioWhitIframe = () => {
      window.addEventListener("message", function (event) {
        if (event.data.info) {
          switch (event.data.info.type) {
            case "back":
              if (event.data.info.value == "viewoutlet") {
                RoutesServices.setLastRoute(event.data.info.value);
                localStorage.setItem(
                  "outleType",
                  window.location.hash.includes("room-service")
                    ? "room-service"
                    : "restaurant",
                );
                localStorage.setItem("outletId", state.idOutlet);
              } else {
                let amountMenus = JSON.parse(
                  localStorage.getItem(
                    window.location.hash.includes("restaurant")
                      ? "amountRestaurants"
                      : window.location.hash.includes("service")
                        ? "amountServices"
                        : "amountMenusRoomService",
                  ),
                );
                amountMenus > 1 || event.data.info.value == "pop"
                  ? RoutesServices.setLastRoute(event.data.info.value)
                  : null;
              }
              break;
            case "go":
              if (event.data.info.value == "viewlogin") {
                dataHeader.linkLogin = $location;
                localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
                RoutesServices.setLastRoute(event.data.info.value);
                RoutesServices.goRoute(
                  state.propertyId,
                  event.data.info.requestId,
                );
              } else if (event.data.info.value == "viewrequestcomplete") {
                if (event.data.info.requestId.length > 1) {
                  push(`/properties/${state.propertyId}/my-experiences`);
                } else if (event.data.info.requestId.length == 1) {
                  RoutesServices.setLastRoute("viewrequestcomplete");
                  RoutesServices.goRoute(
                    state.propertyId,
                    event.data.info.requestId[0].id,
                  );
                } else {
                  push(`/properties/${state.propertyId}/my-experiences`);
                }
              } else {
                dataHeader.link = `/properties/${state.propertyId}`;
                localStorage.setItem("dataHeader", JSON.stringify(dataHeader));
                RoutesServices.setLastRoute(event.data.info.value);
                RoutesServices.goRoute(
                  state.propertyId,
                  event.data.info.requestId,
                );
              }
              break;
            case "entryPoint":
              RoutesServices.setEntryPoint(event.data.info.value);
              break;
            case "veiliframe":
              event.data.info.value
                ? (veiliframe = true)
                : (veiliframe = false);
              break;
            default:
              break;
          }
        }
      });
    };

    connectioWhitIframe();

    const validationLanguage = (enabledLanguges, languageDefault) => {
      languagesEnabled = enabledLanguges;
      language = languageDefault;
    };
  };

  let handleLanguage = (e) => {
    onFinishedLoading();
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

{#if !landing}
  <div style="display:block">
    <LandingPage bind:params page={"home"} bind:onFinishedLoading />
  </div>
{:else if !reload}
  <div
    use:styling={{
      background:
        myContent.contenType != "wysiwyg"
          ? managementColors.general.backgroundColor
          : "#fff",
    }}
    class="container-fluid row"
  >
    {#if !veiliframe && !reload}
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
            ? 'navifation-cont-border--dark'
            : ''}"
        use:styling={{
          background: managementColors.general.backgroundColor,
        }}
      >
        <NavigationTop
          bind:languageConection={handleLanguage}
          bind:logoUrl={propertyData.logo}
          bind:propertyName={propertyData.name}
          bind:propertyId={state.propertyId}
          bind:lang={language}
          bind:listLanguagesEnabled={languagesEnabled}
          bind:features={faeturesList}
          bind:backgroundColorView={managementColors.general.backgroundColor}
          bind:colorLetter={managementColors.general.colorLetter}
          bind:colorAlternative={managementColors.general.colorAlternative}
          bind:managementColors
          bind:showBtnBack
          bind:iframeFromLanding={propEmpty}
        />
      </div>
    {/if}

    {#if myContent.contenType != "wysiwyg"}
      <div
        use:styling={{
          background: managementColors.general.backgroundColor,
          marginTop: "8px",
          color: "white",
        }}
        class="col s12 l12 xl12 iframe"
        contenteditable="false"
        bind:innerHTML={linkIframe}
      />
    {:else}
      <div class=" all__cont">
        {#if myContent.preview}
          <div class="cont__all__img">
            <div class="col s12 img__cont">
              <img src={myContent.preview} class="col s12 padding0 img__size" />
            </div>
          </div>
        {/if}
        <div
          class="col s12 cont__text {!myContent.preview
            ? 'cont__txt__title'
            : myContent.prewview
              ? 'cont__text'
              : ''}"
        >
          <span class="col s12 txt__title__editor">{myContent.title}</span>
        </div>
        <div
          use:styling={{
            marginTop: "8px",
          }}
          class="col s12 l12 xl12 txt__descrip"
          contenteditable="false"
          bind:innerHTML={myContent.body}
        />
      </div>
    {/if}

    {#if reload}
      <div
        use:styling={{
          background: managementColors.general.backgroundColor,
        }}
        class="reloading row col s12"
      >
        <Reloading bind:managementColors />
      </div>
    {/if}
  </div>
{/if}

<style>
  .reloading {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .container-fluid {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px !important;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .iframe {
    padding: 0px !important;
    height: 90% !important;
    flex: 1;
  }

  @media only screen and (min-width: 20px) {
    .img__size {
      padding: 0px !important;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img__cont {
      position: relative;
      padding: 61px 0px 0px 0px !important;
      display: flex;
      justify-content: center;
    }

    .cont__all__img {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0px !important;
      height: 37.2%;
    }

    .all__cont {
      padding: 0px 0px 100px 0px !important;
      background: #fff !important;
    }

    .cont__txt__title {
      padding: 56px 12px 0px 12px !important;
    }

    .txt__title__editor {
      padding: 24px 0px 0px 0px !important;
      font-size: 20px;
      font-weight: 700;
      color: #212121;
    }

    .txt__descrip {
      font-size: 14px;
      color: #212121;
    }
  }

  @media only screen and (min-width: 700px) {
    .img__size {
      border-radius: 20px;
    }

    .img__cont {
      padding: 93px 0px 0px 0px !important;
    }

    .all__cont {
      padding: 0px 32px 100px 32px !important;
    }

    .cont__text {
      padding: 0px !important;
    }
    .cont__txt__title {
      padding: 56px 0px 0px 0px !important;
    }

    .txt__descrip {
      padding: 0px !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .all__cont {
      padding: 0px 230px 100px 230px !important;
    }

    .img__cont {
      padding: 93px 0px 0px 0px !important;
    }
  }

  @media only screen and (min-width: 1600px) {
    .cont__all__img {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0px !important;
      height: 32.8%;
    }
  }
</style>
