<script>
  import { _, locale } from "svelte-i18n";
  export let date;
  export let colorLetterDates;
  export let defaultStyles;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  let monthNames = [
    { EN: "Jan", ES: "ene" },
    { EN: "Feb", ES: "feb" },
    { EN: "Mar", ES: "mar" },
    { EN: "Apr", ES: "abr" },
    { EN: "May", ES: "may" },
    { EN: "Jun", ES: "jun" },
    { EN: "Jul", ES: "jul" },
    { EN: "Aug", ES: "agos" },
    { EN: "Sep", ES: "sep" },
    { EN: "Oct", ES: "oct" },
    { EN: "Nov", ES: "nov" },
    { EN: "Dec", ES: "dic" },
  ];
  let language = JSON.parse(localStorage.getItem("language"))
    ? JSON.parse(localStorage.getItem("language")).toUpperCase()
    : "EN";
  let dateOrder1 =
    (new Date(date).getHours() < 10
      ? "0" + new Date(date).getHours()
      : new Date(date).getHours()) +
    ":" +
    (new Date(date).getMinutes() < 10
      ? "0" + new Date(date).getMinutes()
      : new Date(date).getMinutes());
  let dateOrder2 =
    new Date(date).getDate() < 10
      ? "0" + new Date(date).getDate()
      : new Date(date).getDate();
  let dateOrder3 = monthNames[new Date(date).getMonth()];
  let dateOrder4 = new Date(date).getFullYear();

  locale.subscribe((e) => {
    language = e.toUpperCase();
  });
</script>

{#if language == "EN"}
  <span
    class="card-request__number-text"
    class:text--right={!defaultStyles}
    use:styling={{ color: colorLetterDates }}
  >
    {dateOrder2}
    {dateOrder3[language]} 
    {dateOrder4} ,
    {dateOrder1}
  </span>
{/if}
{#if language == "ES"}
  <span
    class="card-request__number-text"
    class:text--right={!defaultStyles}
    use:styling={{ color: colorLetterDates }}
  >
    {dateOrder2}
    {" de "}
    {dateOrder3[language]}
    {" del "}
    {dateOrder4} ,
    {dateOrder1}
  </span>
{/if}

<style>
  .card-request__number-text {
    font-size: 0.875rem;
    color: #686C6D;
  }

  @media only screen and (min-width: 601px) {
  
    .text--right {
      float: right;
    }
  }
</style>
